import React from 'react';
import { Table, Text } from '@forward-financing/fast-forward';
import { Feature } from './v6ScoreDisplay.types';

type V6ScoreTableProps = {
  features?: Feature[];
};

export const V6ScoreDisplayTable = ({
  features,
}: V6ScoreTableProps): JSX.Element => {
  if (!features) {
    return <></>;
  }

  return (
    <Table>
      <Table.Body>
        {features.map((feature) => (
          <Table.Row key={feature?.name}>
            <Table.Cell columnWidth="large">{feature.name}</Table.Cell>

            <Table.Cell columnWidth="small">
              <Text bold>{feature.inputValue}</Text>
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
};
