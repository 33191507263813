import React from 'react';
import {
  Flex,
  formatDateString,
  Link,
  Table,
  Tag,
} from '@forward-financing/fast-forward';
import { BANK_BASE_URL, UNDERWRITING_BASE_URL } from 'constants/globals';
import { featureFlags } from 'helpers/featureFlags';
import { IndependentSalesOrganization } from '../ApplicationSnapshot/applicationSnapshot.types';
import { CustomerSubmission } from './matchedRecords.types';
import { hasOpenApproval } from './helpers';
import { DeclineInfoModal } from './DeclineInfoModal';

export interface CustomerSubmissionTableProps {
  submissions: CustomerSubmission[] | undefined;
  currentSubmissionUuid: string | undefined;
  isos: IndependentSalesOrganization[] | undefined;
}

export const CustomerSubmissionTable = ({
  submissions,
  currentSubmissionUuid,
  isos,
}: CustomerSubmissionTableProps): JSX.Element => {
  const isCurrentSubmission = (sub: CustomerSubmission): boolean =>
    currentSubmissionUuid === sub.submissionUuid;

  const currentSubmission = submissions?.find((sub) =>
    isCurrentSubmission(sub)
  );

  const sortedOtherSubmissions = submissions
    ?.filter((sub) => !isCurrentSubmission(sub))
    .sort((a, b) =>
      (a.dateAppReceived ?? 0) >= (b.dateAppReceived ?? 0) ? -1 : 1
    );

  const DECISION_OR_CREDIT_COMMITTEE =
    featureFlags.use_decision_committee_naming
      ? 'Decision Committee'
      : 'Credit Committee';

  const renderStatus = (status: string | null): string | null => {
    if (status === 'Credit Committee') {
      return DECISION_OR_CREDIT_COMMITTEE;
    }

    return status;
  };

  return (
    <Table caption="Customer Submissions">
      <Table.Head>
        <Table.ColumnHeader>Submission Name</Table.ColumnHeader>
        <Table.ColumnHeader>Owner 1</Table.ColumnHeader>
        <Table.ColumnHeader>Owner 2</Table.ColumnHeader>
        <Table.ColumnHeader>ISO</Table.ColumnHeader>
        <Table.ColumnHeader>Links</Table.ColumnHeader>
        <Table.ColumnHeader>Stage</Table.ColumnHeader>
        <Table.ColumnHeader>Status</Table.ColumnHeader>
      </Table.Head>
      <Table.Body>
        {currentSubmission && (
          <Table.Row>
            <Table.Cell backgroundColor="green-200">
              {`${currentSubmission.submissionName} (Current Submission)`}
            </Table.Cell>
            <Table.Cell backgroundColor="green-200">
              {currentSubmission.owner1FullName}
            </Table.Cell>
            <Table.Cell backgroundColor="green-200">
              {currentSubmission.owner2FullName}
            </Table.Cell>
            <Table.Cell backgroundColor="green-200">
              {isos?.find((s) => s.uuid === currentSubmission.isoUuid)?.name}
            </Table.Cell>
            <Table.Cell backgroundColor="green-200">
              <Flex gap={2} flexDirection={'row'}>
                {currentSubmission.dateAppReceived &&
                  formatDateString(currentSubmission.dateAppReceived)}
                <Link
                  newTab
                  href={
                    new URL(
                      `/admin/prospective_merchants/${currentSubmission.accountUuid}/sheets/${currentSubmission.submissionUuid}`,
                      BANK_BASE_URL()
                    )
                  }
                >
                  BA
                </Link>
              </Flex>
            </Table.Cell>
            <Table.Cell backgroundColor="green-200">
              <Flex alignItems={'center'} gap={3}>
                {currentSubmission.stage}
                {hasOpenApproval(
                  currentSubmission.stage,
                  currentSubmission.approvedDate
                ) && <Tag>Open</Tag>}
              </Flex>
            </Table.Cell>
            <Table.Cell backgroundColor="green-200">
              {renderStatus(currentSubmission.status)}
            </Table.Cell>
          </Table.Row>
        )}
        {sortedOtherSubmissions?.map((sub) => (
          <Table.Row key={sub.submissionUuid}>
            <Table.Cell>{sub.submissionName}</Table.Cell>
            <Table.Cell>{sub.owner1FullName}</Table.Cell>
            <Table.Cell>{sub.owner2FullName}</Table.Cell>
            <Table.Cell>
              {isos?.find((s) => s.uuid === sub.isoUuid)?.name}
            </Table.Cell>
            <Table.Cell>
              <Flex gap={2} flexDirection={'row'} alignItems={'center'}>
                {sub.dateAppReceived && formatDateString(sub.dateAppReceived)}
                <Link
                  newTab
                  href={
                    new URL(
                      `/admin/applications/${sub.submissionUuid}`,
                      UNDERWRITING_BASE_URL()
                    )
                  }
                >
                  UA
                </Link>
                <Link
                  newTab
                  href={
                    new URL(
                      `/admin/prospective_merchants/${sub.accountUuid}/sheets/${sub.submissionUuid}`,
                      BANK_BASE_URL()
                    )
                  }
                >
                  BA
                </Link>
                {sub.isRenewal && <Tag>Renewal</Tag>}
              </Flex>
            </Table.Cell>
            <Table.Cell>
              <Flex flexDirection="row" gap={2}>
                {sub.stage === 'Declined' && (
                  <DeclineInfoModal
                    currentSubmission={currentSubmission}
                    previousSubmission={sub}
                  />
                )}
                {sub.stage}
                {hasOpenApproval(sub.stage, sub.approvedDate) && (
                  <Tag>open</Tag>
                )}
              </Flex>
            </Table.Cell>
            <Table.Cell>{renderStatus(sub.status)}</Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
};
