import React from 'react';
import {
  formatDateTimeString,
  Flex,
  Link,
  Loading,
  Table,
  Tag,
} from '@forward-financing/fast-forward';

import { BANK_BASE_URL, UNDERWRITING_BASE_URL } from 'constants/globals';

import { useExperianConsumer } from './matchedRecordsHooks';
import {
  CustomerSubmission,
  BankingAdditionalData,
} from './matchedRecords.types';
import { IndependentSalesOrganization } from '../ApplicationSnapshot/applicationSnapshot.types';

export interface AdditionalDataModalRowProps {
  submission: CustomerSubmission;
  isCurrentSubmission: boolean;
  submissionAdditionalData: BankingAdditionalData | undefined;
  isos: IndependentSalesOrganization[] | undefined;
  ownerUuid: string;
}

export const AdditionalDataModalRow = ({
  submission,
  isCurrentSubmission,
  submissionAdditionalData,
  isos,
  ownerUuid,
}: AdditionalDataModalRowProps): JSX.Element => {
  const {
    data: experianConsumer,
    loading: experianConsumerLoading,
    error: experianConsumerError,
  } = useExperianConsumer(submission.submissionUuid, ownerUuid);

  const ficoScore =
    experianConsumer !== undefined &&
    experianConsumer.ficoScore !== undefined &&
    // Lowest FICO score is ~300 so we don't have to worry about falsy values
    // for zero.
    experianConsumer.ficoScore;
  const isLowFico = ficoScore !== false && ficoScore < 500;
  const isExperianConsumerNotFound = experianConsumerError?.statusCode === 404;
  const hasExperianConsumerError =
    experianConsumerError && !isExperianConsumerNotFound;

  let ficoWarning: string | undefined;
  if (isLowFico) {
    ficoWarning = 'Low FICO Score Warning';
  } else if (hasExperianConsumerError) {
    ficoWarning = 'Experian Consumer Fetch Error';
  }

  const submissionTypeColor = isCurrentSubmission ? 'green-200' : undefined;
  const isOtherSubmission = !isCurrentSubmission;
  const showRenewalTag = isOtherSubmission && submission.isRenewal;

  if (experianConsumerLoading) {
    return <Loading text="Loading Experian Consumer Data" />;
  }

  return (
    <>
      <Table.Row>
        <Table.Cell backgroundColor={submissionTypeColor}>
          {`${submission.owner1FullName}${
            isCurrentSubmission ? ' (Current Submission)' : ''
          }`}
        </Table.Cell>

        <Table.Cell backgroundColor={submissionTypeColor}>
          {submission.owner2FullName}
        </Table.Cell>

        <Table.Cell backgroundColor={submissionTypeColor}>
          {isos?.find((s) => s.uuid === submission.isoUuid)?.name}
        </Table.Cell>

        <Table.Cell backgroundColor={submissionTypeColor}>
          <Flex gap={2} flexDirection={'row'}>
            {submission.dateAppReceived &&
              formatDateTimeString(submission.dateAppReceived)}

            {isOtherSubmission && (
              <Link
                newTab
                href={
                  new URL(
                    `/admin/applications/${submission.submissionUuid}`,
                    UNDERWRITING_BASE_URL()
                  )
                }
              >
                UA
              </Link>
            )}

            <Link
              newTab
              href={
                new URL(
                  `/admin/prospective_merchants/${submission.accountUuid}/sheets/${submission.submissionUuid}`,
                  BANK_BASE_URL()
                )
              }
            >
              BA
            </Link>
            {showRenewalTag && <Tag>Renewal</Tag>}
          </Flex>
        </Table.Cell>

        <Table.Cell backgroundColor={submissionTypeColor}>
          {submission.underwriter}
        </Table.Cell>

        <Table.Cell
          backgroundColor={
            isLowFico || hasExperianConsumerError
              ? 'red-100'
              : submissionTypeColor
          }
          aria-label={ficoWarning}
        >
          {hasExperianConsumerError
            ? experianConsumerError?.message
            : ficoScore || ''}
        </Table.Cell>

        <Table.Cell backgroundColor={submissionTypeColor}>
          {submissionAdditionalData?.bankAccountNumbers}
        </Table.Cell>

        <Table.Cell backgroundColor={submissionTypeColor}>
          {submissionAdditionalData?.revenueOverride}
        </Table.Cell>

        <Table.Cell backgroundColor={submissionTypeColor}>
          {submissionAdditionalData?.numberPositions}
        </Table.Cell>

        <Table.Cell backgroundColor={submissionTypeColor}>
          {submissionAdditionalData?.grossPositions}
        </Table.Cell>

        <Table.Cell backgroundColor={submissionTypeColor}>
          {submissionAdditionalData?.totalGrossPercent}
        </Table.Cell>

        <Table.Cell backgroundColor={submissionTypeColor}>
          {submissionAdditionalData?.maxApproval}
        </Table.Cell>

        <Table.Cell backgroundColor={submissionTypeColor}>
          {submissionAdditionalData?.termLength}
        </Table.Cell>

        <Table.Cell backgroundColor={submissionTypeColor}>
          {submissionAdditionalData?.program}
        </Table.Cell>
      </Table.Row>
    </>
  );
};
