import { useCallback } from 'react';
import { fetchFeatureFlagWithIdentifier } from 'api/underwriting/featureFlagFetchUtils';
import { useGenericQuery } from 'apiHooks/genericFetchHooks';

export const useGetApiFeatureFlagWithIdentifier = (
  flag: string,
  identifier: string
): boolean | undefined => {
  const getFlag = useCallback(() => {
    return fetchFeatureFlagWithIdentifier(flag, identifier);
  }, [flag, identifier]);

  return useGenericQuery(getFlag).data?.[flag];
};
