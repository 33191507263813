import { HashMap } from 'api/codecs';

export const v6ScoreFeatures: HashMap<string> = {
  BSMET_DEPOSIT_COUNT_MIN: 'Minimum monthly deposit count over last 3 months',
  ENSCO_SCORE_VANTAGE_max: 'Vantage score: consumer credit score',
  BSMET_DEPOSIT_DOLLARS_MAX:
    'Maximum monthly deposit dollars over last 3 months',
  BSMET_END_BALANCE_MIN: 'Minimum monthly end balance over last 3 months',
  ENTRA_MOR_MAX_YEARS_SINCE_OPENED_max: 'Age of oldest Mortgage tradeline',
  ENSCO_SCORE_FICO_max: 'FICO score: consumer credit score',
  ENINQ_INQUIRY_CNT_L90D_max: 'Number of inquiries over last 90 days',
  PNSCO_PAYNET_SCORE: 'Paynet score: business credit score',
  BSMET_NEGATIVE_DAYS_AVG: 'Average number of days the bank account was < 0',
  ENTRA_PERC_CLOSED_LAST_PYMNT_MISSED_max:
    'Percent of closed tradelines with a delinquent status',
  EMSCO_INTELLISCORE: 'Intelliscore: business credit score',
  ENADD_ADDRESS_YEARS_SINCE_FIRST_REPORTED_max:
    'Age of oldest reported personal address of the owner',
  ENAGG_REVOLVING_BALANCE_max: 'Dollar amount of revolving tradelines',
  EMAGG_SBCS1_IFTC01:
    'Total number of Inquiries in the last 3 months for Financial credit purposes.',
  APBUS_YRS_IN_BUSINESS: 'Time in business',
  BSMUL_OTHER_POSITION_GROSS_DEPOSIT_DOLLARS:
    'Total monthly payments to other funders as a percentage of monthly deposit dollars',
  ENTRA_AUT_MAX_YEARS_SINCE_OPENED_max: 'Age of oldest Auto tradeline',
  BSMET_NSFS_AVG:
    'Average monthly non-sufficient fund charges over last 3 months',
  ENTRA_CRE_OTHER_USER_CNT_max:
    'Number of terminated credit cards and lines of credit',
  ENTRA_CREDIT_LIMIT_max: 'Total credit limit of all open tradelines',
  BSMET_REVENUE_VELOCITY:
    'Total revenue in last month as a percentage of total revenue over the last 3 months',
  EMSCO_YEARS_IN_FILE: 'Years business has been in Experian file',
  ENTRA_REV_OPEN_BALANCE_AMT_max: 'Balance on open revolving tradelines',
  LPJUD_JUDGMENT_YEARS_SINCE_LAST_FILED_max: 'Years since last judgment filed',
  EMAGG_SBCS2_FTC245:
    'Change of current balance over last 3 month average balance on FTC trades',
};
