import React, { useState } from 'react';
import {
  Accordion,
  Banner,
  Box,
  Button,
  Divider,
  Flex,
  Radio,
  Subheading,
  Text,
} from '@forward-financing/fast-forward';
import { useUserContext } from 'contexts/UserContext';
import { toError } from 'helpers/errorUtils';
import { FFLogger } from 'api/LogClient';
import { featureFlags } from 'helpers/featureFlags';
import {
  useCreateExceptionRequest,
  useCreditDecision,
  useUpdateLedger,
} from '../exceptionsRequestHooks';
import { ExceptionRequest, Offer } from '../exceptionsRequest.types';
import { SALES_KEY } from '../exceptionRequestConstants';
import { SummaryRow } from '../SummaryRow';
import { PaymentFrequency } from '../types';
import { ExceptionsRequestRow } from './ExceptionsRequestRow';
import { ExceptionsRequestForm } from './ExceptionsRequestForm';
import { ExceptionRequestFormShape } from './ExceptionsRequestContainer';
import { ProcessingFeeAttributeTable } from './ProcessingFeeAttributeTable';
import { ProcessingFeeAttributeForm } from './ProcessingFeeAttributeForm';

export interface ExceptionsRequestProps {
  submissionUuid: string;
  exceptionsRequestList: ExceptionRequest[];
  currentOffer?: Offer;
  reloadExceptionRequestsManager: () => void;
}

export const ExceptionsRequest = (
  props: ExceptionsRequestProps
): JSX.Element => {
  const [showAddNewRequestForm, setShowAddNewRequestForm] = useState(false);
  const [
    showFrequencyChangeSuccessMessage,
    setShowFrequencyChangeSuccessMessage,
  ] = useState(false);
  const [
    createExceptionRequest,
    { error: createError, loading: createLoading },
  ] = useCreateExceptionRequest();

  const [showProcessingFeeAttributeForm, setShowProcessingFeeAttributeForm] =
    useState(false);

  const currentUser = useUserContext();

  const { creditDecision, responseReady: creditDecisionResponseReady } =
    useCreditDecision(props.submissionUuid);

  const [formFrequency, setFormFrequency] = useState<
    PaymentFrequency | undefined
  >();

  const [savedFrequency, setSavedFrequency] = useState<
    PaymentFrequency | undefined
  >();

  const [
    updateLedger,
    { loading: updateLedgerLoading, error: updateLedgerError },
  ] = useUpdateLedger();

  const onSaveFrequencyChange = async (): Promise<void> => {
    try {
      const { success } = await updateLedger({
        submissionUuid: props.submissionUuid,
        body: {
          frequency: formFrequency === 'Weekly' ? 'weekly' : 'daily',
        },
      });

      if (success) {
        setShowFrequencyChangeSuccessMessage(true);
        setSavedFrequency(formFrequency);
      }
    } catch (e: unknown) {
      const error = toError(e);
      FFLogger.error(error);
    }
  };

  const handleCreateNew = async (
    formData: ExceptionRequestFormShape
  ): Promise<void> => {
    await createExceptionRequest({
      submissionUuid: props.submissionUuid,
      requestingUser: `${currentUser?.first_name} ${currentUser?.last_name}`,
      fundingAmount: formData.fundingAmount,
      buyRate: formData.buyRate,
      program: formData.program,
      termLength: formData.termLength,
      stipExceptionNotes: formData.stipExceptionNotes,
      offerExceptionNotes: formData.offerExceptionNotes,
      underwritingOfferId: props.currentOffer?.id,
    });

    props.reloadExceptionRequestsManager();
  };

  const onAddNewClick = (): void => {
    setShowAddNewRequestForm(true);
  };

  const onCancelAddNewClick = (): void => {
    setShowAddNewRequestForm(false);
  };

  const onCreateNew = async (
    submitPayload: ExceptionRequestFormShape
  ): Promise<void> => {
    await handleCreateNew(submitPayload);
    setShowAddNewRequestForm(false);
  };

  const showAddNewRequestButton =
    !props.exceptionsRequestList?.some((request) => request.inReview) &&
    !showAddNewRequestForm;

  const isCurrentUserSalesManager =
    currentUser.role === 'manager' && currentUser.sub_role === 'manager';

  const shouldShowProcessingFeeAttributesSection =
    isCurrentUserSalesManager &&
    featureFlags.show_processing_fee_attributes_section;

  if (!creditDecisionResponseReady) {
    return <></>;
  }

  return (
    <>
      {showFrequencyChangeSuccessMessage && (
        <Banner variant="success" dismissable={false}>
          Success! The payment frequency was updated. Please allow several
          minutes for the change to take effect.
        </Banner>
      )}
      {createError && (
        <Banner dismissable={false}>{createError.message}</Banner>
      )}
      {updateLedgerError && <Banner>{updateLedgerError.message}</Banner>}
      <Flex py={3} justifyContent="space-between">
        <Subheading>Exceptions Request(s)</Subheading>
        {showAddNewRequestButton && (
          <Button onClick={onAddNewClick}>Add new</Button>
        )}
      </Flex>
      {showAddNewRequestForm && (
        <Box py={2}>
          <ExceptionsRequestForm
            currentOffer={props.currentOffer}
            onCancel={onCancelAddNewClick}
            onSubmit={onCreateNew}
            isSubmitDisabled={createLoading}
          />
        </Box>
      )}
      {props.exceptionsRequestList?.length === 0 ? (
        <Text>
          There are currently no exception requests for this submission.
        </Text>
      ) : (
        <Accordion
          type="multiple"
          defaultValue={[props.exceptionsRequestList[0].id.toString()]}
        >
          {props.exceptionsRequestList.map((exceptionRequest) => {
            return (
              <Accordion.Item
                key={exceptionRequest.id}
                value={exceptionRequest.id.toString()}
              >
                <Accordion.Trigger>
                  <SummaryRow
                    displayStatus={exceptionRequest.displayStatus}
                    submittedAt={exceptionRequest.submittedAt}
                    viewer={SALES_KEY}
                    collaboratorName={exceptionRequest.reviewerUser}
                    reviewCompletionTime={exceptionRequest.reviewCompletedAt}
                    decisionType={exceptionRequest.decisionType}
                  />
                </Accordion.Trigger>
                <Accordion.Content>
                  <ExceptionsRequestRow
                    currentOffer={props.currentOffer}
                    exceptionRequest={exceptionRequest}
                    reloadExceptionRequestsManager={
                      props.reloadExceptionRequestsManager
                    }
                  />
                </Accordion.Content>
              </Accordion.Item>
            );
          })}
        </Accordion>
      )}
      {creditDecision?.frequency && featureFlags.weekly_eligible && (
        <Box>
          <Divider />
          <Radio
            name="Payment Frequency"
            onChange={(newValue) =>
              setFormFrequency(newValue as PaymentFrequency)
            }
            value={formFrequency || creditDecision.frequency}
            orientation="vertical"
          >
            <Radio.Item
              id="Daily"
              value="Daily"
              disabled={!creditDecision?.eligibleForWeekly}
            />
            <Radio.Item
              id="Weekly"
              value="Weekly"
              disabled={!creditDecision?.eligibleForWeekly}
            />
          </Radio>
          <Button
            onClick={onSaveFrequencyChange}
            disabled={
              updateLedgerLoading ||
              !formFrequency ||
              (savedFrequency
                ? formFrequency === savedFrequency
                : formFrequency === creditDecision.frequency)
            }
          >
            {updateLedgerLoading ? 'Saving' : 'Save'}
          </Button>
        </Box>
      )}
      {shouldShowProcessingFeeAttributesSection && creditDecision && (
        <>
          <Flex py={3} justifyContent="space-between">
            <Subheading>Processing Fee Attributes</Subheading>
            {!showProcessingFeeAttributeForm && (
              <Button onClick={() => setShowProcessingFeeAttributeForm(true)}>
                Add new attributes
              </Button>
            )}
          </Flex>
          {showProcessingFeeAttributeForm ? (
            <ProcessingFeeAttributeForm
              creditDecision={creditDecision}
              submissionUuid={props.submissionUuid}
              onExitForm={() => setShowProcessingFeeAttributeForm(false)}
              reloadExceptionsRequestManager={
                props.reloadExceptionRequestsManager
              }
            />
          ) : (
            <ProcessingFeeAttributeTable creditDecision={creditDecision} />
          )}
        </>
      )}
    </>
  );
};
