import { defaultTo } from 'lodash';
import { useGetApiInference } from 'apiHooks/mldp/inferenceFetchHooks';
import { InferenceResponse, Input } from 'types/api/mldp/types';
import { UseGenericQueryResponse } from 'apiHooks/genericFetchHooks';
import { useGenericFeatureQuery } from 'components/featureHooks/genericFeatureHooks';
import { V6Scores } from './v6ScoreDisplay.types';
import { v6ScoreFeatures } from './v6ScoreDisplayHelpers';

const toHumanReadableFeature = (variable: string): string =>
  defaultTo(v6ScoreFeatures[variable], variable);

const getScoreFromFeature = (
  feature: string,
  inputs: Input[]
): number | undefined => {
  const input = inputs.find((i) => i.key === feature);
  return input?.value;
};

const getInputValueFromFeature = (
  feature: string,
  inputs: Input[]
): number | undefined => {
  const input = inputs.find((i) => i.key === feature);
  return input?.input_value;
};

const toV6Scores = (
  inferenceData?: InferenceResponse
): V6Scores | undefined => {
  if (!inferenceData) {
    return undefined;
  }

  return {
    score: inferenceData.details.score,
    topFive: inferenceData.details.top_five.map((feature) => ({
      name: toHumanReadableFeature(feature),
      score: getScoreFromFeature(feature, inferenceData.details.inputs),
      inputValue: getInputValueFromFeature(
        feature,
        inferenceData.details.inputs
      ),
    })),
    bottomFive: inferenceData.details.bottom_five.map((feature) => ({
      name: toHumanReadableFeature(feature),
      score: getScoreFromFeature(feature, inferenceData.details.inputs),
      inputValue: getInputValueFromFeature(
        feature,
        inferenceData.details.inputs
      ),
    })),
    createdAt: inferenceData.created_at,
  };
};

export const useInference = (
  submissionUuid: string
): UseGenericQueryResponse<V6Scores> =>
  useGenericFeatureQuery(useGetApiInference, toV6Scores, submissionUuid);
