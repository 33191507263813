import { useCallback } from 'react';
import {
  deleteDocument,
  fetchBusinessPaynetReport,
  fetchCustomer,
  fetchCustomerEstablishedDates,
  fetchCustomerIneligibilities,
  fetchCustomerSubmissions,
  fetchWebPresences,
  patchCustomer,
  patchCustomerWebPresence,
  patchCustomerWebPresenceBulk,
} from 'api/underwriting/customerFetchUtils';
import {
  useGenericMutation,
  UseGenericMutationResponse,
  useGenericQuery,
  UseGenericQueryResponse,
  useLazyGenericQuery,
  UseLazyGenericQueryFetcher,
  UseLazyGenericQueryResult,
} from 'apiHooks/genericFetchHooks';
import {
  CustomerResponse,
  SubmissionResponse,
  CustomerEstablishedDatesResponse,
  CustomerWebPresenceResponse,
  UpdateCustomerRequestBody,
  UpdateApiCustomerWebPresenceBody,
  CustomerWebPresencePatchResponse,
  WebPresenceType,
  BulkUpdateApiCustomerWebPresenceBody,
  BulkUpdateCustomerWebPresenceResponse,
  IneligibilitiesResponse,
  BusinessPaynetResponse,
  SOSFieldsResponse,
} from 'types/api/underwriting/types';
import { fetchSOSFields } from 'components/SubmissionUnderwriting/BusinessOverview/businessOverviewFetchUtils';

export type UseGetApiCustomerResponse =
  UseGenericQueryResponse<CustomerResponse>;

export const useGetApiCustomer = (
  customerUuid?: string
): UseGetApiCustomerResponse => {
  const getCustomer = useCallback(() => {
    if (!customerUuid) {
      return undefined;
    }

    return fetchCustomer(customerUuid);
  }, [customerUuid]);

  return useGenericQuery(getCustomer);
};

interface UseLazyGetApiCustomerArgs {
  customerUuid: string;
}

export type UseLazyGetApiCustomerFetcher =
  UseLazyGenericQueryFetcher<UseLazyGetApiCustomerArgs>;

export type UseLazyGetApiSubmissionResponse =
  UseLazyGenericQueryResult<CustomerResponse>;

const fetchCustomerWithArgs = (
  args: UseLazyGetApiCustomerArgs
): Promise<CustomerResponse> => {
  return fetchCustomer(args.customerUuid);
};

export const useLazyGetApiCustomer = (): [
  UseLazyGetApiCustomerFetcher,
  UseLazyGetApiSubmissionResponse
] => {
  return useLazyGenericQuery(fetchCustomerWithArgs);
};

export const useGetCustomerSubmissions = (
  customerUuid?: string
): UseGenericQueryResponse<SubmissionResponse[]> => {
  const getCustomerSubmission = useCallback(() => {
    if (!customerUuid) {
      return undefined;
    }
    return fetchCustomerSubmissions(customerUuid);
  }, [customerUuid]);

  return useGenericQuery(getCustomerSubmission);
};

export const useGetApiCustomerEstablishedDates = (
  customerUuid?: string
): UseGenericQueryResponse<CustomerEstablishedDatesResponse> => {
  const getCustomerEstablishedDates = useCallback(() => {
    if (!customerUuid) {
      return undefined;
    }
    return fetchCustomerEstablishedDates(customerUuid);
  }, [customerUuid]);

  return useGenericQuery(getCustomerEstablishedDates);
};

export const useGetApiWebPresences = (
  accountUuid?: string
): UseGenericQueryResponse<CustomerWebPresenceResponse> => {
  const getWebPresences = useCallback(() => {
    if (!accountUuid) {
      return undefined;
    }
    return fetchWebPresences(accountUuid);
  }, [accountUuid]);

  return useGenericQuery(getWebPresences);
};

export interface UseUpdateCustomerArgs {
  customerUuid: string;
  updateBody: UpdateCustomerRequestBody;
}

export type UseUpdateCustomerResponse = UseGenericMutationResponse<
  CustomerResponse,
  UseUpdateCustomerArgs
>;

const updateCustomer = (
  input: UseUpdateCustomerArgs
): Promise<CustomerResponse> => {
  return patchCustomer(input.customerUuid, input.updateBody);
};

export const useUpdateApiCustomer = (): UseUpdateCustomerResponse => {
  return useGenericMutation(updateCustomer);
};

/**
 * useUpdateApiCustomerWebPresence
 * useBulkUpdateApiCustomerWebPresence
 *
 * NOTE WELL: We are currently using the accountUuid instead of the customerUuid
 * for the web presence endpoints. The backend does not support using
 * customerUuid yet. So the names of the hooks and the types are a bit
 * misleading during this transition period, because they are named for the
 * future state where we will use customerUuid.
 *
 */
export interface UseUpdateCustomerWebPresenceArgs {
  accountUuid: string;
  webPresenceType: WebPresenceType;
  body: UpdateApiCustomerWebPresenceBody;
}

export type UseUpdateCustomerWebPresenceResponse = UseGenericMutationResponse<
  CustomerWebPresencePatchResponse,
  UseUpdateCustomerWebPresenceArgs
>;

const updateCustomerWebPresence = (
  input: UseUpdateCustomerWebPresenceArgs
): Promise<CustomerWebPresencePatchResponse> =>
  patchCustomerWebPresence(
    input.accountUuid,
    input.webPresenceType,
    input.body
  );

export const useUpdateApiCustomerWebPresence =
  (): UseUpdateCustomerWebPresenceResponse => {
    return useGenericMutation(updateCustomerWebPresence);
  };

export interface UseBulkUpdateCustomerWebPresenceArgs {
  accountUuid: string;
  body: BulkUpdateApiCustomerWebPresenceBody;
}

export type UseBulkUpdateCustomerWebPresenceResponse =
  UseGenericMutationResponse<
    BulkUpdateCustomerWebPresenceResponse,
    UseBulkUpdateCustomerWebPresenceArgs
  >;

const bulkUpdateCustomerWebPresence = (
  input: UseBulkUpdateCustomerWebPresenceArgs
): Promise<BulkUpdateCustomerWebPresenceResponse> =>
  patchCustomerWebPresenceBulk(input.accountUuid, input.body);

export const useBulkUpdateApiCustomerWebPresence =
  (): UseBulkUpdateCustomerWebPresenceResponse => {
    return useGenericMutation(bulkUpdateCustomerWebPresence);
  };

export const useGetApiIneligibilities = (
  customerUuid?: string
): UseGenericQueryResponse<IneligibilitiesResponse> => {
  const fetchFunction = useCallback(() => {
    if (!customerUuid) {
      return undefined;
    }

    return fetchCustomerIneligibilities(customerUuid);
  }, [customerUuid]);

  return useGenericQuery(fetchFunction);
};

export const useGetApiBusinessPaynetReport = (
  submissionUuid?: string
): UseGenericQueryResponse<BusinessPaynetResponse[]> => {
  const fetchFunction = useCallback(() => {
    if (!submissionUuid) {
      return undefined;
    }

    return fetchBusinessPaynetReport(submissionUuid);
  }, [submissionUuid]);

  return useGenericQuery(fetchFunction);
};

export const useGetApiSOSFields = (
  submissionUuid?: string
): UseGenericQueryResponse<SOSFieldsResponse> => {
  const fetchFunction = useCallback(() => {
    if (!submissionUuid) {
      return undefined;
    }

    return fetchSOSFields(submissionUuid);
  }, [submissionUuid]);

  return useGenericQuery<SOSFieldsResponse>(fetchFunction);
};

const deleteReportFunction = (reportId: string): Promise<boolean> => {
  return deleteDocument(reportId);
};

export const useDeleteApiReport = (): UseGenericMutationResponse<
  boolean,
  string
> => {
  return useGenericMutation(deleteReportFunction);
};
