import React from 'react';
import {
  Banner,
  Box,
  Grid,
  Loading,
  Subheading,
} from '@forward-financing/fast-forward';
import { V6ScoreDisplayTable } from './V6ScoreDisplayTable';
import { useInference } from './v6ScoreDisplayHooks';

type V6ScoreDisplayProps = {
  submissionUuid: string;
};

export const V6ScoreDisplay = ({
  submissionUuid,
}: V6ScoreDisplayProps): JSX.Element => {
  const {
    data: inferenceData,
    loading: inferenceLoading,
    error: inferenceError,
  } = useInference(submissionUuid);

  if (inferenceLoading) {
    return <Loading />;
  }

  if (inferenceError) {
    return <Banner>{inferenceError?.message}</Banner>;
  }

  return (
    <Box mb={4}>
      <Subheading>V6 Score: {inferenceData?.score}/20</Subheading>

      <Grid gutter>
        <Grid.Item l={12} xl={6}>
          <Subheading variant="section">Top Supporters</Subheading>

          <V6ScoreDisplayTable features={inferenceData?.topFive} />
        </Grid.Item>

        <Grid.Item l={12} xl={6}>
          <Subheading variant="section">Top Detractors</Subheading>

          <V6ScoreDisplayTable features={inferenceData?.bottomFive} />
        </Grid.Item>
      </Grid>
    </Box>
  );
};
