import React, { useEffect, useState } from 'react';
import {
  Banner,
  PageContainer,
  StickyContainer,
} from '@forward-financing/fast-forward';
import { featureFlags } from 'helpers/featureFlags';
import { NewDealScoringContainer } from './NewDealScoring/NewDealScoringContainer';
import { NewDealScoring } from './NewDealScoring/NewDealScoring';
import { RenewalScoringContainer } from './RenewalScoring/RenewalScoringContainer';
import { useScoringComposite, useSubmission } from './DealScoringFetchHooks';
import {
  NewDealScoreComposite,
  RenewalScoreComposite,
} from './DealScoring.types';

export interface DealScoringContainerProps {
  submissionUuid: string;
}

export const DealScoringContainer = ({
  submissionUuid,
}: DealScoringContainerProps): JSX.Element => {
  const [previousFundedSubmissionUuid, setPreviousFundedSubmissionUuid] =
    useState<string>();

  const {
    data: submission,
    error: submissionError,
    loading: submissionLoading,
  } = useSubmission(submissionUuid);

  const {
    data: compositeScore,
    error: compositeScoreError,
    loading: compositeScoreLoading,
  } = useScoringComposite(submissionUuid);

  useEffect(() => {
    if (submission) {
      setPreviousFundedSubmissionUuid(
        submission.previousFundedSubmissionUuidFromCustomer ||
          submission.previousFundedSubmissionUuid
      );
    }
  }, [submission]);

  if (
    submissionError ||
    (compositeScoreError && compositeScoreError.statusCode !== 404)
  ) {
    return (
      <>
        {submissionError && (
          <Banner dismissable={false}>{submissionError.message}</Banner>
        )}
        {compositeScoreError && compositeScoreError.statusCode !== 404 && (
          <Banner dismissable={false}>{compositeScoreError.message}</Banner>
        )}
      </>
    );
  }

  if (submissionLoading || compositeScoreLoading) {
    // return nothing while loading
    return <></>;
  }

  const showNewDealScoring: boolean =
    featureFlags.render_scoring_section_based_on_composite_score
      ? (compositeScore && compositeScore.type === 'New Deal') ||
        (!compositeScore && submission?.type === 'New Deal')
      : submission?.type === 'New Deal';

  // Note that the type coersion below is necessary to support the above feature flag.
  // When the feature flag is no longer needed, the types will be inferred correctly
  // via the type (or absence) of the composite score.
  return (
    <StickyContainer stickTo="bottom">
      <PageContainer>
        {showNewDealScoring && submission ? (
          <NewDealScoringContainer
            submissionUuid={submissionUuid}
            customerUuid={submission.customerUuid}
            compositeScore={compositeScore as NewDealScoreComposite | undefined}
          >
            <NewDealScoring />
          </NewDealScoringContainer>
        ) : (
          <RenewalScoringContainer
            submissionUuid={submissionUuid}
            previousFundedSubmissionUuid={previousFundedSubmissionUuid}
            compositeScore={compositeScore as RenewalScoreComposite | undefined}
          />
        )}
      </PageContainer>
    </StickyContainer>
  );
};
