import { formatDateString } from '@forward-financing/fast-forward';
import { UseGenericQueryResponse } from 'apiHooks/genericFetchHooks';
import { useGenericFeatureQuery } from 'components/featureHooks/genericFeatureHooks';
import { useGetApiBusinessLexisNexisReport } from 'apiHooks/underwriting/documentsFetchHooks';
import {
  BusinessLexisNexisAddress,
  BusinessLexisNexisBankruptcy,
  BusinessLexisNexisBusinessRegistration,
  BusinessLexisNexisCorporateHistory,
  BusinessLexisNexisCreditor,
  BusinessLexisNexisDebtor,
  BusinessLexisNexisEvent,
  BusinessLexisNexisExecutive,
  BusinessLexisNexisLiensJudgment,
  BusinessLexisNexisNameVariation,
  BusinessLexisNexisPayload,
  BusinessLexisNexisProfile,
  BusinessLexisNexisUcc,
  BusinessLexisNexisUccFilingEntity,
  FilingResponse,
  FilingsResponse,
  BusinessLexisNexisProfileContact,
  LexisNexisDateResponse,
  LexisNexisNameResponse,
  PhoneInfo,
} from 'types/api/underwriting/types';
import { isDateInPast } from 'helpers/date/dateUtils';
import { toAddressString } from '../helpers';
import {
  Bankruptcy,
  BusinessContact,
  BusinessInformation,
  BusinessLexisNexisReport,
  BusinessRegistration,
  CorporateHistory,
  Creditor,
  Debtor,
  Event,
  Executive,
  Filing,
  LienJudgment,
  NameVariation,
  Profile,
  UccFiling,
  UccFilingEntity,
} from './BusinessLexisNexisPage.types';

const toDateString = (dobObject?: LexisNexisDateResponse): string => {
  const dateArray = [dobObject?.month, dobObject?.day, dobObject?.year];
  return dateArray.filter(Boolean).join('/');
};

const toNameString = (nameObject: LexisNexisNameResponse): string => {
  const nameArray = [
    nameObject.prefix,
    nameObject.first,
    nameObject.middle,
    nameObject.last,
    nameObject.suffix,
  ];
  return nameArray.filter(Boolean).join(' ');
};

const toBusinessInformation = (
  companyName: string,
  address: BusinessLexisNexisAddress,
  phoneNumbersInput?: PhoneInfo | PhoneInfo[]
): BusinessInformation => {
  const phoneNumbers = phoneNumbersInput
    ? [phoneNumbersInput].flat().map((p) => p.phone_info.phone10)
    : [];

  return {
    companyName,
    address: toAddressString(address),
    phoneNumbers,
  };
};

const toNameVariation = (
  nameVariation: BusinessLexisNexisNameVariation
): NameVariation => {
  return { name: nameVariation.company_name };
};

const toBusinessRegistration = (
  registration: BusinessLexisNexisBusinessRegistration
): BusinessRegistration => {
  return {
    filingCode: registration.filing_code,
    companyName: registration.company_name,
    companyPhone: registration.company_phone,
    mailingAddress: toAddressString(registration.mailing_address),
    corporationCode: registration.corporation_code,
  };
};

const toBusinessContact = (
  contact: BusinessLexisNexisProfileContact
): BusinessContact => {
  return {
    company: contact.company_name,
    asOfDate: toDateString(contact.as_of_date),
    contactType: contact.contact_types.contact_type
      ? [contact.contact_types.contact_type].flat()
      : [],
  };
};

const toEvent = (event: BusinessLexisNexisEvent): Event => {
  return {
    filingType: event.filing_type,
    filingDate: toDateString(event.filing_date),
  };
};

const toCorporateHistory = (
  history: BusinessLexisNexisCorporateHistory
): CorporateHistory => {
  return {
    companyName: history.company_name,
    incorporateDate: toDateString(history.incorporate_date),
    address: history.address ? toAddressString(history.address) : undefined,
    entityType: history?.company_type?.entity_type,
    businessType: history?.company_type?.business_type,
    additionalInfo: history?.company_type?.additional_info,
    status: history.status,
    recordDate: toDateString(history.record_date),
    nameType: history.name_type,
    statusDate: toDateString(history.status_date),
    registeredAgent: history.registered_agent
      ? toNameString(history.registered_agent.name)
      : undefined,
    asAgentDate: history.as_agent_date,
  };
};

const toProfile = (profile: BusinessLexisNexisProfile): Profile => {
  return {
    businessContacts: profile.contacts?.contact
      ? [profile.contacts.contact].flat().map(toBusinessContact)
      : [],
    events: profile.events?.event
      ? [profile.events.event].flat().map(toEvent)
      : [],
    corporateHistories: profile.corporate_histories?.corporate_history
      ? [profile.corporate_histories.corporate_history]
          .flat()
          .map(toCorporateHistory)
      : [],
  };
};

const toExecutive = (executive: BusinessLexisNexisExecutive): Executive => {
  return {
    name: toNameString(executive.name),
    dateLastSeen: toDateString(executive.date_last_seen),
    title: executive.company_titles.title
      ? [executive.company_titles.title].flat()
      : [],
  };
};

const toDebtor = (debtor: BusinessLexisNexisDebtor): Debtor => {
  return {
    name: debtor.parsed_parties.party.company_name,
    companyName: debtor.parsed_parties.party.company_name,
    address:
      debtor.addresses &&
      [debtor.addresses.address].flat().map(toAddressString),
  };
};

const toCreditor = (creditor: BusinessLexisNexisCreditor): Creditor => {
  return {
    name: creditor.name,
    companyName: creditor.parsed_parties.party.company_name,
    address:
      creditor.addresses &&
      [creditor.addresses.address].flat().map(toAddressString),
  };
};

const toFiling = (filing: FilingResponse): Filing => {
  return {
    type: filing.type,
    originFilingDate: toDateString(filing.origin_filing_date),
    agency: filing.agency,
    agencyState: filing.agency_state,
    agencyCounty: filing.agency_county,
    filingNumber: filing.number,
  };
};

const toLiensJudgment = (
  liensJudgment: BusinessLexisNexisLiensJudgment
): LienJudgment => {
  return {
    debtor: [liensJudgment.debtors.debtor].flat().map(toDebtor),
    creditor: [liensJudgment.creditors.creditor].flat().map(toCreditor),
    filings: [liensJudgment.filings.filing].flat().map(toFiling),
    amount: liensJudgment.amount ?? undefined,
  };
};

const toUccFilingEntity = (
  uccFilingEntity: BusinessLexisNexisUccFilingEntity
): UccFilingEntity => {
  return {
    originName: uccFilingEntity.origin_name,
    addresses: [uccFilingEntity.addresses.address].flat().map(toAddressString),
  };
};

const getUccStatusText = (
  filingsResponse: FilingsResponse[],
  judgeSatisfiedDate?: string,
  judgeVacatedDate?: string,
  releaseDate?: string
): boolean => {
  const filingsTypes = filingsResponse.map((f) => f.type);

  const releasedKeyWords = [
    'release',
    'satisfied',
    'discharge',
    'dismiss',
    'terminat', // This is to match words like "terminated", "terminate", "terminating", etc.
  ];

  const hasReleasedType =
    filingsTypes.filter((type) => {
      return releasedKeyWords.some((keyWord) =>
        type?.toLowerCase().includes(keyWord)
      );
    }).length > 0;

  const isReleased =
    [judgeSatisfiedDate, releaseDate, judgeVacatedDate].filter(Boolean).length >
      0 || hasReleasedType;

  const hasExpireStatus =
    [filingsResponse].flat().filter((f) => f.filing_status?.includes('expir')) // This is to match words like "expired", "expiration", etc.
      .length > 0;

  const hasExpirationDateInPast =
    [filingsResponse].flat().filter((f) => {
      if (f.expiration_date) {
        const date = new Date(
          formatDateString(toDateString(f.expiration_date))
        );
        return isDateInPast(date);
      }
      return false;
    }).length > 0;

  const isExpired = hasExpireStatus || hasExpirationDateInPast;

  return isExpired || isReleased;
};

const toUccFiling = (uccFiling: BusinessLexisNexisUcc): UccFiling => {
  return {
    debtor: uccFiling.debtors?.debtor
      ? [uccFiling.debtors.debtor].flat().map(toUccFilingEntity)
      : [],
    secured: uccFiling.secureds?.secured
      ? [uccFiling.secureds.secured].flat().map(toUccFilingEntity)
      : [],
    originFilingDate: toDateString(uccFiling.origin_filing_date),
    status: getUccStatusText(
      uccFiling.filings?.filing ? [uccFiling.filings.filing].flat() : [],
      uccFiling.judge_satisfied_date,
      uccFiling.judge_vacated_date,
      uccFiling.release_date
    )
      ? 'Closed'
      : 'Active',
    collateral: uccFiling.collaterals
      ? [uccFiling.collaterals.collateral]
          .flat()
          .map((coll) => coll.description)
      : [],
  };
};

const toBankruptcy = (bankruptcy: BusinessLexisNexisBankruptcy): Bankruptcy => {
  return {
    chapter: bankruptcy.original_chapter,
    filingDate: toDateString(bankruptcy.original_filing_date),
    statusHistory: [bankruptcy.status_history.status].flat().map((stat) => ({
      type: stat.type,
      date: toDateString(stat.date),
    })),
  };
};

export const toBusinessLexisNexisReport = (
  response: BusinessLexisNexisPayload
): BusinessLexisNexisReport => {
  const report = response.full_payload;

  return {
    submissionUuid: response.submission_uuid,
    businessInformation:
      report.report_for &&
      toBusinessInformation(
        report.report_for?.company_name,
        report.report_for?.address,
        report.phone_variations?.phone_variation
      ),
    nameVariations: report.name_variations?.company_name_variation
      ? [report.name_variations.company_name_variation]
          .flat()
          .map(toNameVariation)
      : [],
    businessRegistrations: report.business_registrations?.business_registration
      ? [report.business_registrations.business_registration]
          .flat()
          .map(toBusinessRegistration)
      : [],
    profiles: report.profiles?.profile
      ? [report.profiles.profile].flat().map(toProfile)
      : [],
    executives: report.executives?.executive
      ? [report.executives.executive].flat().map(toExecutive)
      : [],
    liensJudgments: report.liens_judgments?.lien_judgment
      ? [report.liens_judgments.lien_judgment].flat().map(toLiensJudgment)
      : [],
    uccFilings: report.uc_cs?.ucc
      ? [report.uc_cs.ucc].flat().map(toUccFiling)
      : [],
    bankruptcies: report.bankruptcies?.bankruptcy
      ? [report.bankruptcies.bankruptcy].flat().map(toBankruptcy)
      : [],
  };
};

export const useBusinessLexisNexisReport = (
  submissionUuid?: string
): UseGenericQueryResponse<BusinessLexisNexisReport> =>
  useGenericFeatureQuery(
    useGetApiBusinessLexisNexisReport,
    (data) =>
      data?.documents?.[0] && toBusinessLexisNexisReport(data.documents[0]), // BE endpoint includes all the data we need in the first index
    submissionUuid
  );
