import React, { useMemo, useState } from 'react';
import {
  Modal,
  formatDateTimeString,
  Flex,
  Table,
  Link,
  IconButton,
  Banner,
  Loading,
  Tag,
} from '@forward-financing/fast-forward';
import { BANK_BASE_URL, UNDERWRITING_BASE_URL } from 'constants/globals';
import { featureFlags } from 'helpers/featureFlags';

import { CustomerSubmission } from './matchedRecords.types';
import { IndependentSalesOrganization } from '../ApplicationSnapshot/applicationSnapshot.types';
import {
  useBankingSubmissionData,
  useOwnerCreditDataBatch,
} from './matchedRecordsHooks';
import { AdditionalDataModalRow } from './AdditionalDataModalRow';

export interface AdditionalDataModalProps {
  submissionData: CustomerSubmission[] | undefined;
  isos: IndependentSalesOrganization[] | undefined;
  currentSubmissionUuid?: string;
}

// This is a pretty big repetition of code, but it's the most readable and
// will be deleted as soon as we no longer use the old Experian Consumer API.
// See ticket https://forwardfinancing.atlassian.net/browse/FE-1352
const AdditionalDataTableOld = ({
  submissionData,
  currentSubmissionUuid,
  isos,
}: Partial<AdditionalDataModalProps>): JSX.Element => {
  const submissionDataWithSubmissionUuid = useMemo(
    () => submissionData?.map((s) => s.submissionUuid),
    [submissionData]
  );

  const {
    bankingSubmissionData,
    loading: bankingSubmissionDataLoading,
    error: bankingSubmissionDataError,
  } = useBankingSubmissionData(submissionDataWithSubmissionUuid);

  const {
    ficoScores,
    loading: ficoScoresLoading,
    error: ficoScoresError,
  } = useOwnerCreditDataBatch(submissionDataWithSubmissionUuid);

  const isCurrentSubmission = (sub: CustomerSubmission): boolean =>
    currentSubmissionUuid === sub.submissionUuid;

  const currentSubmission = submissionData?.find((sub) =>
    isCurrentSubmission(sub)
  );

  const sortedOtherSubmissions = submissionData
    ?.filter((sub) => !isCurrentSubmission(sub))
    .sort((a, b) =>
      (a.dateAppReceived ?? 0) >= (b.dateAppReceived ?? 0) ? -1 : 1
    );

  const additionalDataForCurrentSubmission = bankingSubmissionData?.find(
    (s) => s.submissionUuid === currentSubmission?.submissionUuid
  );
  const ficoForCurrentSubmissionOwner =
    currentSubmission?.ownersUuid &&
    ficoScores?.find(
      (f) =>
        f.ownerUuid === currentSubmission.ownersUuid[0] &&
        f.submissionUuid === currentSubmission.submissionUuid
    )?.fico;

  if (ficoScoresLoading || bankingSubmissionDataLoading) {
    return <Loading text="Fetching data..." />;
  }

  return (
    <>
      {ficoScoresError && (
        <Banner dismissable={false} variant="error">
          {ficoScoresError.message}
        </Banner>
      )}

      {bankingSubmissionDataError && (
        <Banner dismissable={false} variant="error">
          {bankingSubmissionDataError.message}
        </Banner>
      )}
      <Table caption="Additional Submissions">
        <Table.Head>
          <Table.ColumnHeader>Owner 1</Table.ColumnHeader>
          <Table.ColumnHeader>Owner 2</Table.ColumnHeader>
          <Table.ColumnHeader>ISO</Table.ColumnHeader>
          <Table.ColumnHeader>Links</Table.ColumnHeader>
          <Table.ColumnHeader>Underwriter</Table.ColumnHeader>
          <Table.ColumnHeader>FICO</Table.ColumnHeader>
          <Table.ColumnHeader>Bank Acc. #</Table.ColumnHeader>
          <Table.ColumnHeader>Rev. Override</Table.ColumnHeader>
          <Table.ColumnHeader># of Positions</Table.ColumnHeader>
          <Table.ColumnHeader>% Gross</Table.ColumnHeader>
          <Table.ColumnHeader>% Total Gross</Table.ColumnHeader>
          <Table.ColumnHeader>Max Approval $</Table.ColumnHeader>
          <Table.ColumnHeader>Term</Table.ColumnHeader>
          <Table.ColumnHeader>Program</Table.ColumnHeader>
        </Table.Head>
        <Table.Body>
          {currentSubmission && (
            <Table.Row>
              <Table.Cell backgroundColor="green-200">
                {`${currentSubmission.owner1FullName} (Current Submission)`}
              </Table.Cell>
              <Table.Cell backgroundColor="green-200">
                {currentSubmission.owner2FullName}
              </Table.Cell>
              <Table.Cell backgroundColor="green-200">
                {isos?.find((s) => s.uuid === currentSubmission.isoUuid)?.name}
              </Table.Cell>
              <Table.Cell backgroundColor="green-200">
                <Flex gap={2} flexDirection={'row'}>
                  {currentSubmission.dateAppReceived &&
                    formatDateTimeString(currentSubmission.dateAppReceived)}
                  <Link
                    newTab
                    href={
                      new URL(
                        `/admin/prospective_merchants/${currentSubmission.accountUuid}/sheets/${currentSubmission.submissionUuid}`,
                        BANK_BASE_URL()
                      )
                    }
                  >
                    BA
                  </Link>
                </Flex>
              </Table.Cell>
              <Table.Cell backgroundColor="green-200">
                {currentSubmission.underwriter}
              </Table.Cell>
              <Table.Cell backgroundColor="green-200">
                {ficoForCurrentSubmissionOwner}
              </Table.Cell>
              <Table.Cell backgroundColor="green-200">
                {additionalDataForCurrentSubmission?.bankAccountNumbers}
              </Table.Cell>
              <Table.Cell backgroundColor="green-200">
                {additionalDataForCurrentSubmission?.revenueOverride}
              </Table.Cell>
              <Table.Cell backgroundColor="green-200">
                {additionalDataForCurrentSubmission?.numberPositions}
              </Table.Cell>
              <Table.Cell backgroundColor="green-200">
                {additionalDataForCurrentSubmission?.grossPositions}
              </Table.Cell>
              <Table.Cell backgroundColor="green-200">
                {additionalDataForCurrentSubmission?.totalGrossPercent}
              </Table.Cell>
              <Table.Cell backgroundColor="green-200">
                {additionalDataForCurrentSubmission?.maxApproval}
              </Table.Cell>
              <Table.Cell backgroundColor="green-200">
                {additionalDataForCurrentSubmission?.termLength}
              </Table.Cell>
              <Table.Cell backgroundColor="green-200">
                {additionalDataForCurrentSubmission?.program}
              </Table.Cell>
            </Table.Row>
          )}
          {sortedOtherSubmissions?.map((sub) => {
            const additionalData = bankingSubmissionData?.find(
              (s) => s.submissionUuid === sub.submissionUuid
            );
            const ficoForOwner =
              sub.ownersUuid &&
              ficoScores?.find(
                (f) =>
                  f.ownerUuid === sub.ownersUuid[0] &&
                  f.submissionUuid === sub.submissionUuid
              )?.fico;

            return (
              <Table.Row key={sub.submissionUuid}>
                <Table.Cell>{sub.owner1FullName}</Table.Cell>
                <Table.Cell>{sub.owner2FullName}</Table.Cell>
                <Table.Cell>
                  {isos?.find((s) => s.uuid === sub.isoUuid)?.name}
                </Table.Cell>
                <Table.Cell>
                  <Flex gap={2} flexDirection={'row'} alignItems={'center'}>
                    {sub.dateAppReceived &&
                      formatDateTimeString(sub.dateAppReceived)}
                    <Link
                      newTab
                      href={
                        new URL(
                          `/admin/applications/${sub.submissionUuid}`,
                          UNDERWRITING_BASE_URL()
                        )
                      }
                    >
                      UA
                    </Link>
                    <Link
                      newTab
                      href={
                        new URL(
                          `/admin/prospective_merchants/${sub.accountUuid}/sheets/${sub.submissionUuid}`,
                          BANK_BASE_URL()
                        )
                      }
                    >
                      BA
                    </Link>
                    {sub.isRenewal && <Tag>Renewal</Tag>}
                  </Flex>
                </Table.Cell>
                <Table.Cell>{sub.underwriter}</Table.Cell>
                <Table.Cell>{ficoForOwner}</Table.Cell>
                <Table.Cell>{additionalData?.bankAccountNumbers}</Table.Cell>
                <Table.Cell>{additionalData?.revenueOverride}</Table.Cell>
                <Table.Cell>{additionalData?.numberPositions}</Table.Cell>
                <Table.Cell>{additionalData?.grossPositions}</Table.Cell>
                <Table.Cell>{additionalData?.totalGrossPercent}</Table.Cell>
                <Table.Cell>{additionalData?.maxApproval}</Table.Cell>
                <Table.Cell>{additionalData?.termLength}</Table.Cell>
                <Table.Cell>{additionalData?.program}</Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    </>
  );
};

const AdditionalDataTable = ({
  submissionData,
  currentSubmissionUuid,
  isos,
}: Partial<AdditionalDataModalProps>): JSX.Element => {
  const submissionDataWithSubmissionUuid = useMemo(
    () => submissionData?.map((s) => s.submissionUuid),
    [submissionData]
  );

  const {
    bankingSubmissionData,
    loading: bankingSubmissionDataLoading,
    error: bankingSubmissionDataError,
  } = useBankingSubmissionData(submissionDataWithSubmissionUuid);

  const isCurrentSubmission = (sub: CustomerSubmission): boolean =>
    currentSubmissionUuid === sub.submissionUuid;

  const currentSubmission = submissionData?.find((sub) =>
    isCurrentSubmission(sub)
  );

  const sortedOtherSubmissions = submissionData
    ?.filter((sub) => !isCurrentSubmission(sub))
    .sort((a, b) =>
      (a.dateAppReceived ?? 0) >= (b.dateAppReceived ?? 0) ? -1 : 1
    );

  const additionalDataForCurrentSubmission = bankingSubmissionData?.find(
    (s) => s.submissionUuid === currentSubmission?.submissionUuid
  );

  if (bankingSubmissionDataLoading) {
    return <Loading text="Fetching data..." />;
  }

  return (
    <>
      {bankingSubmissionDataError && (
        <Banner dismissable={false} variant="error">
          {bankingSubmissionDataError.message}
        </Banner>
      )}
      <Table caption="Additional Submissions">
        <Table.Head>
          <Table.ColumnHeader>Owner 1</Table.ColumnHeader>
          <Table.ColumnHeader>Owner 2</Table.ColumnHeader>
          <Table.ColumnHeader>ISO</Table.ColumnHeader>
          <Table.ColumnHeader>Links</Table.ColumnHeader>
          <Table.ColumnHeader>Underwriter</Table.ColumnHeader>
          <Table.ColumnHeader>FICO</Table.ColumnHeader>
          <Table.ColumnHeader>Bank Acc. #</Table.ColumnHeader>
          <Table.ColumnHeader>Rev. Override</Table.ColumnHeader>
          <Table.ColumnHeader># of Positions</Table.ColumnHeader>
          <Table.ColumnHeader>% Gross</Table.ColumnHeader>
          <Table.ColumnHeader>% Total Gross</Table.ColumnHeader>
          <Table.ColumnHeader>Max Approval $</Table.ColumnHeader>
          <Table.ColumnHeader>Term</Table.ColumnHeader>
          <Table.ColumnHeader>Program</Table.ColumnHeader>
        </Table.Head>
        <Table.Body>
          {currentSubmission && (
            <AdditionalDataModalRow
              submission={currentSubmission}
              isCurrentSubmission={true}
              submissionAdditionalData={additionalDataForCurrentSubmission}
              isos={isos}
              ownerUuid={currentSubmission.ownersUuid[0]}
            />
          )}

          {sortedOtherSubmissions?.map((sub) => {
            const additionalData = bankingSubmissionData?.find(
              (s) => s.submissionUuid === sub.submissionUuid
            );

            return (
              <AdditionalDataModalRow
                key={sub.submissionUuid}
                submission={sub}
                isCurrentSubmission={false}
                submissionAdditionalData={additionalData}
                isos={isos}
                ownerUuid={sub.ownersUuid[0]}
              />
            );
          })}
        </Table.Body>
      </Table>
    </>
  );
};

export const AdditionalDataModal = ({
  submissionData,
  isos,
  currentSubmissionUuid,
}: AdditionalDataModalProps): JSX.Element => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const handleOpenChange = (): void => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <Modal
      trigger={
        <IconButton label="View Additional Data" icon="magnifying-glass" />
      }
      isOpen={isModalOpen}
      title="Additional Data"
      onOpenChange={handleOpenChange}
      size="auto"
      body={
        featureFlags.experian_consumer_3pi ? (
          <AdditionalDataTable
            submissionData={submissionData}
            isos={isos}
            currentSubmissionUuid={currentSubmissionUuid}
          />
        ) : (
          <AdditionalDataTableOld
            submissionData={submissionData}
            isos={isos}
            currentSubmissionUuid={currentSubmissionUuid}
          />
        )
      }
    />
  );
};
