import React, { useCallback, useEffect, useState } from 'react';
import {
  Address,
  Banner,
  Button,
  Card,
  Currency,
  Divider,
  Flex,
  formatDateTimeString,
  Icon,
  Link,
  Loading,
  SelectableOption,
  Table,
  Tag,
  Tooltip,
} from '@forward-financing/fast-forward';
import { defaultTo, startCase } from 'lodash';
import { BANK_BASE_URL, UNDERWRITING_BASE_URL } from 'constants/globals';
import { featureFlags } from 'helpers/featureFlags';
import { userFullName, isUnderwriter } from 'helpers/utils';
import { useUserContext } from 'contexts/UserContext';
import { WebPresence } from 'components/SubmissionEditing/SubmissionEditing.types';
import { InternalLink } from 'components/shared/InternalLink';
import { addressToString } from '../../../helpers/addressUtils';
import { useDataMerchReport } from '../DataMerchReport/dataMerchHooks';
import { EstablishedDatesDisplay } from './EstablishedDatesDisplay';
import { ApplicationSnapshotOwner } from './ApplicationSnapshotOwner';
import { buildGoogleSearchUrl, howLongAgo } from './applicationSnapshotUtils';
import {
  Customer,
  CustomerWebPresence,
  EstablishedDates,
  IndependentSalesOrganization,
  Owner,
  ApplicationSnapshotSubmission,
} from './applicationSnapshot.types';
import { IndustryRiskModal } from './IndustryRiskModal';
import {
  useGetUser,
  useGroupedSubmissionLogs,
  UpdateSubmissionBody,
  useLazyGetSubmission,
  useOwnerCreditData,
  useRelativity6IndustryPrediction,
  useSubmissionStageHistory,
  useUpdateSubmission,
  useGetUsersByRole,
} from './applicationSnapshotFetchHooks';
import { UnderwriterAssignmentHistoryModal } from './AssignmentHistoryModal/UnderwriterAssignmentHistoryModal';
import { CreditCommitteeAssignmentHistoryModal } from './AssignmentHistoryModal/CreditCommitteeAssignmentHistoryModal';
import { AssignOverwriteConfirmationModal } from './AssignOverwriteConfirmationModal';
import { WebPresenceModal } from './WebPresenceModal';
import { UnassignConfirmationModal } from './UnassignConfirmationModal';

export interface ApplicationSnapshotProps {
  initialSubmission: ApplicationSnapshotSubmission;
  customer?: Customer;
  owners?: Owner[];
  webPresence?: CustomerWebPresence;
  establishedDates?: EstablishedDates;
  dataMerchLink?: string;
  iso?: IndependentSalesOrganization;
  errorMessages: string[];
  loading: boolean;
}

type AutomationStatusDisplayData = {
  color: 'red-100' | 'green-100' | undefined;
  text: string;
};

const transformDealCategory = (input: string | undefined): string => {
  if (!input) {
    return 'N/A';
  }

  return input
    .split('_')
    .map((word) => {
      // Acronyms that should be fully uppercase
      if (word === 'pif' || word === 'iso') {
        return word.toUpperCase();
      } else {
        return startCase(word);
      }
    })
    .join(' ');
};

const automationStatusDisplayLookup = (
  status?: boolean
): AutomationStatusDisplayData => {
  if (status === undefined) {
    return { color: undefined, text: 'N/A' };
  }
  return status === true
    ? { color: 'green-100', text: 'Completed' }
    : { color: 'red-100', text: 'Failed' };
};

const getSaferSearchUrl = (customerName: string): URL => {
  const url = new URL('/keywordx.asp', 'https://safer.fmcsa.dot.gov');
  url.searchParams.append('searchstring', `*${customerName}*`);
  url.searchParams.append('SEARCHTYPE', '');
  return url;
};

const hasSufficientValidWebPresenceUrls = (
  webPresences: WebPresence | undefined
): boolean => {
  if (webPresences) {
    const count = Object.values(webPresences).filter(Boolean).length;

    return count >= 3;
  } else {
    return false;
  }
};

export const ApplicationSnapshot = (
  props: ApplicationSnapshotProps
): JSX.Element => {
  const {
    errorMessages,
    loading,
    initialSubmission,
    customer,
    iso,
    webPresence,
    establishedDates,
    dataMerchLink,
    owners,
  } = props;

  const DECISION_OR_CREDIT_COMMITTEE =
    featureFlags.use_decision_committee_naming
      ? 'Decision Committee'
      : 'Credit Committee';

  const [
    fetchLatestSubmission,
    { data: latestSubmissionData, error: submissionError },
  ] = useLazyGetSubmission();

  const [updateSubmission, { error: updateSubmissionError }] =
    useUpdateSubmission();

  const submission = latestSubmissionData || initialSubmission;

  const { data: ficoScores, error: ficoScoreError } = useOwnerCreditData(
    submission?.uuid
  );

  const { data: submissionLogData, error: submissionLogError } =
    useGroupedSubmissionLogs(submission?.uuid);

  const {
    data: exceptionRequestUnderwriterUser,
    error: exceptionRequestUnderwriterUserError,
  } = useGetUser(submission?.underwriterExceptionRequestId);

  const { data: dataMerchReport } = useDataMerchReport(submission?.uuid);

  const exceptionRequestUnderwriter = exceptionRequestUnderwriterUser
    ? userFullName(
        exceptionRequestUnderwriterUser.firstName,
        exceptionRequestUnderwriterUser.lastName
      )
    : 'N/A';

  const {
    data: finalUnderwriterSignOffUser,
    error: finalUnderwriterSignOffUserError,
  } = useGetUser(submission?.underwriterSignOffId);

  const { data: underwriters, error: underwritersError } =
    useGetUsersByRole('underwriter');

  const finalUnderwriterSignOff = finalUnderwriterSignOffUser
    ? userFullName(
        finalUnderwriterSignOffUser.firstName,
        finalUnderwriterSignOffUser.lastName
      )
    : 'N/A';

  const [
    pullRelativity6IndustryPrediction,
    {
      data: industryPrediction,
      error: industryPredictionError,
      loading: industryPredictionLoading,
    },
  ] = useRelativity6IndustryPrediction();

  const user = useUserContext();

  // We only want to run this once, realistically.
  // If it fails, no sense in continuously retrying.
  useEffect(() => {
    if (
      featureFlags.show_relativity_6_industry_prediction &&
      !(
        industryPrediction ||
        industryPredictionError ||
        industryPredictionLoading
      ) &&
      customer?.address &&
      customer?.doingBusinessAsName
    ) {
      void pullRelativity6IndustryPrediction({
        businessName: customer.doingBusinessAsName,
        street: [customer.address.street1, customer.address.street2]
          .filter(Boolean)
          .join(' '),
        state: customer.address.state,
        country: 'USA',
        ...(submission?.uuid && { referenceIds: [submission.uuid] }),
      });
    }
  }, [
    customer,
    industryPrediction,
    industryPredictionError,
    industryPredictionLoading,
    pullRelativity6IndustryPrediction,
    submission?.uuid,
  ]);

  const naicsConfidenceScore = industryPrediction?.confidenceScore
    ? `${Math.round(industryPrediction.confidenceScore * 100)}%`
    : undefined;

  const { data: stageHistory } = useSubmissionStageHistory(submission?.uuid);

  const [mostRecentWaitingOnIsoDate, setMostRecentWaitingOnIsoDate] = useState<
    string | null
  >(null);
  const [mostRecentWaitingOnIsoNote, setMostRecentWaitingOnIsoNote] = useState<
    string | null
  >(null);

  // The two state values below are similar, but have an important distinction.
  // assigningUnderwriterInProgress is used to track the entire assignment process
  // across multiple fetches, so we can disable the button and prevent button spamming.
  const [assigningUnderwriterInProgress, setAssigningUnderwriterInProgress] =
    useState<boolean>(false);

  // underwriterAssignRequested is used to indicate only the start of the assignment process
  // and is reset to false before the assignment is actually made to prevent additional
  // assignment calls in parallel.
  const [underwriterAssignRequested, setUnderwriterAssignRequested] =
    useState<boolean>(false);

  const [isReassignConfirmationModalOpen, setIsReassignConfirmationModalOpen] =
    useState<boolean>(false);

  useEffect(() => {
    const getMostRecentWaitingOnIsoDate = (): void => {
      if (stageHistory && stageHistory.length > 0) {
        const filteredWaitingOnIsoStageHistory = [...stageHistory].filter(
          (stage) => stage.subStage === 'Waiting on ISO'
        );

        if (filteredWaitingOnIsoStageHistory.length > 0) {
          const mostRecentWaitingOnIso = filteredWaitingOnIsoStageHistory.sort(
            (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
          )[0];

          setMostRecentWaitingOnIsoDate(
            formatDateTimeString(mostRecentWaitingOnIso.date)
          );

          setMostRecentWaitingOnIsoNote(mostRecentWaitingOnIso.notes);
        } else {
          setMostRecentWaitingOnIsoDate('N/A');
          setMostRecentWaitingOnIsoNote(null);
        }
      } else {
        setMostRecentWaitingOnIsoDate('N/A');
        setMostRecentWaitingOnIsoNote(null);
      }
    };

    void getMostRecentWaitingOnIsoDate();
  }, [stageHistory]);

  const isSubmissionPrimeDeal = (): string => {
    return submission?.primeDeal ? 'Yes' : 'No';
  };

  const isCurrentUserAssignedUnderwriting = !!(
    submission?.subStage === 'In Underwriting' &&
    submission?.underwriterId === user.id
  );

  const isCurrentUserAssignedCreditCommittee = !!(
    submission?.subStage === 'Credit Committee' &&
    submission?.creditCommittee ===
      userFullName(user.first_name, user.last_name)
  );

  const isCurrentUserAssignedException = !!(
    (submission?.subStage === 'Underwriting Exception Request' ||
      submission?.subStage === 'In Exception Review') &&
    submission?.underwriterExceptionRequestId === user.id
  );

  const isCurrentUserAssignedFinalSignOff = !!(
    submission?.subStage === 'Final UW Signoff' &&
    submission?.underwriterSignOffId === user.id
  );

  const isSubmissionAssignedToCurrentUser =
    isCurrentUserAssignedUnderwriting ||
    isCurrentUserAssignedCreditCommittee ||
    isCurrentUserAssignedException ||
    isCurrentUserAssignedFinalSignOff;

  const shouldShowUWAssignToMeButton =
    featureFlags.move_assignment_out_of_live_pipeline &&
    isUnderwriter(user) &&
    !isSubmissionAssignedToCurrentUser &&
    [
      'In Underwriting',
      'Credit Committee',
      'Underwriting Exception Request',
      'In Exception Review',
      'Final UW Signoff',
    ].includes(submission?.subStage || '');

  /**
   * Event handler for when the Assign to Me button is clicked.
   * This function will update state to indicate that the underwriter
   * assignment was requested and the process is underway, and will
   * then fire a request to fetch the latest submission data,
   * which will trigger an effect.
   */
  const handleRequestUnderwriterAssignment = (): void => {
    if (submission) {
      setAssigningUnderwriterInProgress(true);
      setUnderwriterAssignRequested(true);

      void fetchLatestSubmission({ submissionUuid: submission.uuid });
    }
  };

  /**
   * This function handles updating the Submission to assign the underwriter
   * to the correct field given the Submission's stage and sub-stage.
   *
   * On success, the Banking App url for the Submission will open in a new tab.
   */
  const assignUnderwriter = useCallback(
    async (submissionToAssign: ApplicationSnapshotSubmission) => {
      let body: UpdateSubmissionBody | undefined = undefined;

      switch (submissionToAssign.subStage) {
        case 'In Underwriting':
          body = {
            underwriterId: user.id,
          };
          break;
        case 'Credit Committee':
          body = {
            creditCommittee: `${user.first_name} ${user.last_name}`,
          };
          break;
        case 'Underwriting Exception Request':
          body = {
            underwriterExceptionRequestId: user.id,
            subStage: 'In Exception Review',
          };
          break;
        case 'In Exception Review':
          body = {
            underwriterExceptionRequestId: user.id,
          };
          break;
        case 'Final UW Signoff':
          body = {
            underwriterSignOffId: user.id,
          };
          break;
      }

      if (body) {
        const { success } = await updateSubmission({
          submissionUuid: submissionToAssign.uuid,
          body,
        });

        if (success) {
          // Refetch submission to display latest data in the UI
          void fetchLatestSubmission({
            submissionUuid: submissionToAssign.uuid,
          });

          // Open Banking App url in new tab
          window.open(
            new URL(
              `/admin/prospective_merchants/${submissionToAssign.customerUuid}/sheets/${submissionToAssign.uuid}`,
              BANK_BASE_URL()
            ).toString(),
            '_blank'
          );
        }
      }
      setAssigningUnderwriterInProgress(false);
      setIsReassignConfirmationModalOpen(false);
    },
    [updateSubmission, fetchLatestSubmission, user]
  );

  const handleCloseAssignmentConfirmationModal = (): void => {
    setIsReassignConfirmationModalOpen(false);
  };

  const handleInlineAssignment = async (
    body: UpdateSubmissionBody
  ): Promise<void> => {
    const { success } = await updateSubmission({
      submissionUuid: submission.uuid,
      body,
    });

    if (success) {
      // Refetch submission to display latest data in the UI
      void fetchLatestSubmission({
        submissionUuid: submission.uuid,
      });
    }
  };

  /**
   * This useEffect will respond to new Submission data being fetched.
   * If the Underwriter assignment state is active, it will check the
   * latest Submission assignment data to determine the path forward
   * for assignment.
   *
   * If the Submission is already assigned to the relevant field, a
   * modal will open prompting a confirmation. If not, the assignment
   * will continue with no user action needed.
   */
  useEffect(() => {
    const isAlreadyAssignedUnderwriting = !!(
      latestSubmissionData?.subStage === 'In Underwriting' &&
      latestSubmissionData?.underwriterId
    );

    const isAlreadyAssignedCreditCommittee = !!(
      latestSubmissionData?.subStage === 'Credit Committee' &&
      latestSubmissionData?.creditCommittee
    );

    const isAlreadyAssignedException = !!(
      (latestSubmissionData?.subStage === 'Underwriting Exception Request' ||
        latestSubmissionData?.subStage === 'In Exception Review') &&
      latestSubmissionData?.underwriterExceptionRequestId
    );

    const isAlreadyAssignedFinalSignOff = !!(
      latestSubmissionData?.subStage === 'Final UW Signoff' &&
      latestSubmissionData?.underwriterSignOffId
    );

    const isSubmissionAlreadyAssigned =
      isAlreadyAssignedUnderwriting ||
      isAlreadyAssignedCreditCommittee ||
      isAlreadyAssignedException ||
      isAlreadyAssignedFinalSignOff;

    if (latestSubmissionData && underwriterAssignRequested) {
      setUnderwriterAssignRequested(false);

      if (isSubmissionAlreadyAssigned) {
        setAssigningUnderwriterInProgress(false);
        setIsReassignConfirmationModalOpen(true);
      } else {
        void assignUnderwriter(latestSubmissionData);
      }
    }
  }, [
    latestSubmissionData,
    underwriterAssignRequested,
    assignUnderwriter,
    user,
  ]);

  const renderSubStage = (subStage?: string): string | undefined => {
    if (subStage === 'Credit Committee') {
      return DECISION_OR_CREDIT_COMMITTEE;
    }

    return subStage;
  };

  const underwritersOptionsId = (): SelectableOption[] => {
    return (
      underwriters
        ?.map((underwriter) => {
          return {
            text: `${underwriter.firstName} ${underwriter.lastName}`,
            value: `${underwriter.id}`,
          };
        })
        .sort((a, b) => a.text.localeCompare(b.text)) || []
    );
  };

  const underwritersOptionsName = (): SelectableOption[] => {
    return (
      underwriters
        ?.map((underwriter) => {
          return {
            text: `${underwriter.firstName} ${underwriter.lastName}`,
            value: `${underwriter.firstName} ${underwriter.lastName}`,
          };
        })
        .sort((a, b) => a.text.localeCompare(b.text)) || []
    );
  };

  const hasError =
    errorMessages.length > 0 ||
    (ficoScoreError && !featureFlags.experian_consumer_3pi) ||
    industryPredictionError ||
    submissionError ||
    updateSubmissionError ||
    (submissionLogError && featureFlags.move_assignment_out_of_live_pipeline) ||
    underwritersError;

  const dataMerchLinkComponent = (): JSX.Element => {
    if (featureFlags.use_new_data_merch_page) {
      return dataMerchReport && dataMerchReport.length > 0 ? (
        <Table.Cell backgroundColor="red-100">
          <InternalLink
            target="_blank"
            to={`/dashboard/submission/${submission.uuid}/data-merch`}
          >
            YES
          </InternalLink>
        </Table.Cell>
      ) : (
        <Table.Cell>NO</Table.Cell>
      );
    } else {
      return dataMerchLink ? (
        <Table.Cell backgroundColor="red-100">
          <Link newTab href={new URL(dataMerchLink, UNDERWRITING_BASE_URL())}>
            YES
          </Link>
        </Table.Cell>
      ) : (
        <Table.Cell>NO</Table.Cell>
      );
    }
  };

  return (
    <Card
      title="Application Snapshot"
      actions={[
        <IndustryRiskModal key="industry_risk_modal" />,
        shouldShowUWAssignToMeButton && (
          <Button
            onClick={() => handleRequestUnderwriterAssignment()}
            key="uw_assign_to_me_button"
            disabled={assigningUnderwriterInProgress}
          >
            Assign to Me
          </Button>
        ),
      ]}
    >
      <AssignOverwriteConfirmationModal
        submission={submission}
        isOpen={isReassignConfirmationModalOpen}
        onConfirm={() => void assignUnderwriter(submission)}
        onClose={() => handleCloseAssignmentConfirmationModal()}
      />
      {hasError && (
        <Flex flexDirection="column" gap={1} mb={1}>
          {errorMessages.map((errorMessage) => (
            <Banner key={errorMessage} dismissable={false}>
              {errorMessage}
            </Banner>
          ))}

          {ficoScoreError && !featureFlags.experian_consumer_3pi && (
            <Banner key={ficoScoreError.message} dismissable={false}>
              {ficoScoreError.message}
            </Banner>
          )}

          {industryPredictionError && (
            <Banner key={industryPredictionError.message} dismissable={false}>
              {industryPredictionError.message}
            </Banner>
          )}

          {submissionError && (
            <Banner key={submissionError.message} dismissable={false}>
              {submissionError.message}
            </Banner>
          )}

          {updateSubmissionError && (
            <Banner key={updateSubmissionError.message} dismissable={false}>
              {updateSubmissionError.message}
            </Banner>
          )}

          {submissionLogError && (
            <Banner key={submissionLogError.message} dismissable={false}>
              {submissionLogError.message}
            </Banner>
          )}

          {underwritersError && (
            <Banner key={underwritersError.message} dismissable={false}>
              {underwritersError.message}
            </Banner>
          )}
        </Flex>
      )}
      <>
        {loading || industryPredictionLoading ? (
          <Loading />
        ) : (
          <>
            {submission && (
              <Table caption="Application Snapshot">
                <Table.Body>
                  <Table.Row>
                    <Table.RowHeader>Current Stage / Sub-Stage</Table.RowHeader>
                    <Table.Cell>
                      {submission.stageName} /{' '}
                      {renderSubStage(submission.subStage)}
                    </Table.Cell>
                  </Table.Row>
                  {featureFlags.show_deal_category && (
                    <Table.Row>
                      <Table.RowHeader>Deal Category</Table.RowHeader>
                      <Table.Cell
                        backgroundColor={
                          submission.category === 'pif_new_iso'
                            ? 'info'
                            : undefined
                        }
                        aria-label={transformDealCategory(submission.category)}
                      >
                        {transformDealCategory(submission.category)}
                      </Table.Cell>
                    </Table.Row>
                  )}
                  <Table.Row>
                    <Table.RowHeader>Submission Owner</Table.RowHeader>
                    <Table.Cell>{submission.salesOwnerName}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.RowHeader>Sub_On Analyst</Table.RowHeader>
                    <Table.Cell>{submission.prequalAnalystName}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.RowHeader>Prequal Analyst</Table.RowHeader>
                    <Table.Cell>{submission.decisionAnalystName}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.RowHeader>Processor</Table.RowHeader>
                    <Table.Cell>{submission.processor}</Table.Cell>
                  </Table.Row>

                  <Table.Row>
                    <Table.RowHeader>
                      <Flex justifyContent="space-between" alignItems="center">
                        Underwriter
                        <Flex>
                          {submission.underwriterId &&
                            featureFlags.inline_editing_underwriter_assignment && (
                              <UnassignConfirmationModal
                                onConfirm={() => {
                                  void handleInlineAssignment({
                                    underwriterId: null,
                                  });
                                }}
                              />
                            )}
                          {featureFlags.move_assignment_out_of_live_pipeline ? (
                            <UnderwriterAssignmentHistoryModal
                              title="Underwriter"
                              historyData={
                                submissionLogData?.underwriterAssigned
                              }
                            />
                          ) : undefined}
                        </Flex>
                      </Flex>
                    </Table.RowHeader>
                    {featureFlags.inline_editing_underwriter_assignment &&
                    submission.subStage === 'In Underwriting' ? (
                      <Table.EditableSelectCell
                        id={`${submission.uuid}-edit-underwriter`}
                        name={'Underwriter'}
                        label="Assign Underwriter"
                        value={
                          submission.underwriterId
                            ? `${submission.underwriterId}`
                            : ''
                        }
                        onSave={(_id, _name, newValue) => {
                          void handleInlineAssignment({
                            underwriterId: newValue ? +newValue : null,
                          });
                        }}
                        options={underwritersOptionsId()}
                      />
                    ) : (
                      <Table.Cell>
                        <Flex alignItems={'center'} gap={2}>
                          {defaultTo(submission.underwriter, 'N/A')}
                        </Flex>
                      </Table.Cell>
                    )}
                  </Table.Row>

                  <Table.Row>
                    <Table.RowHeader>
                      <Flex justifyContent="space-between" alignItems="center">
                        {DECISION_OR_CREDIT_COMMITTEE}
                        <Flex>
                          {submission.creditCommittee &&
                            featureFlags.inline_editing_underwriter_assignment && (
                              <UnassignConfirmationModal
                                onConfirm={() => {
                                  void handleInlineAssignment({
                                    creditCommittee: '',
                                  });
                                }}
                              />
                            )}
                          {featureFlags.move_assignment_out_of_live_pipeline && (
                            <CreditCommitteeAssignmentHistoryModal
                              historyData={
                                submissionLogData?.creditCommitteeStart
                              }
                            />
                          )}
                        </Flex>
                      </Flex>
                    </Table.RowHeader>
                    {featureFlags.inline_editing_underwriter_assignment &&
                    submission.subStage === 'Credit Committee' ? (
                      <Table.EditableSelectCell
                        id={`${submission.uuid}-edit-decision-committee`}
                        name={'Decision Committee'}
                        label="Assign Decision Committee"
                        value={submission.creditCommittee || ''}
                        onSave={(_id, _name, newValue) => {
                          void handleInlineAssignment({
                            creditCommittee: newValue,
                          });
                        }}
                        options={underwritersOptionsName()}
                      />
                    ) : (
                      <Table.Cell>
                        <Flex alignItems={'center'} gap={2}>
                          {defaultTo(submission.creditCommittee, 'N/A')}
                        </Flex>
                      </Table.Cell>
                    )}
                  </Table.Row>

                  {featureFlags.move_assignment_out_of_live_pipeline && (
                    <Table.Row>
                      <Table.RowHeader>
                        <Flex
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          Exception Request
                          <Flex>
                            {submission.underwriterExceptionRequestId &&
                              featureFlags.inline_editing_underwriter_assignment && (
                                <UnassignConfirmationModal
                                  onConfirm={() => {
                                    void handleInlineAssignment({
                                      underwriterExceptionRequestId: null,
                                    });
                                  }}
                                />
                              )}
                            <UnderwriterAssignmentHistoryModal
                              title="Exception Request"
                              historyData={
                                submissionLogData?.underwritingExceptionRequestStart
                              }
                            />
                          </Flex>
                        </Flex>
                      </Table.RowHeader>
                      {featureFlags.inline_editing_underwriter_assignment &&
                      (submission.subStage ===
                        'Underwriting Exception Request' ||
                        submission.subStage === 'In Exception Review') ? (
                        <Table.EditableSelectCell
                          id={`${submission.uuid}-edit-exception-request`}
                          name={'Exception Request'}
                          label="Assign Exception Request"
                          value={
                            submission.underwriterExceptionRequestId
                              ? `${submission.underwriterExceptionRequestId}`
                              : ''
                          }
                          onSave={(_id, _name, newValue) => {
                            void handleInlineAssignment({
                              subStage: 'In Exception Review',
                              underwriterExceptionRequestId: newValue
                                ? +newValue
                                : null,
                            });
                          }}
                          options={underwritersOptionsId()}
                        />
                      ) : (
                        <Table.Cell>
                          <Flex alignItems={'center'} gap={2}>
                            {defaultTo(
                              exceptionRequestUnderwriterUserError?.message,
                              exceptionRequestUnderwriter
                            )}
                          </Flex>
                        </Table.Cell>
                      )}
                    </Table.Row>
                  )}

                  {featureFlags.move_assignment_out_of_live_pipeline && (
                    <Table.Row>
                      <Table.RowHeader>
                        <Flex
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          Final UW Signoff
                          <Flex>
                            {submission.underwriterSignOffId &&
                              featureFlags.inline_editing_underwriter_assignment && (
                                <UnassignConfirmationModal
                                  onConfirm={() => {
                                    void handleInlineAssignment({
                                      underwriterSignOffId: null,
                                    });
                                  }}
                                />
                              )}
                            <UnderwriterAssignmentHistoryModal
                              title="Final UW Signoff"
                              historyData={
                                submissionLogData?.underwritingFinalSignoffStart
                              }
                            />
                          </Flex>
                        </Flex>
                      </Table.RowHeader>
                      {featureFlags.inline_editing_underwriter_assignment &&
                      submission.subStage === 'Final UW Signoff' ? (
                        <Table.EditableSelectCell
                          id={`${submission.uuid}-edit-final-uw-signoff`}
                          name={'Final Underwriter Signoff'}
                          label="Assign Final Underwriter Signoff"
                          value={
                            submission.underwriterSignOffId
                              ? `${submission.underwriterSignOffId}`
                              : ''
                          }
                          onSave={(_id, _name, newValue) => {
                            void handleInlineAssignment({
                              underwriterSignOffId: newValue ? +newValue : null,
                            });
                          }}
                          options={underwritersOptionsId()}
                        />
                      ) : (
                        <Table.Cell>
                          <Flex alignItems={'center'} gap={2}>
                            {defaultTo(
                              finalUnderwriterSignOffUserError?.message,
                              finalUnderwriterSignOff
                            )}
                          </Flex>
                        </Table.Cell>
                      )}
                    </Table.Row>
                  )}
                  <Table.Row>
                    <Table.RowHeader>ISO Competing Sub</Table.RowHeader>
                    <Table.Cell>{submission.isoCompetingSubMessage}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.RowHeader>Automation Status</Table.RowHeader>
                    <Table.Cell
                      backgroundColor={
                        automationStatusDisplayLookup(
                          submission.completedAutomationSteps
                        ).color
                      }
                    >
                      {
                        automationStatusDisplayLookup(
                          submission.completedAutomationSteps
                        ).text
                      }
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.RowHeader>Prime Deal</Table.RowHeader>
                    <Table.Cell>
                      {submission?.primeDeal !== undefined &&
                        isSubmissionPrimeDeal()}
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.RowHeader>Submission Source</Table.RowHeader>
                    <Table.Cell>
                      {submission.submissionSource === 'api'
                        ? 'API'
                        : startCase(submission.submissionSource)}
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.RowHeader>
                      Application Received At Date
                    </Table.RowHeader>
                    <Table.Cell>
                      {submission.applicationCreatedDate &&
                        formatDateTimeString(submission.applicationCreatedDate)}
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.RowHeader>Legal Name</Table.RowHeader>
                    <Table.Cell>
                      {customer && (
                        <Flex alignItems="center">
                          {customer?.legalName}
                          <Divider orientation="vertical" />
                          <Link
                            newTab
                            href={buildGoogleSearchUrl(
                              customer.legalName,
                              customer.address?.city,
                              customer.address?.state
                            )}
                          >
                            Google
                          </Link>
                        </Flex>
                      )}
                      {submission.hasPreviouslyFundedCustomer && (
                        <Tag bgColor="caution">Previously Funded Customer</Tag>
                      )}
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.RowHeader>DBA Name</Table.RowHeader>
                    <Table.Cell>
                      {customer && (
                        <Flex alignItems="center">
                          {customer?.doingBusinessAsName}
                          <Divider orientation="vertical" />
                          <Link
                            newTab
                            href={buildGoogleSearchUrl(
                              customer.doingBusinessAsName,
                              customer.address?.city,
                              customer.address?.state
                            )}
                          >
                            Google
                          </Link>
                        </Flex>
                      )}
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.RowHeader>Business Address</Table.RowHeader>
                    <Table.Cell>
                      {customer?.address && (
                        <Link
                          newTab
                          href={
                            new URL(
                              `/maps/place/${addressToString(
                                customer.address
                              ).replace(/\s/g, '+')}`,
                              'https://www.google.com'
                            )
                          }
                        >
                          <Address
                            streetAddress={[
                              customer.address.street1,
                              customer.address.street2,
                            ]}
                            city={customer.address.city}
                            state={customer.address.state}
                            zipCode={customer.address.zip}
                            isSingleLine
                          />
                        </Link>
                      )}
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.RowHeader>Broker</Table.RowHeader>
                    <Table.Cell>
                      <Flex gap={2} flexDirection={'row'}>
                        {iso?.name}
                        {customer?.isoProductId === '1633' && (
                          <Tag bgColor="primary">Embedded Marketing</Tag>
                        )}
                      </Flex>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.RowHeader>Web Presence</Table.RowHeader>
                    <Table.Cell>
                      <Flex gap={1} alignItems={'center'}>
                        {hasSufficientValidWebPresenceUrls(webPresence) ? (
                          <Tag bgColor="success">
                            <Flex gap={2} alignItems={'center'}>
                              <Icon name="check" size="lg" />3 or more links
                            </Flex>
                          </Tag>
                        ) : (
                          <Tag bgColor="danger">
                            <Flex gap={2} alignItems={'center'}>
                              <Icon name="xmark" size="lg" />
                              Less than 3 links
                            </Flex>
                          </Tag>
                        )}

                        <WebPresenceModal webPresence={webPresence} />
                      </Flex>
                    </Table.Cell>
                  </Table.Row>

                  {featureFlags.show_relativity_6_industry_prediction && (
                    <>
                      <Table.Row>
                        <Table.RowHeader>
                          NAICS Confidence Score
                        </Table.RowHeader>
                        <Table.Cell>
                          {defaultTo(naicsConfidenceScore, 'N/A')}
                        </Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.RowHeader>NAICS Industry</Table.RowHeader>
                        <Table.Cell>
                          {defaultTo(industryPrediction?.industry, 'N/A')}
                        </Table.Cell>
                      </Table.Row>
                    </>
                  )}

                  <Table.Row>
                    <Table.RowHeader>Industry</Table.RowHeader>
                    <Table.Cell>{customer?.industryName}</Table.Cell>
                  </Table.Row>
                  {customer?.industryName === 'Trucking & Transportation' && (
                    <Table.Row>
                      <Table.RowHeader>
                        <Link
                          newTab
                          href={
                            new URL(
                              'https://safer.fmcsa.dot.gov/CompanySnapshot.aspx'
                            )
                          }
                        >
                          DOT SAFER Query
                        </Link>
                      </Table.RowHeader>
                      <Table.Cell>
                        <Link
                          newTab
                          href={getSaferSearchUrl(customer.doingBusinessAsName)}
                        >
                          {customer.doingBusinessAsName}
                        </Link>
                      </Table.Cell>
                    </Table.Row>
                  )}
                  <Table.Row>
                    <Table.RowHeader>Established</Table.RowHeader>
                    <Table.Cell>
                      <EstablishedDatesDisplay
                        establishedDates={establishedDates}
                      />
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.RowHeader>Time in Business</Table.RowHeader>
                    <Table.Cell>
                      {customer?.startedOn
                        ? howLongAgo(customer.startedOn)
                        : ''}
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.RowHeader>Data Merch</Table.RowHeader>
                    {customer?.fein ? (
                      <>{dataMerchLinkComponent()}</>
                    ) : (
                      <Table.Cell>FEIN Not Provided</Table.Cell>
                    )}
                  </Table.Row>
                  {owners?.length ? (
                    <>
                      {owners?.map((owner) => {
                        const ownerDocument = ficoScores?.find(
                          (ficoScore) => ficoScore.ownerUuid === owner.uuid
                        );

                        return featureFlags.experian_consumer_3pi ? (
                          <ApplicationSnapshotOwner
                            submissionUuid={submission.uuid}
                            ownerUuid={owner.uuid}
                            ownerFullName={owner.fullName}
                          />
                        ) : (
                          <Table.Row key={owner.uuid}>
                            <Table.RowHeader>
                              {owner.fullName} FICO
                            </Table.RowHeader>
                            {ownerDocument?.fico ? (
                              <Table.Cell
                                backgroundColor={
                                  ownerDocument.fico < 500
                                    ? 'red-100'
                                    : undefined
                                }
                              >
                                {ownerDocument?.fico}
                              </Table.Cell>
                            ) : (
                              <Table.Cell backgroundColor="red-100">
                                Experian Not Pulled
                              </Table.Cell>
                            )}
                          </Table.Row>
                        );
                      })}
                    </>
                  ) : (
                    <Table.Row>
                      <Table.RowHeader>FICO</Table.RowHeader>
                      <Table.Cell backgroundColor="red-100">
                        Customer has no owners
                      </Table.Cell>
                    </Table.Row>
                  )}
                  <Table.Row>
                    <Table.RowHeader>Amount Requested</Table.RowHeader>
                    <Table.Cell>
                      {typeof submission.amountRequested === 'number' && (
                        <Currency amount={submission.amountRequested} />
                      )}
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.RowHeader>
                      Most Recent Waiting on ISO Date
                    </Table.RowHeader>
                    <Table.Cell>
                      {mostRecentWaitingOnIsoDate}
                      {mostRecentWaitingOnIsoNote && (
                        <>
                          {' '}
                          <Tooltip
                            content={mostRecentWaitingOnIsoNote}
                            position="right"
                            trigger={
                              <span>
                                <Icon
                                  name="info-circle"
                                  data-testid="waiting-on-iso-note-icon"
                                />
                              </span>
                            }
                          />
                        </>
                      )}
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            )}
          </>
        )}
      </>
    </Card>
  );
};
