import React from 'react';
import {
  Address,
  Banner,
  Loading,
  Table,
} from '@forward-financing/fast-forward';
import { howLongAgo } from '../ApplicationSnapshot/applicationSnapshotUtils';
import { useSOSFields } from './businessOverviewHooks';
import {
  SOSFieldsContactType,
  SOSFieldsOwnership,
} from './businessOverview.types';

export type SOSFieldsTableProps = {
  submissionUuid: string;
};

const parseRegisteredAgent = (registeredAgent?: {
  fullName?: string;
  title?: string;
}): string => {
  if (!registeredAgent) {
    return '';
  }

  const { title, fullName } = registeredAgent;

  return [fullName, title].filter(Boolean).join(', ');
};

const parseContactType = (contactType?: SOSFieldsContactType[]): string => {
  if (!contactType) {
    return '';
  }

  return contactType
    .map(({ type, title }) => [type, title].filter(Boolean).join(', '))
    .join(', ');
};

const parseOwnership = (ownership?: SOSFieldsOwnership[]): string => {
  if (!ownership) {
    return '';
  }

  return ownership
    .map((owner) => {
      const contactType = parseContactType(owner.contactType);

      return [owner.fullName, contactType].filter(Boolean).join(', ');
    })
    .join('\n');
};

export const SOSFieldsTable = ({
  submissionUuid,
}: SOSFieldsTableProps): JSX.Element => {
  const {
    data: sosFields,
    loading: sosFieldsLoading,
    error: sosFieldsError,
  } = useSOSFields(submissionUuid);

  if (sosFieldsLoading) {
    return <Loading />;
  }

  if (sosFieldsError) {
    return <Banner dismissable={false}>Failed to fetch SOS Fields</Banner>;
  }

  return sosFields ? (
    <Table>
      <Table.Body>
        <Table.Row>
          <Table.RowHeader>SOS Status</Table.RowHeader>
          <Table.Cell>{sosFields.status}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.RowHeader>SOS Organization Structure</Table.RowHeader>
          <Table.Cell>{sosFields.organizationStructure}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.RowHeader>SOS Legal Name</Table.RowHeader>
          <Table.Cell>{sosFields.legalName}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.RowHeader>SOS Time in Business</Table.RowHeader>
          <Table.Cell>
            {sosFields.businessStartDate &&
              sosFields.businessStartDate.year &&
              sosFields.businessStartDate.month &&
              sosFields.businessStartDate.day &&
              howLongAgo(
                `${sosFields.businessStartDate.year}-${sosFields.businessStartDate.month}-${sosFields.businessStartDate.day}`
              )}
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.RowHeader>SOS Business Address</Table.RowHeader>
          <Table.Cell>
            {sosFields.businessAddress &&
              sosFields.businessAddress.streetAddress &&
              sosFields.businessAddress.city &&
              sosFields.businessAddress.state &&
              sosFields.businessAddress.zip && (
                <Address
                  streetAddress={[sosFields.businessAddress.streetAddress]}
                  city={sosFields.businessAddress.city || ''}
                  state={sosFields.businessAddress.state || ''}
                  zipCode={sosFields.businessAddress.zip || ''}
                  isSingleLine
                />
              )}
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.RowHeader>Ownership</Table.RowHeader>
          <Table.Cell>{parseOwnership(sosFields.ownership)}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.RowHeader>Registered Agent</Table.RowHeader>
          <Table.Cell>
            {parseRegisteredAgent(sosFields.registeredAgent)}
          </Table.Cell>
        </Table.Row>
      </Table.Body>
    </Table>
  ) : (
    <>No SOS Match Found</>
  );
};
