import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Modal,
  Button,
  Flex,
  Banner,
  Subheading,
  Icon,
} from '@forward-financing/fast-forward';
import { CreateInternalRenewalButton } from './CreateInternalRenewalButton';
import {
  useCreateRenewal,
  useLazyGetSubmissionEligibility,
} from '../SubmissionUnderwriting/ApplicationSnapshot/applicationSnapshotFetchHooks';
import { UNDERWRITING_APPLICATION_URL } from 'constants/globals';

export interface CreateInternalRenewalConfirmationModalProps {
  fundedSubmissionUuid: string;
}

export const CreateInternalRenewalConfirmationModal = ({
  fundedSubmissionUuid,
}: CreateInternalRenewalConfirmationModalProps): JSX.Element => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const [
    createRenewalFunction,
    {
      renewalSubmissionUuid,
      error: createRenewalError,
      loading: createRenewalLoading,
    },
  ] = useCreateRenewal();
  const [fetchEligibility, { data }] = useLazyGetSubmissionEligibility();

  const handleOpenChange = (): void => {
    setIsModalOpen(!isModalOpen);
  };

  const redirectToNewRenewal = useCallback((renewalUrl: string): void => {
    setIsModalOpen(false);
    window.open(renewalUrl, '_blank');
  }, []);

  useEffect(() => {
    void fetchEligibility(fundedSubmissionUuid);
  }, [fetchEligibility, fundedSubmissionUuid]);

  useEffect(() => {
    if (renewalSubmissionUuid && !createRenewalLoading) {
      void fetchEligibility(renewalSubmissionUuid);
      const renewalUrl = new URL(
        renewalSubmissionUuid,
        UNDERWRITING_APPLICATION_URL()
      ).toString();
      redirectToNewRenewal(renewalUrl);
    }
  }, [
    renewalSubmissionUuid,
    createRenewalLoading,
    redirectToNewRenewal,
    fetchEligibility,
    fundedSubmissionUuid,
  ]);

  return (
    <Modal
      trigger={
        <Button variant={'cta'} disabled={!data?.eligible}>
          Create Internal Renewal
        </Button>
      }
      isOpen={isModalOpen}
      title="Create Internal Renewal Confirmation"
      onOpenChange={handleOpenChange}
      body={
        <Box>
          {createRenewalError && (
            <Banner dismissable={false}>{createRenewalError.message}</Banner>
          )}

          <Subheading variant="page">
            <Icon name="circle-exclamation" /> You are about to create an
            internal Renewal. Please confirm.
          </Subheading>

          <Flex justifyContent={'center'} flexDirection={'row'} gap={4} mt={5}>
            <CreateInternalRenewalButton
              createRenewalFunction={createRenewalFunction}
              fundedSubmissionUuid={fundedSubmissionUuid}
              createRenewalLoading={createRenewalLoading}
            />
            <Button
              startIcon="times"
              onClick={() => setIsModalOpen(false)}
              variant="secondary"
            >
              Cancel
            </Button>
          </Flex>
        </Box>
      }
    />
  );
};
