import React from 'react';
import {
  IconButton,
  Link,
  Text,
  Card,
  Box,
} from '@forward-financing/fast-forward';
import { useViewHistoryContext } from '../../contexts/ViewHistoryProvider';
import { ViewHistoryEntry } from '../../contexts/stores/ViewHistoryStore';
import { UNDERWRITING_APPLICATION_URL } from '../../constants/globals';

interface LivePipelineRecentDealsBoxProps {
  currentUserUnderwriter?: boolean;
}

const toOpportunityHref = (
  opportunityUuid: string,
  currentUserIsUnderwriter: boolean
): URL => {
  const pathPrefix = currentUserIsUnderwriter ? 'admin' : 'processing';
  return new URL(
    `${UNDERWRITING_APPLICATION_URL(pathPrefix)}${opportunityUuid}`
  );
};

const LivePipelineRecentlyViewedItemLink = ({
  entry,
  currentUserUnderwriter,
}: {
  entry: ViewHistoryEntry;
  currentUserUnderwriter: boolean;
}) => {
  return (
    <div>
      <Link
        href={toOpportunityHref(entry.objectUUID, currentUserUnderwriter)}
        newTab
      >
        {entry.title}
      </Link>
    </div>
  );
};

export function LivePipelineRecentDealsBox({
  currentUserUnderwriter = false,
}: LivePipelineRecentDealsBoxProps): JSX.Element {
  const {
    historyData: { submissions },
    refreshModel,
  } = useViewHistoryContext();

  return (
    <Box margin={2}>
      <Card
        title="Recently Viewed"
        actions={
          <IconButton
            onClick={() => refreshModel('submissions')}
            icon="arrow-rotate-right"
            label="Refresh"
          />
        }
      >
        <Box>
          {submissions.length > 0 ? (
            submissions.map((s) => (
              <LivePipelineRecentlyViewedItemLink
                key={s.objectUUID}
                entry={s}
                currentUserUnderwriter={currentUserUnderwriter}
              />
            ))
          ) : (
            <Text>No recent items</Text>
          )}
        </Box>
      </Card>
    </Box>
  );
}
