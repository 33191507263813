import React, { useEffect, useState } from 'react';
import {
  Banner,
  Box,
  Button,
  Flex,
  Form,
  FormProps,
  IconButton,
  Modal,
  TextInput,
} from '@forward-financing/fast-forward';
import { MutationResponse } from 'apiHooks/genericFetchHooks';

import { CustomerWebPresence } from './businessOverview.types';

export type EditWebPresenceModalProps = {
  webPresence: CustomerWebPresence | undefined;
  setWebPresence: (webPresence: CustomerWebPresence) => void;
  updateWebPresence: (
    webPresence: CustomerWebPresence
  ) => Promise<MutationResponse>;
  updatedWebPresenceError?: Error;
  updatedWebPresenceLoading?: boolean;
};

export const WebPresenceEditModal = ({
  webPresence,
  setWebPresence,
  updateWebPresence,
  updatedWebPresenceError,
  updatedWebPresenceLoading,
}: EditWebPresenceModalProps): JSX.Element => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const [previousWebPresence, setPreviousWebPresence] =
    useState<CustomerWebPresence>(webPresence || {});

  const [webPresenceEditing, setWebPresenceEditing] = useState(false);
  const [webPresenceError, setWebPresenceError] = useState('');
  const [webPresenceCanceled, setWebPresenceCanceled] = useState(false);

  const [updatingWebPresence, setUpdatingWebPresence] = useState(false);

  // Show errors.
  useEffect(() => {
    // 1. Don't show the error if we aren't editing (modal isn't open).
    // 2. Don't show the error if we previously canceled the changes. This one
    // is an edge case where we submit and get an error, and then cancel the
    // changes. Canceling closes the modal, and we don't want the same error to
    // appear again after re-opening.
    if (webPresenceEditing && updatedWebPresenceError && !webPresenceCanceled) {
      setWebPresenceError(updatedWebPresenceError.message);
    }
  }, [webPresenceEditing, updatedWebPresenceError, webPresenceCanceled]);

  const handleOpenChange = (): void => {
    // Reset any errors when opening the modal.
    if (!isModalOpen) {
      setWebPresenceError('');
      setWebPresenceEditing(true);
    }

    // Changes canceled.
    if (isModalOpen) {
      setWebPresence({ ...previousWebPresence });
      setWebPresenceError('');
      setWebPresenceEditing(false);
      setWebPresenceCanceled(true);
    }

    setIsModalOpen(!isModalOpen);
  };

  const handleSubmit: FormProps['onSubmit'] = async (_sourceInfo) => {
    setUpdatingWebPresence(true);
    setWebPresenceCanceled(false);

    const { success } = await updateWebPresence(webPresence || {});

    if (success) {
      setPreviousWebPresence({ ...webPresence });
      setIsModalOpen(false);
      setWebPresenceEditing(false);
      setUpdatingWebPresence(false);
    }
  };

  // Return empty element if no web presence or error.
  if (!webPresence && !webPresenceError) {
    return <></>;
  }

  return (
    <Modal
      trigger={
        <IconButton icon="pencil-alt" hiddenLabel label="Edit Web Presence" />
      }
      isOpen={isModalOpen}
      title="Edit Web Presence"
      onOpenChange={handleOpenChange}
      body={
        <Box>
          {webPresenceError && (
            <Banner dismissable={false}>{webPresenceError}</Banner>
          )}

          <Form
            accessibleName="Edit Web Presence"
            onSubmit={handleSubmit}
            allowImplicitSubmission={true}
          >
            {({ fireSubmit }) => (
              <Flex flexDirection="column" gap={2}>
                <TextInput
                  label="Facebook"
                  value={webPresence?.facebook}
                  onValueChange={(value) => {
                    setWebPresence({ ...webPresence, facebook: value });
                  }}
                />

                <TextInput
                  label="Business"
                  value={webPresence?.businessWebsite}
                  onValueChange={(value) => {
                    setWebPresence({ ...webPresence, businessWebsite: value });
                  }}
                />

                <TextInput
                  label="Instagram"
                  value={webPresence?.instagram}
                  onValueChange={(value) => {
                    setWebPresence({ ...webPresence, instagram: value });
                  }}
                />

                <TextInput
                  label="Yelp"
                  value={webPresence?.yelp}
                  onValueChange={(value) => {
                    setWebPresence({ ...webPresence, yelp: value });
                  }}
                />

                <TextInput
                  label="Other"
                  value={webPresence?.other}
                  onValueChange={(value) => {
                    setWebPresence({ ...webPresence, other: value });
                  }}
                />

                <Box marginY={2}>
                  <Button
                    onClick={fireSubmit}
                    disabled={updatingWebPresence || updatedWebPresenceLoading}
                  >
                    Submit
                  </Button>
                </Box>
              </Flex>
            )}
          </Form>
        </Box>
      }
    />
  );
};
