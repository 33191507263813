import { UseGenericQueryResponse } from 'apiHooks/genericFetchHooks';
import { useGenericFeatureQuery } from 'components/featureHooks/genericFeatureHooks';
import { useGetApiDataMerch } from 'apiHooks/underwriting/submissionFetchHooks';
import {
  DataMerchNotesResponse,
  DataMerchReportResponse,
} from 'types/api/underwriting/types';
import { DataMerch, Note } from './dataMerchTypes.types';

const toNotes = (note: DataMerchNotesResponse): Note => {
  return {
    note: note.note,
    category: note.category,
    createdAt: note.created_at,
  };
};

const toDataMerch = (response: DataMerchReportResponse): DataMerch[] => {
  if (!response.documents) {
    return [];
  }
  const merchants = response.documents.full_payload.body;
  return merchants.map((merch) => ({
    legalName: merch.merchant.legal_name,
    notes: merch.merchant.notes.map((n) => toNotes(n.note)),
  }));
};

export const useDataMerchReport = (
  submissionUuid?: string
): UseGenericQueryResponse<DataMerch[]> => {
  return useGenericFeatureQuery(
    useGetApiDataMerch,
    (data) => data && toDataMerch(data),
    submissionUuid
  );
};
