import React from 'react';
import { Partner } from '../../api/FundingClient/codecs';
import {
  Meta,
  SubmissionSearchResult,
} from '../../api/UnderwritingClient/codecs';
import { findPartner } from '../../helpers/utils';
import { Subheading, Table, Text } from '@forward-financing/fast-forward';
import { UNDERWRITING_BASE_URL } from 'constants/globals';

export interface SubmissionSearchListProps {
  submissionSearchResults: SubmissionSearchResult[];
  meta: Meta;
  partners: Partner[];
}

export const SubmissionSearchList = (
  props: SubmissionSearchListProps
): JSX.Element => {
  return (
    <>
      <Subheading variant="section">Search Results</Subheading>
      <Text>Matches Found: {props.meta.total_count}</Text>
      <Table>
        <Table.Head>
          <Table.ColumnHeader>ID</Table.ColumnHeader>
          <Table.ColumnHeader>Date Received</Table.ColumnHeader>
          <Table.ColumnHeader>DBA</Table.ColumnHeader>
          <Table.ColumnHeader>Legal Name</Table.ColumnHeader>
          <Table.ColumnHeader>Stage</Table.ColumnHeader>
          <Table.ColumnHeader>Sub-Stage</Table.ColumnHeader>
          <Table.ColumnHeader>ISO</Table.ColumnHeader>
        </Table.Head>
        <Table.Body>
          {props.submissionSearchResults.map(
            ({
              objectID,
              date_received,
              dba,
              legal_name,
              merchant_partner_id,
              stage_name,
              sub_stage,
            }) => {
              const id = objectID.split('-')[1];
              return (
                <Table.Row key={objectID}>
                  <Table.LinkCell
                    href={
                      new URL(
                        `/admin/applications/${id}`,
                        UNDERWRITING_BASE_URL()
                      )
                    }
                    newTab
                  >
                    {id}
                  </Table.LinkCell>
                  <Table.Cell>{date_received.toString()}</Table.Cell>
                  <Table.Cell>{dba}</Table.Cell>
                  <Table.Cell>{legal_name}</Table.Cell>
                  <Table.Cell>{stage_name}</Table.Cell>
                  <Table.Cell>{sub_stage}</Table.Cell>
                  <Table.Cell>
                    {findPartner(props.partners, merchant_partner_id)?.label ||
                      'NA'}
                  </Table.Cell>
                </Table.Row>
              );
            }
          )}
        </Table.Body>
      </Table>
    </>
  );
};
