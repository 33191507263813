import { IconButton, Modal } from '@forward-financing/fast-forward';
import React, { useState } from 'react';
import { CustomerWebPresence } from '../BusinessOverview/businessOverview.types';
import { WebPresenceList } from './WebPresenceList';

export interface WebPresenceModalProps {
  webPresence: CustomerWebPresence | undefined;
}

export const WebPresenceModal = (props: WebPresenceModalProps): JSX.Element => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <Modal
      body={
        props.webPresence &&
        Object.values(props.webPresence).filter(Boolean).length > 0 ? (
          <WebPresenceList webPresence={props.webPresence} />
        ) : (
          <text>No Links</text>
        )
      }
      onOpenChange={(isOpen) => {
        setIsModalOpen(isOpen);
      }}
      title="Web Presences"
      trigger={
        <IconButton icon="circle-info" label="Web Presence Links" hiddenLabel />
      }
      isOpen={isModalOpen}
    />
  );
};
