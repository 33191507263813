import { makeInternalAPIRequest } from 'api/makeInternalAPIRequest';
import { NetworkError } from 'api/networkError';
import { BANK_BASE_URL } from 'constants/globals';
import { UpdateLedgerBody } from 'types/api/banking/types';

export const updateLedger = async (
  submissionUuid: string,
  updateBody: UpdateLedgerBody
): Promise<{ success: boolean }> => {
  const url = new URL(`/api/v2/ledgers/${submissionUuid}`, BANK_BASE_URL());

  const response = await makeInternalAPIRequest<void, UpdateLedgerBody>(
    url,
    'PATCH',
    updateBody
  );

  if (!response.ok) {
    throw new NetworkError(response.status, 'Failed to update ledger');
  }

  return { success: true };
};
