import { useCallback, useEffect, useState } from 'react';
import { defaultTo } from 'lodash';
import { FFLogger } from 'api/LogClient';
import {
  usePullExperianCommercial,
  UsePullExperianCommercialArgs,
} from 'apiHooks/3pi/experianCommercialFetchHooks';
import {
  useGetApiLexisNexisCustomerSearchResults,
  useGetApiPaynetSearchResults,
  usePullCustomerLexisNexis,
  usePullPaynet,
} from 'apiHooks/underwriting/submissionFetchHooks';
import {
  useGetApiWebPresences,
  useUpdateApiCustomer,
  useUpdateApiCustomerWebPresence,
  useBulkUpdateApiCustomerWebPresence,
  useGetApiSOSFields,
} from 'apiHooks/underwriting/customerFetchHooks';
import { useGetApiIndustryRisks } from 'apiHooks/underwriting/industryFetchHooks';
import { toError } from 'helpers/errorUtils';
import {
  LexisNexisCustomerSearchResult,
  LexisNexisCustomerSearchResultsResponse,
  PaynetResponse,
  PaynetResultsResponse,
  CustomerWebPresenceResponse,
  LexisNexisCustomerSearchResultsResponseDocument,
  CustomerResponse,
  WebPresenceType,
  CustomerWebPresencePatchResponse,
  IndustryRiskProfile,
  CreateRelativity6ArtifactsRequestBody,
  IndustryNaicsResponse,
  SOSFieldsResponse,
} from 'types/api/underwriting/types';

import {
  MutationResponse,
  UseGenericQueryResponse,
  UseGenericMutationResult,
  UseGenericMutationResponse,
} from 'apiHooks/genericFetchHooks';

import { useLogError } from 'apiHooks/useLogError';
import { useGenericFeatureQuery } from 'components/featureHooks/genericFeatureHooks';
import {
  useApiPullRelativity6IndustryPrediction,
  UsePullRelativity6IndustryPredictionArgs,
} from 'apiHooks/3pi/relativity6FetchHooks';
import {
  useApiIndustryNaicsIndustryId,
  useApiCreateRelativity6ArtifactsSubmission,
} from 'apiHooks/underwriting/relativity6FetchHooks';
import { Relativity6IndustryPredictionResponseBody } from 'types/api/3pi/types';
import { LegacyReportLinks } from '../OwnerOverview/types';
import { fetchLegacyReportLinks } from '../OwnerOverview/ownerOverviewFetchUtils';
import {
  fetchCustomer,
  fetchExperianMetaData,
  fetchLexisNexisCustomerReports,
  fetchLexisNexisCustomerOverviews,
} from './businessOverviewFetchUtils';
import {
  CustomerBusinessSummary,
  CustomerLexisNexisOverview,
  CustomerLexisNexisReport,
  ExperianMetaData,
  LexisNexisCustomerSearchResultDocument,
  LexisNexisSearchResult,
  PaynetSearchResult,
  CustomerWebPresenceUpdated,
  UpdateCustomerBody,
  RiskProfile,
  CustomerWebPresence,
  PullRelativity6IndustryPredictionResponse,
  ArtifactData,
  Relativity6ArtifactsRequestBody,
  SOSFields,
} from './businessOverview.types';

export interface UseCustomerResponse {
  customer?: CustomerBusinessSummary;
  loading: boolean;
  error?: string;
}

export const useCustomer = (customerUuid?: string): UseCustomerResponse => {
  const [customer, setCustomer] = useState<
    CustomerBusinessSummary | undefined
  >();
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined
  );

  useEffect(() => {
    const getCustomer = async (): Promise<void> => {
      try {
        if (customerUuid) {
          setLoading(true);
          const data = await fetchCustomer(customerUuid);
          setCustomer(data);
        }
      } catch (e: unknown) {
        const error = toError(e);
        setErrorMessage(`Failed to fetch Customer: ${error.message}`);
        FFLogger.error(error);
      } finally {
        setLoading(false);
      }
    };

    void getCustomer();
  }, [customerUuid]);

  return {
    customer,
    loading,
    error: errorMessage,
  };
};
export interface UseCustomerWebPresenceResponse {
  webPresence?: CustomerWebPresence;
  loading: boolean;
  error?: string;
}

const toWebPresences = (
  response?: CustomerWebPresenceResponse
): CustomerWebPresence | undefined => {
  if (!response) {
    return undefined;
  }

  return {
    businessWebsite: response.business_website ?? undefined,
    facebook: response.facebook ?? undefined,
    instagram: response.instagram ?? undefined,
    yelp: response.yelp ?? undefined,
    other: response.other ?? undefined,
  };
};

export const useCustomerWebPresence = (
  accountUuid?: string
): UseGenericQueryResponse<CustomerWebPresence> => {
  return useGenericFeatureQuery(
    useGetApiWebPresences,
    toWebPresences,
    accountUuid
  );
};

export interface UseLegacyReportLinksResponse {
  legacyLinks?: LegacyReportLinks;
  loading: boolean;
  error?: string;
}

export const useLegacyReportLinks = (
  submissionUuid?: string
): UseLegacyReportLinksResponse => {
  const [legacyLinks, setLegacyLinks] = useState<LegacyReportLinks>();

  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined
  );

  useEffect(() => {
    const getLegacyLinks = async (): Promise<void> => {
      try {
        if (submissionUuid) {
          const legacyReports = await fetchLegacyReportLinks(submissionUuid);
          setLoading(true);
          setLegacyLinks(legacyReports);
        }
      } catch (e: unknown) {
        const error = toError(e);
        setErrorMessage(`Legacy Links: ${error.message}`);
        FFLogger.error(error);
      } finally {
        setLoading(false);
      }
    };

    void getLegacyLinks();
  }, [submissionUuid]);

  return {
    legacyLinks,
    loading,
    error: errorMessage,
  };
};

export interface UseExperianMetaDataResponse {
  experianMetaData?: ExperianMetaData;
  loading: boolean;
  error?: string;
}
export const useExperianMetaData = (
  submissionUuid?: string
): UseExperianMetaDataResponse => {
  const [experianMetaData, setExperianMetaData] = useState<ExperianMetaData>();

  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined
  );

  useEffect(() => {
    const getExperianMetaData = async (): Promise<void> => {
      try {
        if (submissionUuid) {
          setLoading(true);
          const data = await fetchExperianMetaData(
            submissionUuid,
            'submission'
          );
          setExperianMetaData(data);
        }
      } catch (e: unknown) {
        const error = toError(e);
        setErrorMessage(`Experian meta data: ${error.message}`);
        FFLogger.error(error);
      } finally {
        setLoading(false);
      }
    };

    void getExperianMetaData();
  }, [submissionUuid]);

  return {
    experianMetaData,
    loading,
    error: errorMessage,
  };
};

export interface CustomerLexisNexisReportResponse {
  customerLexisNexisReports?: CustomerLexisNexisReport[];
  loading: boolean;
  error?: string;
  refetch: () => Promise<void>;
}

export const useCustomerLexisNexisReports = (
  submissionUuid: string
): CustomerLexisNexisReportResponse => {
  const [reports, setReports] = useState<
    CustomerLexisNexisReport[] | undefined
  >();
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined
  );
  const getReports = async (): Promise<void> => {
    try {
      const data = await fetchLexisNexisCustomerReports(submissionUuid);
      setReports(data);
    } catch (e: unknown) {
      const error = toError(e);
      setErrorMessage(
        `Failed to fetch Lexis Nexis Customer Report: ${error.message}`
      );
      FFLogger.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    void getReports();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submissionUuid]);

  return {
    customerLexisNexisReports: reports,
    loading,
    error: errorMessage,
    refetch: getReports,
  };
};

export type UsePullCustomerCreditReportInput = Omit<
  UsePullExperianCommercialArgs,
  'submissionUuid'
>;

export type UsePullCustomerCreditReportResponse = [
  (input: UsePullCustomerCreditReportInput) => Promise<MutationResponse>,
  { success?: true; loading: boolean; error?: Error }
];

export const usePullCustomerCreditReport = (
  submissionUuid: string
): UsePullCustomerCreditReportResponse => {
  const [fetcher, { data, loading, error }] = usePullExperianCommercial();

  const wrappedFetcher = (
    input: UsePullCustomerCreditReportInput
  ): Promise<MutationResponse> => {
    return fetcher({
      ...input,
      submissionUuid,
    });
  };

  useLogError(error);

  return [wrappedFetcher, { success: data?.success, loading, error }];
};

export interface UseCustomerLexisNexisOverviewResponse {
  overviews?: CustomerLexisNexisOverview[];
  loading: boolean;
  error?: string;
}

export const useCustomerLexisNexisOverview = (
  submissionUuid: string
): UseCustomerLexisNexisOverviewResponse => {
  const [overviews, setOverviews] = useState<
    CustomerLexisNexisOverview[] | undefined
  >();
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined
  );

  useEffect(() => {
    const getLexisNexisOverview = async (): Promise<void> => {
      try {
        const data = await fetchLexisNexisCustomerOverviews(submissionUuid);
        setOverviews(data);
      } catch (e: unknown) {
        const error = toError(e);
        setErrorMessage(error.message);
        FFLogger.error(error);
      } finally {
        setLoading(false);
      }
    };

    void getLexisNexisOverview();
  }, [submissionUuid]);

  return {
    overviews,
    loading,
    error: errorMessage,
  };
};

export interface UseCustomerLexisNexisSearchResultsResponse {
  documents?: LexisNexisCustomerSearchResultDocument[] | null;
  loading: boolean;
  error?: string;
}

const toLexisNexisSearchResult = (
  result: LexisNexisCustomerSearchResult
): LexisNexisSearchResult => {
  const definedStreetAddressParts = [
    result.address.street_number,
    result.address.street_name,
    result.address.street_suffix,
  ].filter(Boolean);

  return {
    reportId: result.report_identifier,
    name: result.name,
    address: {
      streetAddress:
        definedStreetAddressParts.length > 0
          ? definedStreetAddressParts.join(' ')
          : undefined,
      city: result.address.city,
      state: result.address.state,
      zip: result.address.zip5,
    },
    hasUcc: result.has_ucc,
  };
};

const toLexisNexisCustomerSearchResultsResponseDocument = (
  document: LexisNexisCustomerSearchResultsResponseDocument
): LexisNexisCustomerSearchResultDocument => {
  return {
    submissionUuid: document.submission_uuid,
    results: document.results.map(toLexisNexisSearchResult),
    createdAt: document.created_at,
    query: document.query
      ? {
          companyName: document?.query?.company_name ?? undefined,
        }
      : undefined,
  };
};

const responseToDocuments = (
  data?: LexisNexisCustomerSearchResultsResponse
): LexisNexisCustomerSearchResultDocument[] | undefined => {
  /**
   * This endpoint response can contain a number of things based on a feature flag.
   *
   * If the send_multiple_ln_search_results flag is on, it will always return an
   * array of documents or an empty array. If the flag is off, it will return
   * an empty array or a single document object.
   *
   * Until we can fully implement this feature flag in a future ticket, this function
   * will convert the response to an array of documents if it is not already an array,
   * and we will ultimately find the first document with more than one result and return
   * that document's results from the hook.
   *
   * This is so that whether the flag is on or off on the backend, the frontend
   * feature will maintain the same behavior for now.
   *
   * @bradleyden - November 20, 2023
   */
  if (!data) {
    return undefined;
  }
  if (!Array.isArray(data?.documents)) {
    return [data?.documents].map(
      toLexisNexisCustomerSearchResultsResponseDocument
    );
  }

  return data?.documents.map(toLexisNexisCustomerSearchResultsResponseDocument);
};

export const useLexisNexisCustomerSearchResults = (
  submissionUuid: string
): UseCustomerLexisNexisSearchResultsResponse => {
  const { data, loading, error } =
    useGetApiLexisNexisCustomerSearchResults(submissionUuid);

  /**
   * I'm explicitly not using `useMemo` here because I don't
   * expect any crazy performance issues from this, but I've
   * written this in such a way as to make that easy to add if
   * I'm wrong.
   *
   * @tyrelosaur - March 21, 2023
   */
  const docs = responseToDocuments(data);

  return {
    documents: docs,
    loading,
    error: error?.message,
  };
};

type PullLexisNexisBody = {
  force?: boolean;
  reportId?: string;
};

type UsePullCustomerLexisNexisReportResult = [
  (input: PullLexisNexisBody) => Promise<MutationResponse>,
  { data?: { success: true }; loading: boolean; error?: Error }
];

export const usePullCustomerLexisNexisReport = (
  submissionUuid: string
): UsePullCustomerLexisNexisReportResult => {
  const [baseFetchFunction, { data, loading, error }] =
    usePullCustomerLexisNexis();

  const pullReport = async (
    input: PullLexisNexisBody
  ): Promise<MutationResponse> => {
    return await baseFetchFunction({
      submissionUuid,
      force: input.force,
      reportId: input.reportId,
    });
  };

  useLogError(error);

  return [
    pullReport,
    {
      data,
      loading,
      error,
    },
  ];
};

export interface UsePaynetResultsResponse {
  results?: PaynetSearchResult[] | null;
  loading: boolean;
  error?: string;
}

const toPaynetSearchResult = (result: PaynetResponse): PaynetSearchResult => {
  return {
    paynetId: result.paynet_id,
    name: result.name,
    city: result.city,
    stateCode: result.state_code,
    taxId: result.tax_id,
  };
};

const paynetResponseToResults = (
  data?: PaynetResultsResponse
): PaynetSearchResult[] | undefined => {
  if (!data) {
    return undefined;
  }
  if (Array.isArray(data?.documents)) {
    return undefined;
  }

  return data?.documents?.results?.map(toPaynetSearchResult);
};

export const usePaynetSearchResults = (
  submissionUuid: string
): UsePaynetResultsResponse => {
  const { data, loading, error } = useGetApiPaynetSearchResults(submissionUuid);

  const results = paynetResponseToResults(data);

  return {
    results,
    loading,
    error: error?.message,
  };
};

type PullPaynetBody = {
  reportId?: string;
};

type UsePullPaynetReportResult = [
  (input: PullPaynetBody) => Promise<MutationResponse>,
  { data?: MutationResponse; loading: boolean; error?: Error }
];

export const usePullPaynetReport = (
  submissionUuid: string
): UsePullPaynetReportResult => {
  const [baseFetchFunction, { data, loading, error }] = usePullPaynet();

  const pullReport = (input: PullPaynetBody): Promise<MutationResponse> => {
    return baseFetchFunction({
      submissionUuid,
      reportId: input.reportId,
    });
  };

  return [
    pullReport,
    {
      data,
      loading,
      error,
    },
  ];
};

type UpdatedCustomer = Pick<CustomerBusinessSummary, 'legalName' | 'dba'> & {
  industryId: number | undefined;
  industry: string | undefined;
  businessStartDate?: string | undefined;
};

type UseUpdateCustomerResult = [
  (input: UpdateCustomerBody) => Promise<MutationResponse>,
  UseGenericMutationResult<UpdatedCustomer>
];

const toUpdatedCustomer = (
  customerResponse: CustomerResponse
): UpdatedCustomer => {
  return {
    legalName: customerResponse.legal_name,
    dba: customerResponse.name,
    industryId: customerResponse.industry_id ?? undefined,
    industry: customerResponse.industry_name ?? undefined,
    businessStartDate: customerResponse.started_on ?? undefined,
  };
};

export const useUpdateCustomer = (
  customerUuid: string
): UseUpdateCustomerResult => {
  const [updateCustomer, { loading, responseReady, data, error }] =
    useUpdateApiCustomer();

  const updateFunction = (
    args: UpdateCustomerBody
  ): Promise<MutationResponse> => {
    return updateCustomer({
      customerUuid,
      updateBody: {
        legal_name: args.legalName,
        name: args.dba,
        industry_id: args.industryId,
        started_on: args.businessStartDate,
      },
    });
  };

  useLogError(error);

  return [
    updateFunction,
    {
      loading,
      // Might not be needed. Wait to next PR to see.
      responseReady,
      // Might not be needed. Wait to next PR to see.
      data: data ? toUpdatedCustomer(data) : undefined,
      error,
    },
  ];
};

/**
 * useUpdateCustomerWebPresence
 *
 * NOTE WELL: We are currently using the accountUuid instead of the customerUuid
 * for the web presence endpoints. The backend does not support using
 * customerUuid yet. So the names of the hooks and the types are a bit
 * misleading during this transition period, because they are named for the
 * future state where we will use customerUuid.
 *
 */
export type UpdateCustomerWebPresenceBody = {
  url: string;
};

type UseUpdateCustomerWebResponseResult = [
  (input: UpdateCustomerWebPresenceBody) => Promise<MutationResponse>,
  UseGenericMutationResult<CustomerWebPresenceUpdated>
];

const toUpdatedCustomerWebPresence = (
  response: CustomerWebPresencePatchResponse
): CustomerWebPresenceUpdated => {
  return {
    webPresenceType: response.web_presence_type,
    url: response.url,
  };
};

export const useUpdateCustomerWebPresence = (
  accountUuid: string,
  webPresenceType: WebPresenceType
): UseUpdateCustomerWebResponseResult => {
  const [updateCustomerWebPresence, { loading, responseReady, data, error }] =
    useUpdateApiCustomerWebPresence();

  const updateFunction = ({
    url,
  }: UpdateCustomerWebPresenceBody): Promise<MutationResponse> => {
    return updateCustomerWebPresence({
      accountUuid,
      webPresenceType,
      body: {
        url,
      },
    });
  };

  useLogError(error);

  return [
    updateFunction,
    {
      loading,
      responseReady,
      data: data ? toUpdatedCustomerWebPresence(data) : undefined,
      error,
    },
  ];
};

const toIndustryRisks = (response: IndustryRiskProfile): RiskProfile => {
  return {
    id: response.id,
    name: response.name,
    riskProfile: response.risk_profile,
    pricingRiskProfile: response.pricing_risk_profile,
    archived: response.archived,
  };
};

export const useIndustryRisks = (): UseGenericQueryResponse<RiskProfile[]> => {
  return useGenericFeatureQuery(useGetApiIndustryRisks, (data) =>
    data ? data.map(toIndustryRisks) : undefined
  );
};

const toWebPresence = (
  webPresence?: CustomerWebPresenceResponse
): CustomerWebPresence => {
  if (!webPresence) {
    return {};
  }

  return {
    businessWebsite: webPresence.business_website ?? undefined,
    facebook: webPresence.facebook ?? undefined,
    instagram: webPresence.instagram ?? undefined,
    yelp: webPresence.yelp ?? undefined,
    other: webPresence.other ?? undefined,
  };
};

type UseWebPresenceResult = UseGenericQueryResponse<CustomerWebPresence>;

export const useGetWebPresence = (
  customerUuid: string
): UseWebPresenceResult => {
  return useGenericFeatureQuery(
    useGetApiWebPresences,
    toWebPresence,
    customerUuid
  );
};

export type UseBulkUpdateWebPresenceResult = UseGenericMutationResponse<
  CustomerWebPresence,
  CustomerWebPresence
>;

export const useBulkUpdateWebPresence = (
  // This should be changed to customerUuid when the backend supports it.
  accountUuid: string
): UseBulkUpdateWebPresenceResult => {
  const [updateWebPresences, { data, error, ...rest }] =
    useBulkUpdateApiCustomerWebPresence();

  const updateFunction = (
    args: CustomerWebPresence
  ): Promise<MutationResponse> => {
    return updateWebPresences({
      accountUuid,
      body: {
        business_website: args.businessWebsite ?? null,
        facebook: args.facebook ?? null,
        instagram: args.instagram ?? null,
        yelp: args.yelp ?? null,
        other: args.other ?? null,
      },
    });
  };

  useLogError(error);

  return [
    updateFunction,
    {
      data: data ? toWebPresence(data) : undefined,
      error,
      ...rest,
    },
  ];
};

export type UsePullRelativity6IndustryPredictionResponse = [
  (args: PullRelativity6IndustryPredictionArgs) => Promise<MutationResponse>,
  {
    data?: PullRelativity6IndustryPredictionResponse;
    error?: Error;
    loading: boolean;
    responseReady: boolean;
  }
];

type PullRelativity6IndustryPredictionArgs = {
  body: UsePullRelativity6IndustryPredictionArgs;
};

const toRelativity6IndustryPredictionAttributes = (
  prediction: Relativity6IndustryPredictionResponseBody
): PullRelativity6IndustryPredictionResponse => {
  return {
    id: prediction.id,
    naicsVersion: prediction.naics_version,
    industryPredictions: prediction.industry_predictions.map((p) => ({
      industryName: p.industry_name,
      naicsCode: p.naics_code,
      confidenceScore: p.confidence_score,
      rank: p.rank,
    })),
    webPresences: prediction.web_presences,
  };
};

export const usePullRelativity6IndustryPrediction =
  (): UsePullRelativity6IndustryPredictionResponse => {
    const [pullFn, { data, error, responseReady, ...rest }] =
      useApiPullRelativity6IndustryPrediction();

    const pullFunction = useCallback(
      async (
        args: PullRelativity6IndustryPredictionArgs
      ): Promise<MutationResponse> => {
        return pullFn(args.body);
      },
      [pullFn]
    );

    useLogError(error);

    return [
      pullFunction,
      {
        data: data
          ? toRelativity6IndustryPredictionAttributes(data)
          : undefined,
        error,
        responseReady,
        ...rest,
      },
    ];
  };

type IndustryNaicsResponseBody = {
  industryId: string;
};

const toIndustryNaicsIndustryId = (
  data?: IndustryNaicsResponse
): IndustryNaicsResponseBody | undefined => {
  if (!data) {
    return undefined;
  }

  return {
    industryId: data.industry_id,
  };
};

export const useGetIndustryNaicsIndustryId = (
  naicsCode?: string,
  naicsVersion?: '2017' | '2022'
): UseGenericQueryResponse<IndustryNaicsResponseBody> => {
  return useGenericFeatureQuery(
    useApiIndustryNaicsIndustryId,
    toIndustryNaicsIndustryId,
    naicsCode,
    naicsVersion
  );
};

const toRelativity6ArtifactsSubmission = (
  artifact: ArtifactData
): CreateRelativity6ArtifactsRequestBody => {
  return {
    artifact_data: {
      predicted_industry: artifact.predictedIndustry,
      assigned_industry: artifact.assignedIndustry,
      confidence_score: artifact.confidenceScore,
      naics_code: artifact.naicsCode,
      naics_version: artifact.naicsVersion,
      artifact_case: artifact.artifactCase,
      artifact_type: artifact.artifactType,
      naics_code_title: artifact.naicsCodeTitle,
    },
  };
};

type UseCreateRelativity6AnalyticsArtifactsResponse = [
  (args: Relativity6ArtifactsRequestBody) => Promise<MutationResponse>,
  {
    error?: Error;
    loading: boolean;
  }
];

export type UseCreateRelativity6ArtifactsArgs = {
  submissionUuid: string;
  body: Relativity6ArtifactsRequestBody;
};

export const useCreateRelativity6Artifacts =
  (): UseCreateRelativity6AnalyticsArtifactsResponse => {
    const [createFn, { error, ...rest }] =
      useApiCreateRelativity6ArtifactsSubmission();

    const createFunction = async (
      args: Relativity6ArtifactsRequestBody
    ): Promise<MutationResponse> => {
      return createFn({
        submissionUuid: args.submissionUuid,
        body: toRelativity6ArtifactsSubmission(args.artifactData),
      });
    };

    useLogError(error);

    return [
      createFunction,
      {
        error,
        ...rest,
      },
    ];
  };

const toSOSFields = (response?: SOSFieldsResponse): SOSFields | undefined => {
  if (!response || !response.documents) {
    return undefined;
  }

  const streetNumber = defaultTo(
    response.documents.business_address?.street_number,
    undefined
  );

  const streetName = defaultTo(
    response.documents.business_address?.street_name,
    undefined
  );

  const streetSuffix = defaultTo(
    response.documents.business_address?.street_suffix,
    undefined
  );

  const streetAddress =
    streetNumber || streetName || streetSuffix
      ? `${streetNumber || ''} ${streetName || ''} ${streetSuffix || ''}`.trim()
      : undefined;

  const ownership = response.documents.ownership_info
    ? response.documents.ownership_info.map((owner) => {
        return {
          fullName: defaultTo(owner.full_name, undefined),
          contactType: owner.contact_type?.map((contact) => {
            return {
              type: defaultTo(contact.type, undefined),
              title: defaultTo(contact.title, undefined),
            };
          }),
        };
      })
    : undefined;

  const businessStartDate = response.documents.incorporate_date
    ? {
        year: defaultTo(response.documents.incorporate_date.year, undefined),
        month: defaultTo(response.documents.incorporate_date.month, undefined),
        day: defaultTo(response.documents.incorporate_date.day, undefined),
      }
    : undefined;

  const businessAddress = response.documents.business_address
    ? {
        streetAddress: streetAddress,
        city: defaultTo(response.documents.business_address.city, undefined),
        state: defaultTo(response.documents.business_address.state, undefined),
        zip: defaultTo(response.documents.business_address.zip5, undefined),
      }
    : undefined;

  const registeredAgent = response.documents.registered_agent
    ? {
        fullName: defaultTo(
          response.documents.registered_agent.full_name,
          undefined
        ),
        title: defaultTo(response.documents.registered_agent.title, undefined),
      }
    : undefined;

  return {
    status: defaultTo(response.documents.status, undefined),
    organizationStructure: defaultTo(
      response.documents.organize_structure,
      undefined
    ),
    legalName: defaultTo(response.documents.company_name, undefined),
    businessStartDate: businessStartDate,
    businessAddress: businessAddress,
    ownership: ownership,
    registeredAgent: registeredAgent,
  };
};

export const useSOSFields = (
  submissionUuid?: string
): UseGenericQueryResponse<SOSFields> => {
  return useGenericFeatureQuery(
    useGetApiSOSFields,
    toSOSFields,
    submissionUuid
  );
};
