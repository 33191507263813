import { Box, Flex, Subheading, Text } from '@forward-financing/fast-forward';
import React from 'react';
import { Ledger } from './ledger.types';

type LedgerContainerProps = {
  ledger: Ledger;
};
export const LedgerContainer = ({
  ledger,
}: LedgerContainerProps): JSX.Element => {
  return (
    <Flex flexDirection={'column'}>
      <Flex justifyContent="space-between" alignItems="center">
        <Box>
          <Text>Created On {ledger.createdAt}</Text>
        </Box>

        <Box> This is the toolbox placeholder</Box>
      </Flex>
      <Flex flexDirection={'column'}>
        <Subheading>Revenue Summary</Subheading>
        <Box backgroundColor="gray-400" p={10}>
          Placeholder
        </Box>
      </Flex>
    </Flex>
  );
};
