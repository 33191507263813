import React from 'react';
import {
  Banner,
  Box,
  Flex,
  formatDateString,
  formatDateTimeString,
  formatPhoneNumber,
  Icon,
  IconButton,
  Subheading,
  Table,
  Tag,
  Text,
} from '@forward-financing/fast-forward';
import { UNDERWRITING_BASE_URL } from 'constants/globals';
import { featureFlags } from 'helpers/featureFlags';
import { IndependentSalesOrganization } from '../ApplicationSnapshot/applicationSnapshot.types';
import { useOwner, useOwnerSimilarContacts } from './matchedRecordsHooks';
import { CustomerSubmission, Owner } from './matchedRecords.types';

const verifyMatchingAddress = (
  street1: string | null,
  city: string | null,
  owner?: Owner
): boolean => {
  if (street1 && city && owner?.address?.street1 && owner?.address?.city) {
    return (
      owner.address.street1.toLowerCase().trim() ===
        street1.toLowerCase().trim() &&
      owner.address.city.toLowerCase().trim() === city.toLowerCase().trim()
    );
  }

  return false;
};

export interface SimilarContactsTableProps {
  ownerUuid: string;
  currentSubmissionUuid: string;
  submissions: CustomerSubmission[] | undefined;
  isos: IndependentSalesOrganization[] | undefined;
  onRefresh: (ownerUuid: string, submissionUuid: string) => void;
}

export const SimilarContactsTable = ({
  ownerUuid,
  submissions,
  currentSubmissionUuid,
  isos,
  onRefresh,
}: SimilarContactsTableProps): JSX.Element => {
  const { owner, loading, error } = useOwner(ownerUuid);

  const {
    similarContacts,
    loading: similarContactsLoading,
    error: similarContactsError,
  } = useOwnerSimilarContacts(ownerUuid, currentSubmissionUuid);

  const isCurrentSubmission = (sub: CustomerSubmission): boolean =>
    currentSubmissionUuid === sub.submissionUuid;

  const currentSubmission = submissions?.find((sub) =>
    isCurrentSubmission(sub)
  );

  if (loading || similarContactsLoading) {
    return <></>;
  }

  if (error || similarContactsError) {
    return (
      <>
        {error && <Banner variant="error">{error.message}</Banner>}
        {similarContactsError && (
          <Banner variant="error">{similarContactsError.message}</Banner>
        )}
      </>
    );
  }

  // istanbul ignore next - this should be impossible to reach
  if (!owner || !similarContacts) {
    return (
      <Banner>
        Something has gone terribly wrong, and we are in a state that should be
        impossible. Please create an AST.
      </Banner>
    );
  }

  const sortedSimilarContacts = similarContacts.sort((a, b) =>
    (a.lastOpportunityDate ?? 0) >= (b.lastOpportunityDate ?? 0) ? -1 : 1
  );

  const currentContactIsoName = isos?.find(
    (s) => s.uuid === currentSubmission?.isoUuid
  )?.name;

  // Extracting the first part of the submission name string before the dash
  // for display purposes
  const currentSubmissionDisplayName =
    currentSubmission?.submissionName.split(' - ')[0];

  const DECISION_OR_CREDIT_COMMITTEE =
    featureFlags.use_decision_committee_naming
      ? 'Decision Committee'
      : 'Credit Committee';

  const renderSubStage = (subStage?: string): string => {
    if (subStage === 'Credit Committee') {
      return DECISION_OR_CREDIT_COMMITTEE;
    }

    return subStage ?? '';
  };

  return (
    <Box>
      <Flex justifyContent="space-between" alignItems="center" gap={2}>
        <Subheading variant="section">Similar Contacts</Subheading>
        <Flex alignItems="center" gap={2}>
          <IconButton
            icon="refresh"
            label="Refresh similar owners"
            onClick={() => onRefresh(ownerUuid, currentSubmissionUuid)}
            hiddenLabel
          />
          <Text>Last Refresh: {formatDateTimeString(owner.updatedAt)}</Text>
        </Flex>
      </Flex>
      <Table>
        <Table.Head>
          <Table.ColumnHeader>Contact Name</Table.ColumnHeader>
          <Table.ColumnHeader>SSN</Table.ColumnHeader>
          <Table.ColumnHeader abbr="Date of Birth">DOB</Table.ColumnHeader>
          <Table.ColumnHeader>Phone</Table.ColumnHeader>
          <Table.ColumnHeader>Address</Table.ColumnHeader>
          <Table.ColumnHeader>Customer</Table.ColumnHeader>
          <Table.ColumnHeader>ISO</Table.ColumnHeader>
          <Table.ColumnHeader colSpan={2}>
            Most Recent Submission
          </Table.ColumnHeader>
          <Table.ColumnHeader>Stage / Sub-Stage</Table.ColumnHeader>
          <Table.ColumnHeader>Decline Drivers</Table.ColumnHeader>
        </Table.Head>
        <Table.Body>
          <Table.Row>
            <Table.Cell backgroundColor="green-200">
              {`${owner.fullName} (Current Contact)`}
            </Table.Cell>
            <Table.Cell backgroundColor="green-200">
              {owner.ssnLastFour}
            </Table.Cell>
            <Table.Cell backgroundColor="green-200">
              {owner.birthdate && formatDateString(owner.birthdate)}
            </Table.Cell>
            <Table.Cell backgroundColor="green-200">
              {owner.phoneNumber && formatPhoneNumber(owner.phoneNumber)}
            </Table.Cell>
            <Table.Cell backgroundColor="green-200" />
            <Table.Cell backgroundColor="green-200">
              {currentSubmissionDisplayName}
            </Table.Cell>
            <Table.Cell backgroundColor="green-200">
              {currentContactIsoName}
            </Table.Cell>
            <Table.Cell backgroundColor="green-200">
              {currentSubmission?.dateAppReceived &&
                formatDateString(currentSubmission.dateAppReceived)}
            </Table.Cell>
            {/* This cell is empty because there will never be a renewal Tag in here */}
            <Table.Cell backgroundColor="green-200" />
            <Table.Cell backgroundColor="green-200">
              {`${currentSubmission?.stage || ''} / ${renderSubStage(
                currentSubmission?.subStage
              )}`}
            </Table.Cell>
            <Table.Cell backgroundColor="green-200">
              {currentSubmission?.declineDrivers}
            </Table.Cell>
          </Table.Row>

          {sortedSimilarContacts.map((contact) => {
            return (
              // We only want to display the row if the lastOpportunityDate
              // is a truthy value OR the contact dealStage is in
              // Renewal Prospective or Renewals (Ineligible) stages
              (contact.lastOpportunityDate ||
                ['Renewal Prospecting', 'Renewals (Ineligible)'].includes(
                  contact.dealStage
                )) && (
                <Table.Row key={contact.id}>
                  <Table.Cell
                    backgroundColor={
                      contact.name &&
                      contact.name.toLowerCase() ===
                        owner.fullName.toLowerCase()
                        ? 'blue-100'
                        : undefined
                    }
                  >
                    {contact.name}
                  </Table.Cell>
                  <Table.Cell
                    backgroundColor={
                      contact.ssnLastFour && contact.ssnMatch
                        ? 'blue-100'
                        : undefined
                    }
                  >
                    <Flex gap={2} alignItems="baseline">
                      {contact.ssnLastFour}
                      {contact.ssnMatch && (
                        <Icon name="check" data-testid="ssn-match-icon" />
                      )}
                    </Flex>
                  </Table.Cell>
                  <Table.Cell
                    backgroundColor={
                      contact.birthdate &&
                      owner.birthdate &&
                      formatDateString(contact.birthdate) ===
                        formatDateString(owner.birthdate)
                        ? 'blue-100'
                        : undefined
                    }
                  >
                    {contact.birthdate && formatDateString(contact.birthdate)}
                  </Table.Cell>
                  <Table.Cell
                    backgroundColor={
                      contact.phoneNumber &&
                      owner.phoneNumber &&
                      formatPhoneNumber(contact.phoneNumber) ===
                        formatPhoneNumber(owner.phoneNumber)
                        ? 'blue-100'
                        : undefined
                    }
                  >
                    {contact.phoneNumber &&
                      formatPhoneNumber(contact.phoneNumber)}
                  </Table.Cell>
                  <Table.Cell
                    backgroundColor={
                      verifyMatchingAddress(
                        contact.street1,
                        contact.city,
                        owner
                      )
                        ? 'blue-100'
                        : undefined
                    }
                  >
                    {verifyMatchingAddress(
                      contact.street1,
                      contact.city,
                      owner
                    ) && (
                      <Flex justifyContent={'center'}>
                        <Icon name="check" data-testid="address-match-icon" />
                      </Flex>
                    )}
                  </Table.Cell>
                  <Table.Cell
                    backgroundColor={
                      contact.customerName &&
                      contact.customerName.toLowerCase() ===
                        currentSubmissionDisplayName?.toLowerCase()
                        ? 'blue-100'
                        : undefined
                    }
                  >
                    {contact.customerName}
                  </Table.Cell>
                  <Table.Cell
                    backgroundColor={
                      contact.isoName &&
                      contact.isoName.toLowerCase() ===
                        currentContactIsoName?.toLowerCase()
                        ? 'blue-100'
                        : undefined
                    }
                  >
                    {contact.isoName}
                  </Table.Cell>
                  <Table.LinkCell
                    href={
                      new URL(
                        `/admin/applications/${contact.lastOpportunityUuid}`,
                        UNDERWRITING_BASE_URL()
                      )
                    }
                    newTab
                  >
                    {contact.lastOpportunityDate &&
                    !Number.isNaN(Date.parse(contact.lastOpportunityDate))
                      ? formatDateString(contact.lastOpportunityDate)
                      : 'N/A'}
                  </Table.LinkCell>
                  <Table.Cell>
                    {contact.isRenewal && <Tag>Renewal</Tag>}
                  </Table.Cell>
                  <Table.Cell>
                    {contact.dealStage} / {renderSubStage(contact.dealSubstage)}
                  </Table.Cell>
                  <Table.Cell>
                    {contact.declineDrivers?.map((driver) => (
                      <Text key={driver}>{driver}</Text>
                    ))}
                  </Table.Cell>
                </Table.Row>
              )
            );
          })}
        </Table.Body>
      </Table>
    </Box>
  );
};
