import { useCallback } from 'react';
import { fetchInference } from 'api/mldp/inferenceFetchUtils';
import {
  UseGenericQueryResponse,
  useGenericQuery,
} from 'apiHooks/genericFetchHooks';
import { InferenceResponse } from 'types/api/mldp/types';

export type UseApiInferenceResponse =
  UseGenericQueryResponse<InferenceResponse>;

export const useGetApiInference = (
  submissionUuid?: string
): UseApiInferenceResponse => {
  const fetcher = useCallback(() => {
    if (!submissionUuid) {
      return undefined;
    }

    return fetchInference(submissionUuid);
  }, [submissionUuid]);

  return useGenericQuery<InferenceResponse>(fetcher);
};
