import { updateLedger } from 'api/banking/ledgerFetchUtils';
import {
  UseGenericMutationResponse,
  useGenericMutation,
} from 'apiHooks/genericFetchHooks';
import { UpdateLedgerBody } from 'types/api/banking/types';

export interface UseUpdateLedgerArgs {
  submissionUuid: string;
  updateBody: UpdateLedgerBody;
}

export type UseUpdateLedgerResponse = UseGenericMutationResponse<
  { success: boolean },
  UseUpdateLedgerArgs
>;

const updateLedgerWithArgs = async (
  args: UseUpdateLedgerArgs
): Promise<{ success: boolean }> =>
  updateLedger(args.submissionUuid, args.updateBody);

export const useApiUpdateLedger = (): UseUpdateLedgerResponse => {
  return useGenericMutation(updateLedgerWithArgs);
};
