import React from 'react';
import { Flex, Link, Text } from '@forward-financing/fast-forward';
import { CustomerWebPresence } from './applicationSnapshot.types';
import { isValidHttpUrl } from './applicationSnapshotUtils';

export interface WebPresenceListProps {
  webPresence: CustomerWebPresence | undefined;
}

export const WebPresenceList = ({
  webPresence,
}: WebPresenceListProps): JSX.Element => {
  if (!webPresence || Object.entries(webPresence).length === 0) {
    return <></>;
  }

  return (
    <Flex gap={2} flexDirection={'column'}>
      {/* key is the web presence type, e.g. facebook or businessWebsite
        value is the url. */}
      {Object.entries(webPresence).map(([key, value]) => {
        if (typeof value !== 'string' || value === '') {
          return <React.Fragment key={key} />;
        }

        if (!isValidHttpUrl(value)) {
          return (
            <Text key={key}>
              Invalid {key} URL: {value}
            </Text>
          );
        }

        return (
          <Text key={key}>
            <Link newTab href={new URL(value)}>
              {value}
            </Link>
          </Text>
        );
      })}
    </Flex>
  );
};
