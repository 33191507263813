import React, { useState, useEffect } from 'react';
import Select, { ValueType } from 'react-select';

import {
  Button,
  Icon,
  Loading,
  Flex,
  Checkbox,
  Box,
  Subheading,
  Text,
} from '@forward-financing/fast-forward';
import defaultTo from 'lodash/defaultTo';
import { featureFlags } from 'helpers/featureFlags';
import { BorderedContainer } from '../../shared/BorderedContainer';
import { WebPresenceBorderedContainer } from '../../shared/WebPresenceBorderedContainer/WebPresenceBorderedContainer';
import { FormRow, DisplayStyle } from '../../shared/form/FormRow';

import { Dropdown } from '../../shared/form/Dropdown';
import {
  DropdownOption,
  HTMLFormChangeEvent,
  HTMLFormChangeOrMouseEvent,
} from '../../../types/form';
import { IsoPopover } from '../AccountInformationForm/IsoPopover';

import {
  Opportunity,
  Submission,
  WebPresenceType,
  IndustryType,
} from '../../../api/UnderwritingClient/codecs';
import { User } from '../../../api/AuthClient/codecs';
import { Partner } from '../../../api/FundingClient/codecs';
import { HashMap } from '../../../api/codecs';
import { isAdmin } from '../../../helpers/utils';

// Inputs
import { Input } from '../../shared/form/Input';
import { TextArea } from '../../shared/form/TextArea';
import { WebPresenceInput } from '../../shared/form/WebPresenceInput';

// Validations
import {
  isRequired,
  isBiggerThan,
} from '../../../helpers/validations/ValidationHelpers';
import {
  validate,
  validateCapitalNeeded,
  validateDate,
  validField,
  validateEmail,
  validateURL,
} from '../../../helpers/validations/FieldValidator';
import { ValidationResult } from '../../../helpers/validations/codecs';
import {
  ENTITY_TYPES,
  USE_OF_FUNDS,
  DATE_FORMAT,
} from '../../../constants/globals';
import { IndustryPredictionResponse } from '../Wizard.types';
import { EntityTypePopover } from './EntityTypePopover';

import { useCreateRelativity6ArtifactsApplication } from './AdditionalInformationFormFetchHooks';
import { IsoSalesRepPopover } from './IsoSalesRepPopover';
import { BusinessStartDatePopover } from './BusinessStartDatePopover';
import { EmailContentPopover } from './EmailContentPopover';

export interface AdditionalInformationFormProps {
  partner_id: number;
  submission: Submission;
  opportunity: Pick<Opportunity, 'type' | 'renewal_submitter_email'>;
  industryTypes: IndustryType[];
  partners: Partner[];
  handleApplicationChange: (e: HTMLFormChangeEvent) => void;
  handleAccountChange: (e: HTMLFormChangeOrMouseEvent) => void;
  handleOpportunityChange?: (e: HTMLFormChangeOrMouseEvent) => void;
  handleWebPresenceChange: (e: HTMLFormChangeOrMouseEvent) => void;
  onBack: () => void;
  onSubmit: () => void;
  declinedIcon: () => JSX.Element;
  forPrequal: boolean;
  loggedInUser: User;
  mostConfidentIndustry?: IndustryPredictionResponse;
  suggestedIndustry?: IndustryType;
  hasVerifiedIndustry: boolean;
  setHasVerifiedIndustry: (value: boolean) => void;
  hasUpdatedBusinessType: boolean;
  setHasUpdatedBusinessType: (value: boolean) => void;
}

export const AdditionalInformationForm = ({
  partner_id,
  submission,
  opportunity,
  industryTypes: industryTypesProp,
  partners: partnersProp,
  handleAccountChange,
  handleApplicationChange,
  handleWebPresenceChange,
  handleOpportunityChange,
  onBack,
  onSubmit,
  declinedIcon,
  forPrequal,
  loggedInUser,
  mostConfidentIndustry,
  suggestedIndustry,
  hasVerifiedIndustry,
  setHasVerifiedIndustry,
  hasUpdatedBusinessType,
  setHasUpdatedBusinessType,
}: AdditionalInformationFormProps): JSX.Element => {
  const [isLoading, setIsLoading] = useState(false);

  const industryTypes: DropdownOption[] = [
    {
      value: 0,
      label: 'Select a Business Type',
    },
    ...industryTypesProp.map((industryType) => ({
      value: industryType.id,
      label: industryType.name,
    })),
  ];

  const partners: DropdownOption[] = [
    {
      value: 0,
      label: 'Select a Partner',
    },
    ...partnersProp,
  ];

  const entityTypes: DropdownOption[] = [
    {
      value: '',
      label: 'Select an Entity Type',
    },
    ...ENTITY_TYPES,
  ];

  const useOfFunds: DropdownOption[] = [
    {
      value: '',
      label: 'Select Use of Funds',
    },
    ...USE_OF_FUNDS,
  ];

  const account = submission.account;

  const naicsConfidenceScore = mostConfidentIndustry?.confidenceScore
    ? `${Math.round(mostConfidentIndustry.confidenceScore * 100)}%`
    : undefined;

  // Sets Business Type to the suggested industry if it's not already set
  useEffect(() => {
    if (
      suggestedIndustry &&
      !account.industry_id &&
      featureFlags.show_relativity_6_industry_prediction
    ) {
      const syntheticEvent = {
        persist: () => {
          // Intentionally empty
        },
        target: {
          name: 'industry_id',
          value: suggestedIndustry.id,
        },
      };

      handleAccountChange(syntheticEvent as unknown as HTMLFormChangeEvent);
      setHasVerifiedIndustry(false);
      setHasUpdatedBusinessType(true);
    }
  }, [
    suggestedIndustry,
    handleAccountChange,
    account.industry_id,
    setHasVerifiedIndustry,
    setHasUpdatedBusinessType,
  ]);

  // This will ensure the verification checkbox is initially checked if the industry_id has already been set
  // directly from the backend (i.e. not from the suggested industry) and the user hasn't manually
  // updated the Business Type.
  if (account.industry_id && !hasUpdatedBusinessType) {
    setHasVerifiedIndustry(true);
  }

  const handleCapitalValue = (): string => {
    return submission.capitalNeededNotPresent ? '' : submission.capital_needed;
  };

  const handleCapitalNotPresentChange = (
    e: HTMLFormChangeOrMouseEvent
  ): void => {
    handleApplicationChange(e);

    const target = (
      e.currentTarget ? e.currentTarget : e.target
    ) as HTMLInputElement;

    if (target.checked) {
      const syntheticEvent = {
        persist: () => {
          // Intentionally empty
        },
        target: {
          name: 'capital_needed',
          value: '',
        },
      };

      handleApplicationChange(syntheticEvent as HTMLFormChangeEvent);
    }
  };

  const findPartner = (id: string | number): Partner | undefined => {
    return partnersProp.find((p) => p.value === Number(id));
  };

  const updateSalesRepEmail = (partner?: Partner): void => {
    const syntheticEventEmail = {
      persist: () => {
        // Intentionally empty
      },
      target: {
        name: 'sales_rep_email',
        value: partner ? partner.email : null,
      },
    };

    handleApplicationChange(syntheticEventEmail as HTMLFormChangeEvent);
  };

  const handleSelectPartner = (
    selectedOption: ValueType<DropdownOption, false>
  ): void => {
    // We want to set both the partnerID and the partner UUID when this field is selected
    const syntheticEventId = {
      persist: () => {
        // Intentionally empty
      },
      currentTarget: {
        name: 'partner_id',
        value: (selectedOption as DropdownOption).value.toString(),
        type: 'change',
      },
    };

    handleApplicationChange(syntheticEventId as HTMLFormChangeEvent);

    const partner = findPartner((selectedOption as DropdownOption).value);

    const syntheticEventUUID = {
      persist: () => {
        // Intentionally empty
      },
      target: {
        name: 'partner_uuid',
        value: partner ? partner.uuid : null,
      },
    };

    handleApplicationChange(syntheticEventUUID as HTMLFormChangeEvent);
    updateSalesRepEmail(partner);
  };

  const validationErrors: HashMap<ValidationResult> = {
    capital_needed: submission.capitalNeededNotPresent
      ? validField
      : validateCapitalNeeded(submission.capital_needed),
    loan_use: validField,
    entity_type: validate([isRequired], account.entity_type),
    industry_type: validate(
      [isRequired, isBiggerThan(0)],
      account.industry_id
        ? account.industry_id.toString()
        : suggestedIndustry?.id.toString() || ''
    ),
    started_on: account.started_on
      ? validateDate(
          account.started_on || '',
          'business',
          'Valid Business Start Date is Required'
        )
      : validField,
    notes: forPrequal ? validate([isRequired], submission.notes) : validField,
    business_website: validateURL(account.web_presences?.business_website?.url),
    facebook: validateURL(account.web_presences?.facebook?.url),
    instagram: validateURL(account.web_presences?.instagram?.url),
    other: validateURL(account.web_presences?.other?.url),
    yelp: validateURL(account.web_presences?.yelp?.url),
    sales_rep_email: submission.sales_rep_email
      ? validateEmail(submission.sales_rep_email)
      : validField,
    renewal_submitter_email:
      opportunity.type === 'Renewal'
        ? validateEmail(opportunity.renewal_submitter_email)
        : validField,
    partnerId: validate(
      [isRequired, isBiggerThan(0)],
      partner_id ? partner_id.toString() : ''
    ),
    // If the feature flag is off, users won't be able to see this checkbox,
    // so we mark it as valid
    industryClassification:
      hasVerifiedIndustry || !featureFlags.show_relativity_6_industry_prediction
        ? validField
        : { hasValue: true, isValid: false },
  };

  const isValidAdditionalInfo = Object.values(validationErrors).every(
    (result) => result.hasValue && result.isValid
  );

  const [createArtifacts] = useCreateRelativity6ArtifactsApplication();
  const handleSubmit = async (e: React.MouseEvent): Promise<void> => {
    e.preventDefault();

    // If Suggested Business Type exists & does not match the selected Business Type,
    // and the user has manually updated the selected Business Type,
    // create an artifact with the predicted industry and the selected industry
    if (
      featureFlags.show_relativity_6_industry_prediction &&
      suggestedIndustry?.id &&
      Number(suggestedIndustry.id) !== account.industry_id &&
      submission.uuid &&
      hasUpdatedBusinessType
    ) {
      await createArtifacts({
        applicationUuid: submission.uuid,
        artifactData: {
          predictedIndustry: defaultTo(suggestedIndustry?.id?.toString(), ''),
          assignedIndustry: defaultTo(account.industry_id?.toString(), ''),
          confidenceScore: defaultTo(
            mostConfidentIndustry?.confidenceScore?.toString(),
            ''
          ),
          naicsCode: defaultTo(mostConfidentIndustry?.naicsCode, ''),
          naicsVersion: '2017',
          artifactCase: 'Predicted Industry',
          artifactType: 'relativity',
          naicsCodeTitle: defaultTo(mostConfidentIndustry?.industryName, ''),
        },
      });

      // To account for the fact that the Wizard controls all the PQ forms, we need to
      // set the hasUpdatedBusinessType state to false here to prevent the artifact
      // from being created multiple times.
      setHasUpdatedBusinessType(false);
    }

    if (isValidAdditionalInfo) {
      setIsLoading(true);
      onSubmit();
    }
  };

  const partnerSelectIsEmpty =
    validationErrors.partnerId && !validationErrors.partnerId.hasValue;

  useEffect(() => {
    if (partner_id && !submission.sales_rep_email) {
      const partner = findPartner(partner_id);
      if (partner?.email) {
        updateSalesRepEmail(partner);
      }
    }
  });

  return (
    <div className="additional-information-form">
      <BorderedContainer
        label="Additional Information"
        labelIcon={declinedIcon()}
      >
        <div className="columns">
          <div className="column" />
        </div>

        <FormRow
          left={
            <Input
              label="Business Start Date"
              name="started_on"
              value={account.started_on || ''}
              inputMask="99/99/9999"
              moreInfoPopover={<BusinessStartDatePopover />}
              placeholder={DATE_FORMAT}
              onChange={handleAccountChange}
              validationResult={validationErrors.started_on}
            />
          }
          right={
            <Input
              leadingIcon="fas fa-dollar-sign"
              label="Amount Needed"
              name="capital_needed"
              checkBox={{
                label: 'Amount needed not on application',
                name: 'capitalNeededNotPresent',
                value: submission.capitalNeededNotPresent || false,
                checkboxLabelSize: 'is-half',
                onClick: handleCapitalNotPresentChange,
              }}
              onChange={handleApplicationChange}
              value={handleCapitalValue()}
              validationResult={validationErrors.capital_needed}
              isDisabled={submission.capitalNeededNotPresent}
            />
          }
        />

        {/* 
            This conditional throws a TS error due to BorderedContainer's children prop
            only accepting `JSX.Element | JSX.Element[]`, which is why we wrap it in
            a fragment.
        */}
        <>
          {featureFlags.show_relativity_6_industry_prediction && (
            <FormRow
              left={
                <Flex flexDirection={'column'} gap={1} my={2}>
                  <Box>
                    <Subheading variant="subsection">NAICS Industry</Subheading>
                    <Text>
                      {defaultTo(mostConfidentIndustry?.industryName, 'N/A')}
                    </Text>
                  </Box>
                  <Box>
                    <Subheading variant="subsection">
                      Suggested Business Type
                    </Subheading>
                    <Text>{defaultTo(suggestedIndustry?.name, 'N/A')}</Text>
                  </Box>
                </Flex>
              }
              right={
                <Flex flexDirection={'column'} gap={1} my={2}>
                  <Box>
                    <Subheading variant="subsection">NAICS Code</Subheading>
                    <Text>
                      {defaultTo(mostConfidentIndustry?.naicsCode, 'N/A')}
                    </Text>
                  </Box>
                  <Box>
                    <Subheading variant="subsection">
                      NAICS Confidence Score
                    </Subheading>
                    <Text>{defaultTo(naicsConfidenceScore, 'N/A')}</Text>
                  </Box>
                </Flex>
              }
            />
          )}
        </>

        <FormRow
          left={
            <Flex flexDirection={'column'} gap={1}>
              <Dropdown
                label="Business Type"
                name="industry_id"
                options={industryTypes}
                onChange={(e) => {
                  handleAccountChange(e);
                  setHasVerifiedIndustry(false);
                  setHasUpdatedBusinessType(true);
                }}
                validationResult={validationErrors.industry_type}
                value={account.industry_id || suggestedIndustry?.id || ''}
              />
              {featureFlags.show_relativity_6_industry_prediction && (
                <Checkbox
                  label="Please check to verify that you have reviewed the industry classification before proceeding."
                  checked={hasVerifiedIndustry}
                  onCheckboxChange={() =>
                    setHasVerifiedIndustry(!hasVerifiedIndustry)
                  }
                  required={!hasVerifiedIndustry}
                />
              )}
            </Flex>
          }
          right={
            <Dropdown
              label="Use of Funds"
              name="loan_use"
              options={useOfFunds}
              onChange={handleApplicationChange}
              validationResult={validationErrors.loan_use}
              value={submission.loan_use}
            />
          }
        />

        <FormRow
          left={
            <Dropdown
              label="Entity Type"
              name="entity_type"
              options={entityTypes}
              moreInfoPopover={<EntityTypePopover />}
              onChange={handleAccountChange}
              validationResult={validationErrors.entity_type}
              value={account.entity_type || ''}
            />
          }
          right={
            <TextArea
              label="Email Content"
              name="notes"
              moreInfoPopover={<EmailContentPopover />}
              value={submission.notes || ''}
              onChange={handleApplicationChange}
              validationResult={validationErrors.notes}
            />
          }
        />
        <FormRow
          left={
            <div className="field">
              <label className={`has-text-weight-bold`}>
                <Flex>
                  {partnerSelectIsEmpty && <Icon name="exclamation-circle" />}
                  Partner
                  <IsoPopover />
                </Flex>
              </label>
              <Select
                aria-label="Partner"
                value={findPartner(partner_id)}
                name="partner_id"
                options={partners}
                onChange={handleSelectPartner}
                isDisabled={isAdmin(loggedInUser) ? false : !forPrequal}
                isSearchable={true}
                placeholder="Select a Partner"
                classNamePrefix="partner"
                className={partnerSelectIsEmpty ? 'is-danger' : ''}
              />
            </div>
          }
          right={
            <Input
              label="Partner Sales Rep Email"
              name="sales_rep_email"
              moreInfoPopover={<IsoSalesRepPopover />}
              onChange={handleApplicationChange}
              value={submission.sales_rep_email || ''}
              validationResult={validationErrors.sales_rep_email}
            />
          }
        />

        {opportunity.type === 'Renewal' ? (
          <FormRow
            displayStyle={DisplayStyle.SingleRight}
            right={
              <Input
                label="Renewal Submitter Email"
                name="renewal_submitter_email"
                onChange={handleOpportunityChange}
                value={opportunity.renewal_submitter_email || ''}
                validationResult={validationErrors.renewal_submitter_email}
              />
            }
          />
        ) : (
          <></>
        )}

        {forPrequal ? (
          <></>
        ) : (
          <WebPresenceBorderedContainer label="Social Presence">
            <FormRow
              left={
                <WebPresenceInput
                  name={WebPresenceType.Website}
                  onChange={handleWebPresenceChange}
                  value={
                    account.web_presences?.[WebPresenceType.Website]?.url || ''
                  }
                  leadingComponent={<Icon name="globe" />}
                  validationResult={validationErrors.business_website}
                />
              }
              right={
                <WebPresenceInput
                  name={WebPresenceType.Facebook}
                  onChange={handleWebPresenceChange}
                  value={
                    account.web_presences?.[WebPresenceType.Facebook]?.url || ''
                  }
                  leadingComponent={<Icon prefix="fab" name="facebook" />}
                  validationResult={validationErrors.facebook}
                />
              }
            />

            <FormRow
              left={
                <WebPresenceInput
                  name={WebPresenceType.Instagram}
                  onChange={handleWebPresenceChange}
                  value={
                    account.web_presences?.[WebPresenceType.Instagram]?.url ||
                    ''
                  }
                  leadingComponent={<Icon prefix="fab" name="instagram" />}
                  validationResult={validationErrors.instagram}
                />
              }
              right={
                <WebPresenceInput
                  name={WebPresenceType.Yelp}
                  onChange={handleWebPresenceChange}
                  value={
                    account.web_presences?.[WebPresenceType.Yelp]?.url || ''
                  }
                  leadingComponent={<Icon prefix="fab" name="yelp" />}
                  validationResult={validationErrors.yelp}
                />
              }
            />

            <FormRow
              left={
                <WebPresenceInput
                  name={WebPresenceType.Other}
                  onChange={handleWebPresenceChange}
                  value={
                    account.web_presences?.[WebPresenceType.Other]?.url || ''
                  }
                  leadingComponent={
                    <span className="other has-text-weight-bold">Other</span>
                  }
                  validationResult={validationErrors.other}
                />
              }
            />
          </WebPresenceBorderedContainer>
        )}
      </BorderedContainer>
      <div className="additional-navigation-actions columns">
        <div className="column">
          {submission.prequal_state_attributes.other_info_completed ? null : (
            <Button startIcon="arrow-left" onClick={onBack}>
              Back
            </Button>
          )}
        </div>
        <div className="column has-text-right">
          {isLoading ? (
            <Loading size="small" />
          ) : (
            <Button
              endIcon="arrow-right"
              disabled={!isValidAdditionalInfo}
              onClick={handleSubmit}
            >
              Proceed to Overview
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};
