import queryString from 'query-string';
import { makeInternalAPIRequest } from 'api/makeInternalAPIRequest';
import { NetworkError } from 'api/networkError';
import { MutationResponse } from 'apiHooks/genericFetchHooks';
import { UNDERWRITING_BASE_URL } from 'constants/globals';
import {
  CreditDataResponse,
  DeclineSubmissionRequestBody,
  EnqueuedDealResponse,
  InternalRenewalCreationResponse,
  LexisNexisCustomerSearchResultsResponse,
  PaynetResultsResponse,
  PullLexisNexisReportRequestBody,
  PullPaynetReportRequestBody,
  RenewalEligibleResponse,
  SubmissionResponse,
  SubmissionStageHistoryResponse,
  SubmissionStageTransitionsResponse,
  UpdateSubmissionRequestBody,
  SubmissionRenewalCompositeScoreResponse,
  SubmissionNewDealCompositeScoreResponse,
  FraudScoringDocumentResponse,
  AlgoliaSearchParams,
  SubmissionSearchResultResponse,
  SubmissionNotesResponse,
  UpdateSubmissionNoteBody,
  RenewalScoreResponse,
  RenewalScoresFetchResponse,
  CreateRenewalScoreBody,
  UpdateRenewalScoreBody,
  LegacyReportLinksResponse,
  StipulationResponse,
  CreateStipulationRequestBody,
  UpdateStipulationRequestBody,
  SubmissionCompositeScoreResponse,
  LexisNexisCustomerSearchResponse,
  ProcessingQueueResponse,
  SubmissionLogsResponse,
  StipulationRecommendationsResponse,
  DataMerchReportResponse,
  RenewalComparisonResponse,
} from 'types/api/underwriting/types';
import { HashMap } from 'api/codecs';
// CAUTION: LOCAL TESTING ONLY. DO NOT MERGE UNCOMMENTED TEST CODE TO
// PRODUCTION.
// Uncomment this line for mock data.
// import { mockLexisNexisCustomerSearchResponse } from 'mocks/underwriting/generators/ApiV2SubmissionsUuidLexisNexisCustomerSearch';
// import { mockDataMerchReport } from 'mocks/underwriting/generators/ApiV2DataMerch';

const underwriting = (path: string): URL =>
  new URL(path, UNDERWRITING_BASE_URL());

export const fetchSubmission = async (
  submissionUuid: string
): Promise<SubmissionResponse> => {
  const url = underwriting(`/api/v2/submissions/${submissionUuid}`);

  const response = await makeInternalAPIRequest<SubmissionResponse>(url, 'GET');

  if (!response.ok) {
    throw new NetworkError(response.status, 'Failed to fetch submission');
  }

  return response.json();
};

export const fetchSubmissions = async (
  searchParams: AlgoliaSearchParams
): Promise<SubmissionSearchResultResponse> => {
  const url = underwriting(
    `api/v2/submissions?${queryString.stringify(searchParams)}`
  );

  const response = await makeInternalAPIRequest<SubmissionSearchResultResponse>(
    url,
    'GET'
  );

  if (!response.ok) {
    throw new NetworkError(response.status, 'Failed to fetch submissions');
  }

  return response.json();
};

export const patchSubmission = async (
  submissionUuid: string,
  body: UpdateSubmissionRequestBody
): Promise<SubmissionResponse> => {
  const url = underwriting(`/api/v2/submissions/${submissionUuid}`);

  const response = await makeInternalAPIRequest<
    SubmissionResponse,
    UpdateSubmissionRequestBody
  >(url, 'PATCH', body);

  if (!response.ok) {
    throw new NetworkError(response.status, 'Failed to update submission');
  }

  return response.json();
};

export const declinePatchSubmission = async (
  submissionUuid: string,
  body: DeclineSubmissionRequestBody
): Promise<SubmissionResponse> => {
  const url = underwriting(`/api/v2/submissions/${submissionUuid}/decline`);

  const response = await makeInternalAPIRequest<
    SubmissionResponse,
    DeclineSubmissionRequestBody
  >(url, 'PATCH', body);

  if (!response.ok) {
    throw new NetworkError(response.status, 'Failed to decline submission');
  }

  return response.json();
};

export const fetchLexisNexisCustomerSearchResults = async (
  submissionUuid: string
): Promise<LexisNexisCustomerSearchResultsResponse> => {
  const url = underwriting(
    `/api/v2/submissions/${submissionUuid}/documents/lexis_nexis_customer_search_results`
  );

  const response =
    await makeInternalAPIRequest<LexisNexisCustomerSearchResultsResponse>(
      url,
      'GET'
    );

  if (!response.ok) {
    throw new NetworkError(
      response.status,
      'Failed to fetch Lexis Nexis Customer Search Results'
    );
  }

  return response.json();
};

export const fetchLexisNexisCustomerManualSearch = async (
  submissionUuid: string,
  queryParams: string
): Promise<LexisNexisCustomerSearchResponse> => {
  // CAUTION: LOCAL TESTING ONLY. DO NOT MERGE UNCOMMENTED TEST CODE TO
  // PRODUCTION.
  // Uncomment this line for mock data.
  // return mockLexisNexisCustomerSearchResponse();

  const url = new URL(
    `/api/v2/submissions/${submissionUuid}/lexis_nexis_customer_search?${queryParams}`,
    UNDERWRITING_BASE_URL()
  );

  const response =
    await makeInternalAPIRequest<LexisNexisCustomerSearchResponse>(url, 'GET');

  if (!response.ok) {
    throw new NetworkError(
      response.status,
      'Failed to fetch LexisNexis Customer search'
    );
  }

  return response.json();
};

export const pullLexisNexisCustomerReport = async (
  submissionUuid: string,
  force?: boolean,
  reportId?: string
): Promise<{ success: true }> => {
  const url = underwriting(
    `/api/v2/submissions/${submissionUuid}/lexis_nexis_customer_reports`
  );

  const response = await makeInternalAPIRequest<
    void,
    PullLexisNexisReportRequestBody
  >(url, 'POST', {
    ...(force !== undefined && { force }),
    ...(reportId !== undefined && { report_id: reportId }),
  });

  if (!response.ok) {
    throw new NetworkError(
      response.status,
      'Failed to pull Lexis Nexis Customer Report'
    );
  }

  return {
    success: true,
  };
};

export const pullPaynetReport = async (
  submissionUuid: string,
  reportId?: string
): Promise<MutationResponse> => {
  const url = underwriting(
    `/api/v2/submissions/${submissionUuid}/paynet_reports`
  );

  const response = await makeInternalAPIRequest<
    void,
    PullPaynetReportRequestBody
  >(url, 'POST', reportId ? { paynet_id: reportId } : {});

  if (!response.ok) {
    throw new NetworkError(response.status, 'Failed to pull Paynet Report');
  }

  return {
    success: true,
  };
};

export const fetchPaynetSearchResults = async (
  submissionUuid: string
): Promise<PaynetResultsResponse> => {
  const url = underwriting(
    `/api/v2/submissions/${submissionUuid}/documents/paynet_search_results`
  );

  const response = await makeInternalAPIRequest<PaynetResultsResponse>(
    url,
    'GET'
  );

  if (!response.ok) {
    throw new NetworkError(
      response.status,
      'Failed to fetch Paynet Search Results'
    );
  }

  return response.json();
};

export const fetchPaynetManualSearch = async (
  submissionUuid: string,
  queryParams: string
): Promise<PaynetResultsResponse> => {
  const url = underwriting(
    `/api/v2/submissions/${submissionUuid}/paynet_search?${queryParams}`
  );

  const response = await makeInternalAPIRequest<PaynetResultsResponse>(
    url,
    'GET'
  );

  if (!response.ok) {
    throw new NetworkError(response.status, 'Failed to fetch Paynet search');
  }

  return response.json();
};

export const fetchOwnerCreditOverview = async (
  submissionUuid: string
): Promise<CreditDataResponse[]> => {
  const url = new URL(
    `/api/v2/submissions/${submissionUuid}/credit_overviews`,
    UNDERWRITING_BASE_URL()
  );

  const response = await makeInternalAPIRequest<CreditDataResponse[]>(
    url,
    'GET'
  );

  if (!response.ok) {
    throw new NetworkError(response.status, 'Failed to fetch CreditOverviews');
  }

  return response.json();
};

export const refreshSimilarOwners = async (
  ownerUuid: string,
  submissionUuid: string
): Promise<MutationResponse> => {
  const url = underwriting(
    `api/v2/owners/${ownerUuid}/similar_contacts?submission_uuid=${submissionUuid}`
  );

  const response = await makeInternalAPIRequest<void>(url, 'POST');

  if (!response.ok) {
    throw new NetworkError(response.status, 'Failed to refresh similar owners');
  }

  return {
    success: true,
  };
};

export const goToNextDeal = async (): Promise<EnqueuedDealResponse> => {
  const url = new URL(
    '/api/v2/prequalification_deal_assignments',
    UNDERWRITING_BASE_URL()
  );

  const response = await makeInternalAPIRequest<EnqueuedDealResponse>(
    url,
    'POST'
  );

  if (!response.ok) {
    throw new NetworkError(response.status, 'Failed to go to next submission');
  }

  return response.json();
};

export const fetchSubmissionStageHistory = async (
  submissionUuid: string
): Promise<SubmissionStageHistoryResponse[]> => {
  const url = new URL(
    `/api/v2/submissions/${submissionUuid}/stage_history`,
    UNDERWRITING_BASE_URL()
  );

  const response = await makeInternalAPIRequest<
    SubmissionStageHistoryResponse[]
  >(url, 'GET');

  if (!response.ok) {
    throw new NetworkError(
      response.status,
      'Failed to fetch Stage History for Submission'
    );
  }

  return response.json();
};

export const fetchSubmissionRenewalCompositeScore = async (
  submissionUuid: string
): Promise<SubmissionRenewalCompositeScoreResponse> => {
  const url = new URL(
    `/api/v2/submissions/${submissionUuid}/composite_score`,
    UNDERWRITING_BASE_URL()
  );

  const response =
    await makeInternalAPIRequest<SubmissionRenewalCompositeScoreResponse>(
      url,
      'GET'
    );

  if (!response.ok) {
    throw new NetworkError(
      response.status,
      'Failed to fetch Renewal Composite Score for Submission'
    );
  }

  return response.json();
};

export const createInternalRenewal = async (
  submissionUuid: string
): Promise<InternalRenewalCreationResponse> => {
  const url = underwriting(`api/v2/submissions/${submissionUuid}/renewals`);

  const response =
    await makeInternalAPIRequest<InternalRenewalCreationResponse>(url, 'POST');

  if (!response.ok) {
    throw new NetworkError(
      response.status,
      'Failed to create internal renewal'
    );
  }

  return response.json();
};

export const getSubmissionEligibilityForRenewal = async (
  submissionUuid: string
): Promise<RenewalEligibleResponse> => {
  const url = underwriting(`api/v2/submissions/${submissionUuid}/renewal`);

  const response = await makeInternalAPIRequest<RenewalEligibleResponse>(
    url,
    'GET'
  );

  if (!response.ok) {
    throw new NetworkError(
      response.status,
      'Failed to fetch submission eligibility'
    );
  }

  return response.json();
};

export const fetchSubmissionStageTransitions = async (
  submissionUuid: string
): Promise<SubmissionStageTransitionsResponse[]> => {
  const url = new URL(
    `/api/v2/submissions/${submissionUuid}/stage_transitions`,
    UNDERWRITING_BASE_URL()
  );

  const response = await makeInternalAPIRequest<
    SubmissionStageTransitionsResponse[]
  >(url, 'GET');

  if (!response.ok) {
    throw new NetworkError(
      response.status,
      'Failed to fetch Stage Transitions for Submission'
    );
  }

  return response.json();
};

export const fetchSubmissionNewDealCompositeScore = async (
  submissionUuid: string
): Promise<SubmissionNewDealCompositeScoreResponse> => {
  const url = new URL(
    `/api/v2/submissions/${submissionUuid}/composite_score`,
    UNDERWRITING_BASE_URL()
  );

  const response =
    await makeInternalAPIRequest<SubmissionNewDealCompositeScoreResponse>(
      url,
      'GET'
    );

  if (!response.ok) {
    throw new NetworkError(
      response.status,
      'Failed to fetch New Deal Composite Score for Submission'
    );
  }

  return response.json();
};

export const refreshCompositeScore = async (
  submissionUuid: string
): Promise<
  | SubmissionNewDealCompositeScoreResponse
  | SubmissionRenewalCompositeScoreResponse
> => {
  const url = underwriting(
    `/api/v2/submissions/${submissionUuid}/composite_score`
  );

  const response = await makeInternalAPIRequest<
    | SubmissionNewDealCompositeScoreResponse
    | SubmissionRenewalCompositeScoreResponse
  >(url, 'POST');

  if (!response.ok) {
    throw new NetworkError(
      response.status,
      'Failed to refresh composite score'
    );
  }

  return response.json();
};

export const fetchFraudScores = async (
  submissionUuid: string
): Promise<FraudScoringDocumentResponse> => {
  const url = new URL(
    `/api/v2/submissions/${submissionUuid}/documents/fraud_scoring`,
    UNDERWRITING_BASE_URL()
  );

  const response = await makeInternalAPIRequest<FraudScoringDocumentResponse>(
    url,
    'GET'
  );

  if (!response.ok) {
    throw new NetworkError(response.status, 'Failed to fetch fraud scores');
  }

  return response.json();
};

export const fetchSubmissionNotes = async (
  submissionUuid: string
): Promise<SubmissionNotesResponse[]> => {
  const url = new URL(
    `api/v2/submissions/${submissionUuid}/notes`,
    UNDERWRITING_BASE_URL()
  );

  const response = await makeInternalAPIRequest<SubmissionNotesResponse[]>(
    url,
    'GET'
  );

  if (!response.ok) {
    throw new NetworkError(
      response.status,
      'Failed to fetch Notes for Submission'
    );
  }

  return response.json();
};

export const patchSubmissionNote = async (
  submissionUuid: string,
  noteType: string,
  body: UpdateSubmissionNoteBody
): Promise<SubmissionNotesResponse> => {
  const url = underwriting(
    `/api/v2/submissions/${submissionUuid}/notes/${noteType}`
  );

  const response = await makeInternalAPIRequest<
    SubmissionNotesResponse,
    UpdateSubmissionNoteBody
  >(url, 'PATCH', body);

  if (!response.ok) {
    throw new NetworkError(
      response.status,
      'Failed to update Note for Submission'
    );
  }

  return response.json();
};

export const fetchRenewalScore = async (
  submissionUuid: string
): Promise<RenewalScoresFetchResponse> => {
  const url = underwriting(
    `api/v2/submissions/${submissionUuid}/renewal_scores`
  );

  const response = await makeInternalAPIRequest<RenewalScoresFetchResponse>(
    url,
    'GET'
  );

  if (!response.ok) {
    throw new NetworkError(
      response.status,
      'Failed to fetch Renewal Score for Submission'
    );
  }

  return response.json();
};

export const createRenewalScore = async (
  submissionUuid: string,
  body?: CreateRenewalScoreBody
): Promise<RenewalScoreResponse> => {
  const url = underwriting(
    `api/v2/submissions/${submissionUuid}/renewal_scores`
  );

  const response = await makeInternalAPIRequest<
    RenewalScoreResponse,
    CreateRenewalScoreBody
  >(url, 'POST', body);

  if (!response.ok) {
    throw new NetworkError(
      response.status,
      'Failed to create Renewal Score for Submission'
    );
  }

  return response.json();
};

export const patchRenewalScore = async (
  submissionUuid: string,
  body: UpdateRenewalScoreBody
): Promise<RenewalScoreResponse> => {
  const url = underwriting(
    `api/v2/submissions/${submissionUuid}/renewal_scores`
  );

  const response = await makeInternalAPIRequest<
    RenewalScoreResponse,
    UpdateRenewalScoreBody
  >(url, 'PATCH', body);

  if (!response.ok) {
    throw new NetworkError(
      response.status,
      'Failed to update Renewal Score for Submission'
    );
  }

  return response.json();
};

export const fetchSubmissionLegacyReportLinks = async (
  submissionUuid: string
): Promise<LegacyReportLinksResponse> => {
  const url = new URL(
    `/api/v2/submissions/${submissionUuid}/legacy_report_links`,
    UNDERWRITING_BASE_URL()
  );

  const response = await makeInternalAPIRequest<LegacyReportLinksResponse>(
    url,
    'GET'
  );

  if (!response.ok) {
    throw new NetworkError(
      response.status,
      'Failed to fetch legacy report links for Submission'
    );
  }

  return response.json();
};

export const fetchSubmissionStipulations = async (
  submissionUuid: string
): Promise<StipulationResponse[]> => {
  const url = new URL(
    `/api/v2/submissions/${submissionUuid}/stipulations`,
    UNDERWRITING_BASE_URL()
  );

  const response = await makeInternalAPIRequest<StipulationResponse[]>(
    url,
    'GET'
  );

  if (!response.ok) {
    throw new NetworkError(
      response.status,
      'Failed to fetch Stipulations for Submission'
    );
  }

  return response.json();
};

export const createStipulation = async (
  submissionUuid: string,
  body: CreateStipulationRequestBody
): Promise<StipulationResponse> => {
  const url = new URL(
    `/api/v2/submissions/${submissionUuid}/stipulations`,
    UNDERWRITING_BASE_URL()
  );

  const response = await makeInternalAPIRequest<
    StipulationResponse,
    CreateStipulationRequestBody
  >(url, 'POST', body);

  if (!response.ok) {
    throw new NetworkError(
      response.status,
      'Failed to create Stipulation for Submission'
    );
  }

  return response.json();
};

export const updateStipulation = async (
  submissionUuid: string,
  stipulationUuid: string,
  body: UpdateStipulationRequestBody
): Promise<StipulationResponse> => {
  const url = new URL(
    `/api/v2/submissions/${submissionUuid}/stipulations/${stipulationUuid}`,
    UNDERWRITING_BASE_URL()
  );

  const response = await makeInternalAPIRequest<
    StipulationResponse,
    UpdateStipulationRequestBody
  >(url, 'PATCH', body);

  if (!response.ok) {
    throw new NetworkError(
      response.status,
      'Failed to update Stipulation for Submission'
    );
  }

  return response.json();
};

export const deleteStipulation = async (
  submissionUuid: string,
  stipulationUuid: string
): Promise<MutationResponse> => {
  const url = new URL(
    `/api/v2/submissions/${submissionUuid}/stipulations/${stipulationUuid}`,
    UNDERWRITING_BASE_URL()
  );

  const response = await makeInternalAPIRequest<MutationResponse>(
    url,
    'DELETE'
  );

  if (!response.ok) {
    throw new NetworkError(
      response.status,
      'Failed to delete Stipulation for Submission'
    );
  }

  return { success: true };
};

export const fetchSubmissionCompositeScore = async (
  submissionUuid: string
): Promise<SubmissionCompositeScoreResponse> => {
  const url = new URL(
    `/api/v2/submissions/${submissionUuid}/composite_score`,
    UNDERWRITING_BASE_URL()
  );

  const response =
    await makeInternalAPIRequest<SubmissionCompositeScoreResponse>(url, 'GET');

  if (!response.ok) {
    throw new NetworkError(
      response.status,
      'Failed to fetch Composite Score for Submission'
    );
  }

  return response.json();
};

export const assignEnqueuedProcessingDeal =
  async (): Promise<ProcessingQueueResponse> => {
    const url = new URL(
      '/api/v2/processing_deal_assignments',
      UNDERWRITING_BASE_URL()
    );

    const response = await makeInternalAPIRequest<ProcessingQueueResponse>(
      url,
      'POST'
    );

    if (!response.ok) {
      throw new NetworkError(
        response.status,
        'Failed to go to next submission'
      );
    }

    return response.json();
  };

export const fetchStipulationValidReasons = async (): Promise<
  HashMap<string[]>
> => {
  const url = new URL(
    `/api/v2/stipulations/valid_reasons`,
    UNDERWRITING_BASE_URL()
  );

  const response = await makeInternalAPIRequest<HashMap<string[]>>(url, 'GET');

  if (!response.ok) {
    throw new NetworkError(
      response.status,
      'Failed to fetch Valid Reasons for Stipulations'
    );
  }

  return response.json();
};
export const fetchSubmissionLogs = async (
  submissionUuid: string
): Promise<SubmissionLogsResponse> => {
  const url = new URL(
    `/api/v2/submissions/${submissionUuid}/submission_logs`,
    UNDERWRITING_BASE_URL()
  );

  const response = await makeInternalAPIRequest<SubmissionLogsResponse>(
    url,
    'GET'
  );

  if (!response.ok) {
    throw new NetworkError(
      response.status,
      'Failed to fetch Logs for Submission'
    );
  }

  return response.json();
};

export const fetchStipulationRecommendations = async (
  submissionUuid: string
): Promise<StipulationRecommendationsResponse> => {
  const url = new URL(
    `/api/v2/submissions/${submissionUuid}/stipulation_recommendations`,
    UNDERWRITING_BASE_URL()
  );

  const response =
    await makeInternalAPIRequest<StipulationRecommendationsResponse>(
      url,
      'GET'
    );

  if (!response.ok) {
    throw new NetworkError(
      response.status,
      'Failed to fetch Stipulation Recommendations for Submission'
    );
  }

  return response.json();
};

export const fetchDataMerch = async (
  submissionUuid: string
): Promise<DataMerchReportResponse> => {
  // CAUTION: LOCAL TESTING ONLY. DO NOT MERGE UNCOMMENTED TEST CODE TO
  // PRODUCTION.
  // Uncomment these lines for mock data, and comment out the code below it.
  // return new Promise((resolve) => {
  //   setTimeout(() => {
  //     resolve(mockDataMerchReport());
  //   }, 5000);
  // });
  const url = new URL(
    `/api/v2/submissions/${submissionUuid}/documents/data_merch`,
    UNDERWRITING_BASE_URL()
  );

  const response = await makeInternalAPIRequest<DataMerchReportResponse>(
    url,
    'GET'
  );

  if (!response.ok) {
    throw new NetworkError(response.status, 'Failed to fetch Data Merch');
  }

  return response.json();
};

export const fetchRenewalComparison = async (
  submissionUuid: string,
  sections: string[]
): Promise<RenewalComparisonResponse> => {
  const url = new URL(
    `/api/v2/submissions/${submissionUuid}/renewal_comparison?sections=${sections}`,
    UNDERWRITING_BASE_URL()
  );

  const response = await makeInternalAPIRequest<RenewalComparisonResponse>(
    url,
    'GET'
  );

  if (!response.ok) {
    if (response.status === 404) {
      throw new NetworkError(response.status, 'Renewal Comparison not found');
    } else {
      throw new NetworkError(
        response.status,
        'Failed to fetch Renewal Comparison'
      );
    }
  }

  return response.json();
};
