import { fetchCurrentOffer } from 'api/banking/offerFetchUtils';
import {
  useGenericQuery,
  UseGenericQueryResponse,
} from 'apiHooks/genericFetchHooks';
import { useCallback } from 'react';
import { OfferResponse } from 'types/api/banking/types';

export type UseApiCurrentOfferResponse = UseGenericQueryResponse<
  OfferResponse | undefined
>;

export const useApiCurrentOffer = (
  submissionUuid?: string
): UseApiCurrentOfferResponse => {
  const loadData = useCallback(() => {
    if (!submissionUuid) {
      return undefined;
    }

    return fetchCurrentOffer(submissionUuid);
  }, [submissionUuid]);

  return useGenericQuery(loadData);
};
