import React from 'react';
import { Submission } from './navbar.types';
import { Navbar } from '@forward-financing/fast-forward';
import { BANK_BASE_URL } from 'constants/globals';

export interface NavbarBankingLinkProps {
  submission: Submission | undefined;
}
export const NavbarBankingLink = ({
  submission,
}: NavbarBankingLinkProps): JSX.Element => {
  if (!submission?.customerUuid || !submission?.uuid) {
    return <></>;
  }

  return (
    <Navbar.Link
      icon="building-columns"
      newTab
      href={
        new URL(
          `admin/prospective_merchants/${submission?.customerUuid}/sheets/${submission?.uuid}`,
          BANK_BASE_URL()
        )
      }
    >
      Banking
    </Navbar.Link>
  );
};
