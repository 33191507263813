import React from 'react';
import {
  Box,
  formatPhoneNumber,
  Table,
  Text,
} from '@forward-financing/fast-forward';
import { noDataMessage } from '../../../helpers/string/errorMessageUtils';
import { BusinessRegistration } from './BusinessLexisNexisPage.types';

interface BusinessRegistrationsTableProps {
  businessRegistrations?: BusinessRegistration[];
}

export const BusinessRegistrationsTable = ({
  businessRegistrations,
}: BusinessRegistrationsTableProps): JSX.Element => {
  const hasBusinessRegistrations =
    businessRegistrations && businessRegistrations?.length > 0;
  return hasBusinessRegistrations ? (
    <Table caption="Business Registrations">
      <Table.Head>
        <Table.ColumnHeader>Filing Code</Table.ColumnHeader>
        <Table.ColumnHeader>Company Name</Table.ColumnHeader>
        <Table.ColumnHeader>Company Phone</Table.ColumnHeader>
        <Table.ColumnHeader>Mailing Address</Table.ColumnHeader>
        <Table.ColumnHeader>Corporate Code</Table.ColumnHeader>
      </Table.Head>
      <Table.Body>
        {businessRegistrations?.map((businessRegistration) => (
          <Table.Row key={businessRegistration.corporationCode}>
            <Table.Cell>{businessRegistration.filingCode}</Table.Cell>
            <Table.Cell>{businessRegistration.companyName}</Table.Cell>
            <Table.Cell>
              {formatPhoneNumber(businessRegistration.companyPhone)}
            </Table.Cell>
            <Table.Cell>{businessRegistration.mailingAddress}</Table.Cell>
            <Table.Cell>{businessRegistration.corporationCode}</Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  ) : (
    <Box mb={4}>
      <Text>{noDataMessage('Business Registrations')}</Text>
    </Box>
  );
};
