import { LexisNexisAddress } from 'types/api/underwriting/types';

// This payload contains keys not supported by our Address component, so we have to format it like this.
export const toAddressString = (addressObject?: LexisNexisAddress): string => {
  if (!addressObject) {
    return '';
  }

  const addressArray = [
    addressObject.street_number,
    addressObject.street_pre_direction,
    addressObject.street_name,
    addressObject.street_suffix,
    addressObject.street_post_direction,
    addressObject.unit_designation,
    addressObject.unit_number,
    addressObject.city,
    addressObject.state,
    addressObject.zip5,
  ];
  return addressArray.filter(Boolean).join(' ');
};
