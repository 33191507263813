import { makeInternalAPIRequest } from 'api/makeInternalAPIRequest';
import { NetworkError } from 'api/networkError';
import { BANK_BASE_URL } from 'constants/globals';
import { OfferResponse } from 'types/api/banking/types';

export const fetchCurrentOffer = async (
  submissionUuid: string
): Promise<OfferResponse | undefined> => {
  const url = new URL(
    `/api/v1/current_offer/${submissionUuid}`,
    BANK_BASE_URL()
  );

  const response = await makeInternalAPIRequest<OfferResponse>(url, 'GET');

  if (!response.ok) {
    throw new NetworkError(response.status, 'Failed to fetch current offer');
  }

  if (response.status === 204) {
    return undefined;
  }

  return response.json();
};
