import defaultTo from 'lodash/defaultTo';
import { useFetchExperianConsumerV2 } from 'apiHooks/3pi/experianConsumerFetchHooks';
import { UseGenericQueryResponse } from 'apiHooks/genericFetchHooks';
import { useGenericFeatureQuery } from 'components/featureHooks/genericFeatureHooks';
import { Tradeline } from 'types/api/3pi/types';
import { currencyFormat } from 'helpers/utils';
import { toDate } from 'helpers/string/dateUtils';
import {
  TradelineDetails,
  TradelineHistoryCodesKeys,
  TradelinesDetails,
} from './types';
import {
  TRADELINE_ENTITIES_TO_REQUEST,
  TRADELINE_HISTORY_CODES,
  TRADELINE_OTHER_RATING_TYPE,
  TRADELINE_PRIMARY_ACCOUNT_TYPE_CODES,
  TRADELINE_PRIMARY_ECOA_CODES,
} from './constants';

export const getCurrentStatus = (
  paymentHistory: string | undefined
): string | undefined =>
  paymentHistory?.length ? paymentHistory[0] : undefined;

export const getDelinquencyCount = ({
  delinquencies_over_30_days,
  delinquencies_over_60_days,
  delinquencies_over_90_days,
  derog_counter,
}: Tradeline): number =>
  delinquencies_over_30_days +
  delinquencies_over_60_days +
  delinquencies_over_90_days +
  derog_counter;

export const getIsPrimary = ({
  ecoa_code,
  account_type_code,
}: Tradeline): boolean =>
  TRADELINE_PRIMARY_ACCOUNT_TYPE_CODES.includes(account_type_code) &&
  TRADELINE_PRIMARY_ECOA_CODES.includes(ecoa_code);

export const getRatingType = (paymentHistory: string): string => {
  const currStatus = getCurrentStatus(
    paymentHistory
  ) as TradelineHistoryCodesKeys;

  if (currStatus === '9') {
    return TRADELINE_OTHER_RATING_TYPE;
  }
  return TRADELINE_HISTORY_CODES[currStatus ?? '-'];
};

export const toTradelineDetails = (tradeline: Tradeline): TradelineDetails => ({
  creditLoanType: tradeline.account_type,
  accountStatusType: defaultTo(tradeline.account_condition, ''),
  accountBalance: currencyFormat(tradeline.balance_amount, true),
  currentStatus: getCurrentStatus(tradeline.payment_history),
  openDate: tradeline.date_opened_f,
  delinquencyCount: getDelinquencyCount(tradeline),
  responsibility: tradeline.ecoa,
  isOpen: tradeline.is_open,
  isPrimary: getIsPrimary(tradeline),
  originalCreditorName: defaultTo(tradeline.original_creditor_name, ''),
  ratingType: getRatingType(tradeline.payment_history),
  name: tradeline.subscriber_name,
  creditLimit: currencyFormat(tradeline.credit_limit, true),
  paymentStatus: tradeline.payment_status,
  delinquenciesOver30Days: tradeline.delinquencies_over_30_days,
  delinquenciesOver60Days: tradeline.delinquencies_over_60_days,
  delinquenciesOver90Days: tradeline.delinquencies_over_90_days,
  derogCounter: tradeline.derog_counter,
  lastPaidDate: tradeline.last_paid_date
    ? toDate(tradeline.last_paid_date.replace('Z', ''), "yyyy-MM-dd'T'HH:mm:ss")
    : undefined,
  statusUpdatedDate: toDate(
    tradeline.status_updated_date?.replace('Z', ''),
    "yyyy-MM-dd'T'HH:mm:ss"
  ),
  paymentHistory: tradeline.payment_history,
  monthlyPayment: currencyFormat(tradeline.monthly_payment_amount, true),
});

export const isIndividualOrJoined = ({ ecoa }: Tradeline): boolean =>
  ['Individual', 'Joint Account'].includes(ecoa);

export const compareAccBalance = (
  first: Tradeline,
  second: Tradeline
): number => {
  const firstBalance = defaultTo(first.balance_amount, 0);
  const secondBalance = defaultTo(second.balance_amount, 0);

  if (firstBalance === secondBalance) {
    return 0;
  }

  return firstBalance > secondBalance ? -1 : 1;
};

/**
 * Compares two tradeline objects first by their account responsibility and then by their account balance.
 *
 * The comparison logic is as follows:
 * 1. Tradelines with 'Individual' or 'Joint Account' responsibility come before others.
 * 2. Within the same responsibility type, tradelines are sorted by account balance in ascending order.
 * 3. Tradelines with 'Authorized User' responsibility come after 'Individual' and 'Joint Account' but before others.
 * 4. If both tradelines have the same responsibility type, they are compared by account balance.
 */
export const compareTradelinesByResponsibilityAndBalance = (
  first: Tradeline,
  second: Tradeline
): number => {
  if (isIndividualOrJoined(first)) {
    return isIndividualOrJoined(second) ? compareAccBalance(first, second) : -1;
  }

  if (isIndividualOrJoined(second)) {
    return 1;
  }

  if (first.ecoa === 'Authorized User') {
    return second.ecoa === 'Authorized User'
      ? compareAccBalance(first, second)
      : -1;
  }

  if (second.ecoa === 'Authorized User') {
    return 1;
  }

  return compareAccBalance(first, second);
};

export const toTradelinesDetails = (
  tradelines: Tradeline[]
): TradelinesDetails => {
  tradelines.sort(compareTradelinesByResponsibilityAndBalance);

  const formattedTradelines = tradelines.map(toTradelineDetails);

  return formattedTradelines.reduce(
    (result, currentTradeline) => {
      if (currentTradeline.isPrimary) {
        const arrToPush = currentTradeline.isOpen ? result.open : result.closed;

        arrToPush.push(currentTradeline);
      } else {
        result.nonPrimary.push(currentTradeline);
      }

      return result;
    },
    {
      open: [],
      closed: [],
      nonPrimary: [],
    } as TradelinesDetails
  );
};

export const useTradelinesDetails = (
  submissionUuid: string,
  ownerUuid: string
): UseGenericQueryResponse<TradelinesDetails> =>
  useGenericFeatureQuery(
    useFetchExperianConsumerV2,
    (data) =>
      data?.consumer_credits &&
      data.consumer_credits[0] &&
      data.consumer_credits[0].tradelines
        ? toTradelinesDetails(data.consumer_credits[0].tradelines)
        : undefined,
    submissionUuid,
    ownerUuid,
    TRADELINE_ENTITIES_TO_REQUEST
  );
